export const state = () => ({
  status: null,
  errors: [],
  giftCardError: null,
  qtyError: null,
  loginError: null
})

export const mutations = {
  SET_LOGIN_ERROR(state, error) {
    state.loginError = error
  },
  SET_ERRORS(state, errors) {
    state.errors = [...state.errors, errors]
    // state.errors = errors
  },
  SET_STATUS(state, status) {
    state.status = status
  },
  CLEAR_ERROR(state) {
    state.errors = []
    state.status = null
  },
  CLEAR_ALL_ERRORS(state) {
    state.errors = []
    state.status = null
    state.giftCardError = null
  },
  SET_GIFT_CARD_ERROR(state, error) {
    if (error && error.error) {
      const err = error.error.toLowerCase().includes('gift card')
        ? error.error
        : null
      state.giftCardError = err
    }
  },
  SET_QTY_ERROR(state, error) {
    if (error && error.error) {
      const err = error.error.toLowerCase().includes('quantity')
        ? error.error
        : null
      state.qtyError = err
    } else {
      state.qtyError = null
    }
  },
  CLEAR_QTY_ERROR(state) {
    state.qtyError = null
  }
}

export const getters = {
  errors(state) {
    return state.products
  },
  errorMessage(state) {
    return state.errors ? state.errors : null
  },
  errorMessages(state) {
    // TODO: Check if this is needed
    if (state.errors && state.errors.errors) {
      return Object.keys(state.errors.errors).map(k => {
        const objName = k.replace(/^\w/, c => c.toUpperCase())
        return `${objName}: ${state.errors.errors[k]}`
      })
    }
    if (state.errors) {
      return state.errors.map(e => {
        if ((e && e.error) || (e && e.detail)) {
          return e.error || e.detail
        }
      })
    }
    return []
  }
}

export const actions = {
  setErrors({ commit, state }, errors) {
    commit('SET_ERRORS', errors)
  },
  setApiError({ commit }, { status, error }) {
    commit('SET_ERRORS', error)
    commit('SET_GIFT_CARD_ERROR', error)
    commit('SET_QTY_ERROR', error)
    commit('SET_STATUS', status)
    commit('SHOW_ERROR', true, { root: true })
    // commit('SHOW_MESSAGE', true, { root: true })
  }
}
