export default {
  spacesPath: 'stomping-assets.sfo2.digitaloceanspaces.com',
  cdnPath: 'stomp-cdn.krollconcepts.com',
  dangle: 'dangle-earrings',
  stud: 'stud-earrings',
  necklace: 'necklaces',
  more: 'other',
  extras: 'other',
  other: 'other',
  laserCut: 'laser-cut',
  newInStore: 'NEW',
  taxonsFields:
    'fields[taxon]=name,permalink,seo_title,parent,meta_title,meta_description,meta_keywords',
  productInclude: 'images,default_variant',
  productPerPage: '24',
  ordersPerPage: '60',
  lineItemsPerPage: '1000',
  lineItemsIncludes: '',
  lineItemFields:
    'name,quantity,display_price,total,display_total,slug,sku,product_code,stock_count,display_wholesale_price,display_total_wholesale_price,variant_image,variant',
  cartInclude:
    'billing_address,shipping_address,user,payments,shipments,promotions',
  cartIncludeWithItems:
    'line_items,variants,variants.images,billing_address,shipping_address,user,payments,shipments,promotions',
  cartFields:
    'number,item_count,display_item_total,display_wholesale_item_total,wholesale_item_total,display_total,token,insufficient_stock_line_ids,line_items,state,display_adjustment_total,is_wholesale,display_tax_total,display_order_total_after_store_credit,order_total_after_store_credit,display_total_applied_gift_card,item_total_excl_gift_cards,order_total_after_store_credit_excl_gift_cards,ship_total,special_instructions',
  // cartInclude:
  //   'billing_address,shipping_address,user,payments,shipments,promotions',
  orderInclude:
    'line_items,variants,variants.images,billing_address,shipping_address,user,payments,payments.source,shipments,promotions,display_order_total_after_store_credit',
  sizeChartImgName: 'stomping-elephants-stud-earring-size-guide',
  sizeChartImgSizes: {
    sm: '_48x48.jpg',
    md: '_250x250.jpg',
    lg: '_500x500.jpg'
  }
}
