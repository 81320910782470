export default function({ store, redirect, route, $auth }) {
  if ($auth.loggedIn) {
    const path =
      route.path === store.state.prevPage ? '/' : store.state.prevPage
    console.log(path)
    if (path === '/sign-up' || path === '/login') {
      return redirect('/')
    }
    return redirect(path)
  }
}
