export const toFloat = text => {
  try {
    const number = parseFloat(text)
    return isNaN(number) ? 0.0 : number
  } catch {
    return 0.0
  }
}

export const numberToMoney = value => {
  try {
    return `$${toFloat(value).toFixed(2)}`
  } catch {
    return '$0.00'
  }
}

export const titleCase = (value, delim) => {
  if (!value) return ''
  const delimiter = delim || ' '
  const words = value.split(delimiter)
  const capitals = words.map(w => {
    return w.slice(0, 1).toUpperCase() + w.slice(1)
  })
  return capitals.join(' ')
}

export const lowerCase = (value, delim) => {
  const delimiter = delim || ' '
  const words = value.split(delimiter)
  const lower = words.map(w => {
    return w.toLowerCase()
  })
  return lower.join(' ')
}

export const truncateText = text => {
  return text.length > 100 ? text.substring(0, 100) + '...' : text
}

export const formatDate = date => {
  const event = new Date(Date.parse(date))
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
  return event
    .toLocaleDateString('en-AU', options)
    .split('/')
    .join('.')
}

export const formatDateFull = date => {
  const event = new Date(Date.parse(date))
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }
  return event
    .toLocaleDateString('en-AU', options)
    .split('/')
    .join('.')
}

export const cleanCommas = str => {
  return str
    .split(',,')
    .join(',')
    .replace(/,\s*$/, '')
}
