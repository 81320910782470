<template>
  <div class="modals">
    <b-modal
      id="sign-in-modal"
      ref="sign-in-modal"
      :title="resetForm ? 'Recover password' : 'Login to your account'"
      ok-title="Login"
      :ok-disabled="resetForm"
      :cancel-disabled="resetForm"
      @hide="onHide()"
      @ok="login"
    >
      <sign-in-up-form
        ref="signInForm"
        title=""
        :hide-buttons="true"
        @show-reset="resetForm = true"
        @hide-reset="resetForm = false"
        @submit="login"
      />
    </b-modal>
    <b-modal
      id="sign-up-modal"
      ref="sign-up-modal"
      size="lg"
      title="Register an account"
      ok-title="Register"
      @hide="onHide()"
      @ok="signUp"
    >
      <sign-up-form ref="signUpForm" />
    </b-modal>
    <b-modal
      id="contact-modal"
      ref="contact-modal"
      size="md"
      :title="contactTitle"
      ok-title="Submit"
      @hide="onHide()"
      @ok="contactSubmit"
    >
      <component :is="contactComponent" ref="contactFormComponent" />
    </b-modal>
    <b-modal
      id="merged-cart-modal"
      ref="merged-cart-modal"
      centered
      ok-only
      title="Update Notification"
      ok-title="I Understand"
      @hide="clearMergeWarning()"
    >
      <p class="my-4">
        We just wanted to let you know that we have added items to your bag from your previous session.
      </p>
      <p class="my-4">
        Please carefully review your bag before checking out!
      </p>
    </b-modal>
    <b-modal
      id="insufficient-stock-modal"
      ref="insufficient-stock-modal"
      centered
      ok-only
      ok-title="Ok"
      title="Insufficient Stock"
      @hide="redirectToBag"
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5>
          <fa :icon="['fas', 'exclamation-circle']" size="1x" class="se-pink" />
          Insufficient Stock
        </h5>
        <fa :icon="['fas', 'times']" size="1x" class="soft-text icon-button" @click="close()" />
      </template>
      <p class="my-4">
        Some items in your bag have insufficient stock available.
      </p>
      <p class="my-4">
        Please adjust the quantity of the items highlighted.
      </p>
    </b-modal>
    <b-modal
      id="min-order-modal"
      ref="min-order-modal"
      centered
      ok-only
      ok-title="Ok"
      title="Minimum Order Required"
      @hide="redirectToBag"
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5>
          <fa :icon="['fas', 'exclamation-circle']" size="1x" class="se-pink" />
          Minimum Order Required
        </h5>
        <fa :icon="['fas', 'times']" size="1x" class="soft-text icon-button" @click="close()" />
      </template>
      <p class="my-4">
        Your order has not reached the minimum order value (at wholesale pricing) for wholesale pricing to be applied.
      </p>
      <p class="my-4">
        {{`Please add an additional ${wholesaleRemaining} (wholesale) to your order.`}}
      </p>
      <p class="my-4">
        You can check the remaining required at any time at the bottom of your bag/cart (under the ‘Empty Bag’ button).
      </p>
    </b-modal>

    <recaptcha
      v-show="showModal !== null"
      @error="onRecaptchaError"
      @success="onRecaptchaSuccess"
      @expired="onRecaptchaExpired"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import isDefined from './../utils/isDefined'
import SignUpForm from '~/components/SignUpForm'
import SignInUpForm from '~/components/SignInUpForm'
import StockistForm from '~/components/stockists/StockistForm'
import ContactUsForm from '~/components/ContactUsForm'
import RepsForm from '~/components/RepsForm'

export default {
  components: {
    'sign-up-form': SignUpForm,
    'sign-in-up-form': SignInUpForm,
    'stockist-form': StockistForm,
    'contact-us-form': ContactUsForm,
    'reps-form': RepsForm
  },
  data() {
    return {
      resetForm: false
    }
  },
  computed: {
    ...mapGetters({
      displayWholesaleItemTotal: 'cart/displayWholesaleItemTotal'
    }),
    contactTitle() {
      return this.$store.state.contactFormTitle
    },
    contactComponent() {
      return this.$store.state.contactFormComponent
    },
    showModal() {
      return this.$store.state.showModal
    },
    mergedCart() {
      return this.$store.state.cart.mergedCartItems
    }
  },
  watch: {
    mergedCart() {
      if (this.mergedCart) {
        this.$store.commit('SHOW_MODAL', 'merged-cart-modal')
      }
    }
  },
  methods: {
    modalOk() {
      this.login()
    },
    async login(bvModalEvt) {
      if (bvModalEvt !== null && bvModalEvt !== undefined) {
        bvModalEvt.preventDefault()
      }
      try {
        await this.$recaptcha.getResponse()
        await this.$recaptcha.reset()
        await this.$refs.signInForm.login()
        if (this.$auth.loggedIn) {
          this.$nextTick(() => {
            if (isDefined(this.$refs['sign-in-modal'])) {
              this.$refs['sign-in-modal'].hide()
            }
            this.$store.commit('HIDE_NAV_SMALL')
          })
        }
      } catch (error) {
        console.log('Error:', error)
      }
    },
    async signUp(bvModalEvt) {
      if (bvModalEvt !== null && bvModalEvt !== undefined) {
        bvModalEvt.preventDefault()
      }
      try {
        await this.$recaptcha.getResponse()
        await this.$recaptcha.reset()
        await this.$refs.signUpForm.signup()
        if (this.$auth.loggedIn) {
          this.$nextTick(() => {
            this.$refs['sign-up-modal'].hide()
            this.$store.commit('HIDE_NAV_SMALL')
          })
        }
      } catch (error) {
        console.log('Error:', error)
      }
    },
    async contactSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      const valid = await this.$refs.contactFormComponent.validData()
      if (!valid) {
        return
      }
      const data = this.$refs.contactFormComponent.formData
      await this.$store.dispatch('submitContactForm', data)
      this.$nextTick(() => {
        this.$refs['contact-modal'].hide()
      })
    },
    onHide(id) {
      this.$store.commit('SHOW_MODAL', null)
    },
    onRecaptchaSuccess(token) {
      console.log('Succeeded')
    },
    onRecaptchaExpired() {
      console.log('Expired')
    },
    onRecaptchaError(error) {
      console.log('Error happened:', error)
    },
    clearMergeWarning() {
      this.$store.commit('cart/SET_MERGED_CART_ITEMS', false)
      if (isDefined(this.$store.state.cart.cart)) {
        const cart = this.$store.state.cart.cart
        const insuff = cart.attributes.insufficient_stock_line_ids
        if (
          isDefined(insuff) &&
          insuff.length > 0 &&
          this.$route.name === 'checkout'
        ) {
          this.$store.commit('SHOW_MODAL', null)
          this.$store.commit('SHOW_MODAL', 'insufficient-stock-modal')
        }
      }
    },
    redirectToBag() {
      this.$store.commit('SHOW_MODAL', null)
      this.$router.push('/bag')
    }
  }
}
</script>

<style lang="scss">
.icon-button {
  cursor: pointer;
}
</style>
