<template>
  <b-navbar-nav id="navbar__small" class="d-flex d-sm-none small-nav-items w-100 justify-content-around">
    <b-nav-item class="bag-item text-right" to="/">
      <span>
        <fa :icon="['fas', 'home']" size="1x" />
      </span>
    </b-nav-item>
    <b-nav-item @click="collapseShow('account')">
      <fa-layers class="mr-2">
        <fa :icon="['fas', 'user']" size="1x" />
        <fa :icon="icons" size="1x" transform="down-3 right-8 shrink-6" class="pink" />
      </fa-layers>
      <span class="nav-text d-none d-sm-block">
        My Account
      </span>
    </b-nav-item>
    <b-nav-item @click="collapseShow('search')">
      <fa icon="search" />
    </b-nav-item>
    <b-nav-item class="bag-item text-right" to="/bag">
      <span>
        <fa :icon="['fas', 'shopping-bag']" size="1x" />
        <b-badge v-show="itemCount > 0" pill variant="danger">
          {{ itemCount }}
        </b-badge>
      </span>
    </b-nav-item>
    <!-- <b-nav-item class="bag-item text-right" v-b-toggle href="#sidebar-1" @click.prevent>
      <span>
        <fa :icon="['fas', 'bars']" size="1x" />
      </span>
    </b-nav-item> -->

    <li class="nav-item bag-item text-right collapsed" role="button" tabindex="0" aria-expanded="false">
      <b-btn v-b-toggle.sidebar-1 variant="link" sm class="nav-btn">
        <span>
          <fa :icon="['fas', 'bars']" size="1x" />
        </span>
      </b-btn>
    </li>
  </b-navbar-nav>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      displayItemTotal: 'cart/displayItemTotal',
      itemCount: 'cart/itemCount'
    }),
    loggedIn() {
      return this.$auth.loggedIn
    },
    icons() {
      return this.loggedIn ? ['fas', 'lock-open'] : ['fas', 'lock']
    }
  },
  methods: {
    collapseShow(component) {
      this.$store.commit('NAV_SMALL_COMPONENT', component)
      this.$store.commit('TOGGLE_NAV_SMALL')
      this.$emit('collapse-show', component)
    },
    toggleSidebar() {
      this.$store.dispatch('sitenav/toggleSidebar')
    }
  }
}
</script>
<style lang="scss">
#navbar__small {
  .nav-btn {
    padding-left: 0 !important;
    padding-right: 0 !important;
    color: #fff;
  }
}
</style>
