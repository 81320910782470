import constants from '../utils/constants'
import ApiError from './error'

export default $axios => ({
  async account() {
    try {
      const { data } = await $axios.get(
        `api/account?include=default_billing_address,default_shipping_address,favorites,wholesaler,wholesaler.business_address`
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  // Create a user account
  async createUser(payload) {
    try {
      const { data } = await $axios.post('register', payload)
      return data
    } catch (error) {
      console.log(error)
      return { error: ApiError(error), data: null }
    }
  },
  async orders(options) {
    try {
      const defaultOptions = {
        page: 1,
        perPage: constants.ordersPerPage
      }
      const paramsOptions = { ...defaultOptions, ...options }
      const { perPage, page } = paramsOptions
      const params = `?page=${page}&per_page=${perPage}`
      const { data } = await $axios.get(
        `api/account/orders${sanitizeUrl(params)}`
      )
      return data
    } catch (error) {
      console.log(error)
      return { error: ApiError(error), data: null }
    }
  },
  async order(number) {
    try {
      const { data } = await $axios.get(
        `api/account/orders/${number}?include=${constants.orderInclude}`
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async update(payload) {
    try {
      const { data } = await $axios.patch(
        `api/account?include=default_billing_address,default_shipping_address,favorites,wholesaler,wholesaler.business_address`,
        payload
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async recoverPassword(email) {
    try {
      const { data } = await $axios.get(
        `api/account/password_reset_token?email=${email}`
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async validResetToken(token) {
    try {
      const { data } = await $axios.get(
        `api/account/valid_reset_token?token=${token}`
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async resetPassword(payload) {
    try {
      const { data } = await $axios.put(`api/account/password_reset`, payload)
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  }
})

const sanitizeUrl = url => url.split('#').join('%23')
