export const state = () => ({
  navItems: {
    studEarrings: {
      category: 'products',
      label: 'Stud Earrings',
      route: '/shop/stud-earrings'
    },
    dangleEarrings: {
      category: 'products',
      label: 'Dangle Earrings',
      route: '/shop/dangle-earrings'
    },
    // necklaces: {
    //   category: 'products',
    //   label: 'Necklaces',
    //   route: '/shop/necklaces'
    // },
    // laserCut: {
    //   category: 'products',
    //   label: 'Shop Laser Cut',
    //   route: '/shop/laser-cut'
    // },
    other: {
      category: 'products',
      label: 'Other',
      route: '/shop/other'
    },
    ourTeam: {
      category: 'about',
      label: 'Our Team',
      route: '/tell-me-more/our-team'
    },
    ourStory: {
      category: 'about',
      label: 'Our Story',
      route: '/tell-me-more/our-story'
    },
    stockists: {
      category: 'about',
      label: 'Stockists',
      route: '/tell-me-more/stockists'
    },
    becomeARep: {
      category: 'workWithUs',
      label: 'Become a Rep',
      route: '/work-with-us/become-rep'
    },
    becomeAStockist: {
      category: 'workWithUs',
      label: 'Become a Stockist',
      route: '/work-with-us/become-stockist'
    },
    faqs: {
      category: 'help',
      label: 'FAQs',
      route: '/help/faq'
    },
    contactUs: {
      category: 'help',
      label: 'Contact Us',
      route: '/help/contact'
    },
    shippingAndReturns: {
      category: 'help',
      label: 'Shipping and Returns',
      route: '/help/shipping'
    },
    bag: {
      category: 'quickLinks',
      label: 'Shopping Bag',
      route: '/bag'
    },
    myAccount: {
      category: 'quickLinks',
      label: 'My Account',
      route: '/account/info'
    },
    newInStore: {
      category: 'quickLinks',
      label: 'New in Store',
      route: '/new-in-store'
    }
  },
  navCategories: {
    products: 'STOMPING ELEPHANTS',
    about: 'TELL ME MORE',
    quickLinks: 'QUICK LINKS',
    help: 'HELP'
  },
  sidebarShown: false,
  sidebarTitle: 'Menu'
})

export const mutations = {
  TOGGLE_SIDEBAR(state) {
    state.sidebarShown = !state.sidebarShown
  },
  CLOSE_SIDEBAR(state) {
    state.sidebarShown = false
  },
  SET_SIDEBAR_TITLE(state, title) {
    state.sidebarTitle = title
  }
}

export const getters = {
  categoriesArray(state) {
    return Object.keys(state.navCategories).map(k => {
      const c = {}
      c[k] = state.navCategories[k]
      return c
    })
  },
  categories(state) {
    return state.navCategories
  },
  linksByCategory(state) {
    const keys = Object.keys(state.navCategories)
    const navKeys = Object.keys(state.navItems)
    const links = {}
    keys.map(k => {
      links[k] = navKeys
        .map(nk => {
          return state.navItems[nk]
        })
        .filter(c => {
          return c.category === k
        })
    })
    return links
  },
  allLinks(state) {
    const keys = Object.keys(state.navItems)
    return keys.map(k => {
      return state.navItems[k]
    })
  },
  productsLinks(state) {
    const keys = Object.keys(state.navItems)
    return keys
      .map(k => {
        return state.navItems[k]
      })
      .filter(c => {
        return c.category === 'products'
      })
  },
  aboutLinks(state) {
    const keys = Object.keys(state.navItems)
    return keys
      .map(k => {
        return state.navItems[k]
      })
      .filter(c => {
        return c.category === 'about'
      })
  },
  workWithUsLinks(state) {
    const keys = Object.keys(state.navItems)
    return keys
      .map(k => {
        return state.navItems[k]
      })
      .filter(c => {
        return c.category === 'workWithUs'
      })
  },
  helpLinks(state) {
    const keys = Object.keys(state.navItems)
    return keys
      .map(k => {
        return state.navItems[k]
      })
      .filter(c => {
        return c.category === 'help'
      })
  }
}

export const actions = {
  toggleSidebar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSidebar({ commit }) {
    commit('CLOSE_SIDEBAR')
  }
}
