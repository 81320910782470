<template>
  <div>
    <b-form @submit.prevent="validateBeforeSubmit" @reset="onReset">
      <contact-fields ref="contactFields" />
      <b-form-row>
        <b-col cols="sm-6">
          <b-form-group
            id="input-group-1"
            label="Suburb:"
            label-for="stockist-suburb"
          >
            <b-form-input
              id="stockist-suburb"
              v-model="suburb"
              v-validate="{ required: true, min: 3 }"
              :state="validateState('suburb')"
              name="suburb"
              aria-describedby="suburb-feedback"
              size="md"
              type="text"
              required
              placeholder="SUBURB"
            />
            <b-form-invalid-feedback id="suburb-feedback">
              Suburb required (min. 3 characters)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="sm-6">
          <b-form-group
            id="input-group-postcode"
            label="Postcode:"
            label-for="stockist-postcode"
          >
            <b-form-input
              id="stockist-postcode"
              v-model="postcode"
              v-validate="{ required: true, min: 4 }"
              :state="validateState('postcode')"
              name="postcode"
              aria-describedby="postcode-feedback"
              size="md"
              type="text"
              required
              placeholder="POSTCODE"
            />
            <b-form-invalid-feedback id="postcode-feedback">
              Postcode required (min. 4 characters)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-group
        id="input-group-1"
        label="What’s your favourite pair of Stomping Earrings?:"
        label-for="favourite-earrings"
      >
        <b-form-input
          id="favourite-earrings"
          v-model="favouriteEarrings"
          v-validate="{ required: true, min: 3 }"
          :state="validateState('favouriteEarrings')"
          name="favouriteEarrings"
          aria-describedby="favouriteEarrings-feedback"
          size="md"
          type="text"
          required
          placeholder="FAVOURITE EARRINGS"
        />
        <b-form-invalid-feedback id="favouriteEarrings-feedback">
          Store type required (min. 3 characters)
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-row>
        <b-col cols="7" sm="8">
          <p>
            Are you willing to do regular markets?
          </p>
        </b-col>
        <b-col cols="5" sm="4">
          <div class="float-right">
            <b-form-radio-group
              id="markets-radios"
              v-model="availableForMarkets"
              :options="options"
              name="markets-radios"
            />
          </div>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="7" sm="8">
          <p>
            Do you have spare time?
          </p>
        </b-col>
        <b-col cols="5" sm="4">
          <div class="float-right">
            <b-form-radio-group
              id="spare-time-radios"
              v-model="spareTime"
              :options="options"
              name="spare-time-radios"
            />
          </div>
        </b-col>
      </b-form-row>
      <b-form-group
        id="about-group"
        label="Tell us about yourself:"
        label-for="about-text"
      >
        <b-form-textarea
          id="about-text"
          v-model="about"
          v-validate="{ required: true, min: 3, max: 550 }"
          :state="validateState('about')"
          name="about"
          aria-describedby="about-feedback"
          placeholder="Tell us about yourself"
          rows="8"
          max-rows="12"
        />
        <b-form-invalid-feedback id="about-feedback">
          Region required (min. 3 characters, max. 550 characters)
        </b-form-invalid-feedback>
      </b-form-group>
      <no-ssr>
        <recaptcha
          @error="onRecaptchaError"
          @success="onRecaptchaSuccess"
          @expired="onRecaptchaExpired"
        />
      </no-ssr>
      <b-button type="submit" variant="primary">
        Submit
      </b-button>
      <b-button type="reset" variant="outline-dark">
        Reset
      </b-button>
    </b-form>
  </div>
</template>

<script>
import ContactFields from '~/components/ContactFields'
import validateState from './../mixins/validateState'
export default {
  components: {
    'contact-fields': ContactFields
  },
  mixins: [validateState],
  data() {
    return {
      availableForMarkets: true,
      spareTime: true,
      favouriteEarrings: '',
      suburb: '',
      postcode: '',
      about: '',
      options: [{ text: 'Yes', value: true }, { text: 'No', value: false }]
    }
  },
  computed: {
    formData() {
      return {
        msgType: 'rep_enquiry',
        formData: {
          ...{
            available_for_markets: this.availableForMarkets ? 'Yes' : 'No',
            spare_time: this.spareTime ? 'Yes' : 'No',
            favourite_earrings: this.favouriteEarrings,
            suburb: this.suburb,
            postcode: this.postcode,
            about: this.about
          },
          ...this.$refs.contactFields.contactData
        }
      }
    }
  },
  methods: {
    async validData() {
      const thisIsValid = await this.isValid()
      const validContact = await this.$refs.contactFields.isValid()
      return thisIsValid && validContact
    },
    async validateBeforeSubmit() {
      const canSubmit = await this.validData()
      if (!canSubmit) {
        return
      }
      try {
        await this.$recaptcha.getResponse()
        await this.$recaptcha.reset()
        await this.$store.dispatch('submitContactForm', this.formData)
        this.onReset()
        this.$emit('reps-form-submitted')
      } catch (error) {
        console.log('Error:', error)
      }
    },
    onReset() {
      this.$refs.contactFields.reset()
      this.availableForMarkets = true
      this.spareTime = true
      this.favouriteEarrings = ''
      this.suburb = ''
      this.postcode = ''
      this.about = ''
      this.resetState()
      this.$refs.contactFields.resetState()
    },
    onRecaptchaSuccess(token) {
      console.log('Succeeded')
    },
    onRecaptchaExpired() {
      console.log('Expired')
    },
    onRecaptchaError(error) {
      console.log('Error happened:', error)
    }
  }
}
</script>
