import normalize from 'json-api-normalizer'
import pluralize from 'pluralize'
import changeCase from 'change-case'
import getIncluded from './getIncluded'

export default store => ({
  flatten(storeMod, data) {
    const obj = normalize(data, { endpoint: storeMod })
    const type = pluralize.singular(storeMod)
    const sortedObjs =
      obj.meta !== undefined &&
      obj.meta !== null &&
      Array.isArray(obj.meta[storeMod].data)
        ? obj.meta[storeMod].data.map(m => {
            if (m.type === type) {
              return m.id
            }
          })
        : []
    obj[`sorted_${type}`] = sortedObjs
    Object.keys(obj).map(k => {
      const mut = `set_${pluralize.plural(k)}`.toUpperCase()
      if (Object.keys(store._mutations).includes(`${storeMod}/${mut}`)) {
        store.commit(`${storeMod}/${mut}`, obj[k])
      }
    })
  },
  // update the store with the 'included' data response
  setIncluded(storeMod, data, type, asArray = true) {
    const included = getIncluded(data, type, asArray)
    if (included) {
      const plural = pluralize.plural(type)
      const mutPart = changeCase.snakeCase(plural)
      const mut = `set_${mutPart}`.toUpperCase()
      store.commit(`${storeMod}/${mut}`, included)
    } else {
      console.log('ERROR')
    }
  },
  getRelationships(storeMod, relativeType, obj) {
    if (obj && obj.relationships) {
      const keepGoing =
        obj.relationships[relativeType] && obj.relationships[relativeType].data
      if (keepGoing) {
        const ids = obj.relationships[relativeType].data.map(d => {
          return d.id
        })
        const storeObj = store.state[storeMod][relativeType]
        if (storeObj) {
          const filtered = Object.keys(storeObj)
            .filter(id => ids.includes(id))
            .reduce((o, key) => {
              o[key] = store.state[storeMod][relativeType][key]
              return o
            }, {})
          return filtered
        }
      }
    }
    return store.state[storeMod][relativeType]
  },
  normalize(obj) {
    return normalize(obj)
  },
  getRelationship(storeMod, relativeType, relId) {
    return this.getRelationships(storeMod, relativeType)[relId]
  }
})
