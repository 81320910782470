import constants from './../utils/constants'
export default $axios => ({
  async index(options) {
    const opts = options === undefined ? '' : options
    try {
      const route = `api/taxons?${constants.taxonsFields}${opts}`
      const { data } = await $axios.get(route)
      return data
    } catch (error) {
      return error
    }
  },
  async get(taxon, includeOpt) {
    try {
      const opts = includeOpt === undefined ? '' : includeOpt
      const route = `api/taxons/${taxon}?${constants.taxonsFields}&${opts}`
      const { data } = await $axios.get(route)
      return data
    } catch (error) {
      return error
    }
  },
  async getTaxonChildren(parent) {
    try {
      const route = `api/taxons/${parent}/?include=children&${
        constants.taxonsFields
      }`
      const { data } = await $axios.get(route)
      return data
    } catch (error) {
      return error
    }
  },
  async getCoincidentTaxons(parent) {
    try {
      const cat = parent.startsWith('categories/')
        ? parent
        : `categories/${parent}`
      const route = `api/taxons/${cat}/?include=materials,themes&${
        constants.taxonsFields
      }`
      const { data } = await $axios.get(route)
      return data
    } catch (error) {
      return error
    }
  }
})
