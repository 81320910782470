// import normalize from 'json-api-normalizer'
import pluralize from 'pluralize'
import isDefined from '../utils/isDefined'
import { numberToMoney } from './../utils/format'

export const state = () => ({
  order: null,
  lineItems: [],
  images: [],
  shipments: [],
  addresses: [],
  payments: [],
  creditCards: [],
  giftCards: [],
  paypalExpressCheckouts: [],
  variants: [],
  users: null
})

export const mutations = {
  SET_ORDER(state, order) {
    state.order = order
  },
  SET_LINEITEMS(state, items) {
    state.lineItems = items
  },
  SET_IMAGES(state, items) {
    state.images = items
  },
  SET_VARIANTS(state, variants) {
    state.variants = variants
  },
  SET_SHIPMENTS(state, shipments) {
    state.shipments = shipments
  },
  SET_ADDRESSES(state, addresses) {
    state.addresses = addresses
  },
  SET_PAYMENTS(state, payments) {
    state.payments = payments
  },
  SET_CREDITCARDS(state, card) {
    state.creditCards = card
  },
  SET_GIFTCARDS(state, card) {
    state.giftCards = card
  },
  SET_USERS(state, users) {
    state.users = users
  },
  SET_PAYPALEXPRESSCHECKOUTS(state, source) {
    state.paypalExpressCheckouts = source
  }
}

export const getters = {
  paymentSources(state) {
    if (state.payments) {
      const paymentIds = Object.keys(state.payments)
      const paymentSources = paymentIds.map(id => {
        const sourceRel = state.payments[id].relationships.source.data
        const sourceType = sourceRel.type
        const stateType = pluralize.plural(sourceType)
        let source = state[stateType][sourceRel.id]
        if (source) {
          source = source.attributes
          source.id = sourceRel.id
          return source
        }
      })
      return paymentSources
    }
    return []
  },
  shippingMethods(state) {
    if (state.shipments) {
      const shipmentIds = Object.keys(state.shipments)
      return shipmentIds.map(id => {
        return state.shipments[id].attributes.selectedShippingRateName
      })
    }
    return []
  },
  orderNumber(state) {
    return state.order ? state.order.attributes.number : ''
  },
  orderDate(state) {
    return state.order ? state.order.attributes.completed_at : ''
  },
  displayTotal(state) {
    return state.order ? state.order.attributes.display_total : '$0.00'
  },
  displayTaxTotal(state) {
    return state.order ? state.order.attributes.display_tax_total : '$0.00'
  },
  displayShipTotal(state) {
    return state.order
      ? numberToMoney(state.order.attributes.ship_total)
      : '$0.00'
  },
  displayItemTotal(state) {
    return state.order ? state.order.attributes.display_item_total : '$0.00'
  },
  displayAdjustmentTotal(state) {
    return state.order ? state.order.attributes.display_adjustment_total : null
  },
  billingAddress(state) {
    const orderRel = orderRelationship(state, 'billing_address', 'addresses')
    return orderRel ? orderRel.attributes : {}
  },
  shippingAddress(state) {
    const orderRel = orderRelationship(state, 'shipping_address', 'addresses')
    return orderRel ? orderRel.attributes : {}
  },
  giftCardDisplayTotal(state) {
    return state.order
      ? state.order.attributes.display_total_applied_gift_card
      : null
  },
  displayOrderTotalAfterStoreCredit(state) {
    return state.order
      ? state.order.attributes.display_order_total_after_store_credit
      : null
  },
  displayWholesaleItemTotal(state) {
    return isDefined(state.order)
      ? state.order.attributes.display_wholesale_item_total
      : '$0.00'
  },
  email(state) {
    return state.order ? state.order.attributes.email : ''
  },
  company(state) {
    if (state.addresses && Object.keys(state.addresses).length > 0) {
      const companies = Object.keys(state.addresses).map(k => {
        const a = state.addresses[k]
        if (a.attributes.company && a.attributes.company !== '') {
          return a.attributes.company
        }
      })
      return companies.length > 0 ? companies[0] : ''
    }
    return ''
  },
  items(state) {
    if (!state.lineItems && !state.variants && !state.images) {
      return []
    }
    return Object.keys(state.lineItems).map(id => {
      const line = state.lineItems[id]
      const variantId = parseInt(line.relationships.variant.data.id)
      const variant = state.variants[variantId]
      let images = []
      if (variant) {
        images = variant.relationships.images.data.map(image => {
          return state.images[image.id]
        })
      }
      const item = {
        id: line.id,
        attributes: line.attributes,
        images: images,
        variant: variant
      }
      return item
    })
  },
  itemImgs(state) {
    if (!state.lineItems) {
      return {}
    }
    const imgs = {}
    Object.keys(state.lineItems).map(id => {
      const line = state.lineItems[id]
      imgs[id] = line.attributes.variantImage
    })
    return imgs
  },
  isWholesale(state) {
    return isDefined(state.order) ? state.order.attributes.is_wholesale : false
  }
}

export const actions = {
  async getCompleteOrder({ commit }, orderNumber) {
    const orderToken = this.$cookies.get('stomping_complete_token')
    const { data, included, error } = await this.$api.orders.orderStatus(
      orderToken,
      orderNumber
    )
    this.$normalizer.flatten('orders', { included: included })
    if (!error) {
      commit('SET_ORDER', data)
    } else {
      commit('error/SET_ERRORS', error.message, { root: true })
    }
  },
  async getUserCompleteOrder({ commit }, orderNumber) {
    const { data, included, error } = await this.$api.user.order(orderNumber)
    this.$normalizer.flatten('orders', { included: included })
    if (!error) {
      commit('SET_ORDER', data)
    } else {
      commit('error/SET_ERRORS', error.message, { root: true })
    }
  }
}

const orderRelationship = (state, relType, relState) => {
  if (state.order && state[relState]) {
    const id = state.order.relationships[relType].data
      ? state.order.relationships[relType].data.id
      : null
    return state[relState][id]
  }
  return null
}
