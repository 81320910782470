<template>
  <div>
    <base-layout>
      <layout-container
        :show-alerts="false"
        :show-have-you-seen="false"
      />
    </base-layout>
  </div>
</template>

<script>
import BaseLayout from '~/components/BaseLayout'
import LayoutContainer from '~/components/LayoutContainer'

export default {
  components: {
    'base-layout': BaseLayout,
    'layout-container': LayoutContainer
  }
}
</script>
