<template>
  <div class="dropdown-full col-12">
    <!-- STUD EARRINGS -->
    <b-dropdown
      id="dropdown__stud"
      name="stud-dropdown"
      variant="trans"
      no-caret
      class="nav-link-custom"
      :class="studBG"
      @show="ddShow('stud')"
      @hide="ddHide"
    >
      <template v-slot:button-content>
        <span>
          Shop Stud Earrings <caret :open="studBG.length > 0" />
        </span>
      </template>
      <b-dropdown-item :to="buildRoute('stud-earrings', '')">
        All Stud Earrings
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item
        v-for="theme in studThemes"
        :key="theme.attributes.permalink + '-stud'"
        :to="buildRoute('stud-earrings', theme.attributes.name)"
      >
        {{ theme.attributes.name }}
      </b-dropdown-item>
      <b-dropdown-divider v-if="studMaterials.length > 0 && studThemes.length > 0" />
      <b-dropdown-item
        v-for="material in studMaterials"
        :key="material.attributes.permalink + '-stud'"
        :to="buildRoute('stud-earrings', material.attributes.name)"
      >
        {{ material.attributes.name }}
      </b-dropdown-item>
    </b-dropdown>
    <!-- DANGLE EARRINGS -->
    <b-dropdown
      id="dropdown__dangle"
      variant="trans"
      no-caret
      class="nav-link-custom"
      :class="dangleBG"
      @show="ddShow('dangle')"
      @hide="ddHide"
    >
      <template v-slot:button-content>
        <span>
          Shop Dangle Earrings <caret :open="dangleBG.length > 0" />
        </span>
      </template>
      <b-dropdown-item :to="buildRoute('dangle-earrings', '')">
        All Dangle Earrings
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item
        v-for="theme in dangleThemes"
        :key="theme.attributes.permalink + '-dangle'"
        :to="buildRoute('dangle-earrings', theme.attributes.name)"
      >
        {{ theme.attributes.name }}
      </b-dropdown-item>
      <b-dropdown-divider v-if="dangleMaterials.length > 0 && dangleThemes.length > 0" />
      <b-dropdown-item
        v-for="material in dangleMaterials"
        :key="material.attributes.permalink+ '-dangle'"
        :to="buildRoute('dangle-earrings', material.attributes.name)"
      >
        {{ material.attributes.name }}
      </b-dropdown-item>
    </b-dropdown>
    <!-- LASER CUT -->
    <!-- <b-dropdown
      id="dropdown__laserCut"
      variant="trans"
      no-caret
      class="nav-link-custom"
      :class="laserCutBG"
      @show="ddShow('laserCut')"
      @hide="ddHide"
    >
      <template v-slot:button-content>
        <span>
          Shop Laser Cut <caret :open="laserCutBG.length > 0" />
        </span>
      </template>
      <b-dropdown-item :to="buildRoute('laser-cut', '')">
        All laserCuts
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item
        v-for="theme in laserCutThemes"
        :key="theme.attributes.permalink + '-laserCut'"
        :to="buildRoute('laser-cut', theme.attributes.name)"
      >
        {{ theme.attributes.name }}
      </b-dropdown-item>
      <b-dropdown-divider v-if="laserCutMaterials.length > 0 && laserCutThemes.length > 0" />
      <b-dropdown-item
        v-for="material in laserCutMaterials"
        :key="material.attributes.permalink + '-laserCut'"
        :to="buildRoute('laser-cut', material.attributes.name)"
      >
        {{ material.attributes.name }}
      </b-dropdown-item>
    </b-dropdown> -->
    <!-- EXTRAS -->
    <b-dropdown
      id="dropdown__extras"
      variant="trans"
      no-caret
      class="nav-link-custom"
      :class="extrasBG"
      @show="ddShow('other')"
      @hide="ddHide"
    >
      <template v-slot:button-content>
        <span>
          Shop Other Products <caret :open="extrasBG.length > 0" />
        </span>
      </template>
      <b-dropdown-item :to="buildRoute('other', '')">
        All Other Products
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item
        v-for="item in more"
        :key="item.attributes.permalink + '-extras'"
        :to="buildRoute('other', item.attributes.name)"
      >
        {{ item.attributes.name }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Caret from '~/components/Caret'
export default {
  components: {
    caret: Caret
  },
  data() {
    return {
      studBG: '',
      dangleBG: '',
      necklaceBG: '',
      extrasBG: '',
      laserCutBG: '',
      selectedCategory: ''
    }
  },
  computed: {
    ...mapGetters({
      studThemes: 'products/studThemes',
      studMaterials: 'products/studMaterials',
      dangleThemes: 'products/dangleThemes',
      dangleMaterials: 'products/dangleMaterials',
      extrasThemes: 'products/extrasThemes',
      extrasMaterials: 'products/extrasMaterials',
      laserCutThemes: 'products/laserCutThemes',
      laserCutMaterials: 'products/laserCutMaterials'
    }),
    more() {
      return this.$store.getters['products/extraCategories']
    }
  },
  methods: {
    buildRoute(category, name) {
      const formatName = name
        .toLowerCase()
        .split(' ')
        .join('-')
      return `/shop/${category}/${formatName}`
    },
    ddShow(name) {
      switch (name) {
        case 'stud':
          this.selectedCategory = 'stud-earrings'
          this.studBG = 'dropdown-active show'
          this.dangleBG = ''
          this.laserCutBG = ''
          this.extrasBG = ''
          break
        case 'dangle':
          this.selectedCategory = 'dangle-earrings'
          this.studBG = ''
          this.dangleBG = 'dropdown-active show'
          this.laserCutBG = ''
          this.extrasBG = ''
          break
        case 'laserCut':
          this.selectedCategory = 'laser-cut'
          this.studBG = ''
          this.dangleBG = ''
          this.laserCutBG = 'dropdown-active show'
          this.extrasBG = ''
          break
        default:
          this.selectedCategory = 'other'
          this.studBG = ''
          this.dangleBG = ''
          this.laserCutBG = ''
          this.extrasBG = 'dropdown-active show'
          break
      }
    },
    ddHide() {
      this.studBG = ''
      this.dangleBG = ''
      this.extrasBG = ''
      this.laserCutBG = ''
    }
  }
}
</script>
<style lang="scss">
.nav__ddown {
  .btn:focus {
    box-shadow: none !important;
  }
}
</style>
