<template>
  <div class="alerts">
    <!-- <b-alert
      class="text-center position-fixed fixed-bottom m-0 rounded-0"
      :show="dismissCountDown"
      dismissible
      fade
      :variant="messageType"
      @dismiss-count-down="countDownChanged"
    >
      {{ message }}
    </b-alert>
    <b-alert
      class="text-center position-fixed fixed-bottom m-0 rounded-0"
      :show="dismissErrorCountDown"
      dismissible
      fade
      variant="danger"
      @dismiss-count-down="errorCountDownChanged"
    >
      <div v-for="(msg, index) in errorMessages" :key="'error__msg-' + index">
        {{ msg }}
      </div>
    </b-alert> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      dismissErrorCountDown: 0
    }
  },
  computed: {
    ...mapGetters({
      errorMessage: 'error/errorMessage',
      errorMessages: 'error/errorMessages',
      errors: 'error/errors',
      showLoading: 'showLoading'
    }),
    message() {
      return this.$store.state.message
    },
    messageType() {
      return this.$store.state.messageType
    }
  },
  watch: {
    errorMessage() {
      this.errorToast()
      // if (this.errorMessage.length > 0) {
      //   this.dismissCountDown = 0
      //   this.dismissErrorCountDown = this.dismissSecs
      //   console.log('ayayayayayaya')
      // } else {
      //   this.dismissErrorCountDown = 0
      // }
    },
    message() {
      this.makeToast()
      // if (this.message && this.message !== undefined && this.message !== '') {
      //   this.dismissErrorCountDown = 0
      //   this.dismissCountDown = this.dismissSecs
      // }
    }
  },
  methods: {
    clearError() {
      this.$store.commit('SHOW_ERROR', false)
      this.$store.commit('error/CLEAR_ERROR')
    },
    clearMessage() {
      this.$store.commit('SHOW_MESSAGE', false)
      this.$store.commit('CLEAR_MESSAGE')
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
      if (dismissCountDown === 0) {
        this.clearMessage()
      }
    },
    errorCountDownChanged(dismissCountDown) {
      this.dismissErrorCountDown = dismissCountDown
      if (dismissCountDown === 0) {
        this.clearError()
      }
    },
    makeToast() {
      this.$bvToast.toast(this.message, {
        title: 'Message',
        variant: 'success',
        autoHideDelay: 4000,
        toaster: 'b-toaster-bottom-right',
        appendToast: true
      })
      this.clearMessage()
    },
    errorToast() {
      this.errorMessages.forEach(msg => {
        this.$bvToast.toast(msg, {
          title: 'Oh snap!',
          variant: 'danger',
          autoHideDelay: 4000,
          toaster: 'b-toaster-bottom-right',
          appendToast: true
        })
      })
    }
  }
}
</script>
