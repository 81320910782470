<template>
  <div class="height-100">
    <!-- <Loader ref="defaultLoader" /> -->
    <!-- <sidebar /> -->
    <b-link to="/help/shipping" class="navbar-link">
      <b-navbar
        toggleable="lg"
        type="dark"
        variant="primary"
        class="p-1 justify-content-center"
      >
        <b-nav-text class="white-text p-0">
          FREE SHIPPING on orders $59 and over in Australia*
        </b-nav-text>
      </b-navbar>
    </b-link>
    <div id="primary-nav">
      <b-container>
        <Navbar keep-alive :keep-alive-props="{ max: 10 }" />
      </b-container>
    </div>
    <layout-alerts />

    <slot />

    <Footer />
    <layout-modals />
  </div>
</template>

<script>
import Navbar from '~/components/Navbar'
// import Loader from './../components/Loading'
import Footer from '~/components/Footer'
import LayoutModals from '~/components/LayoutModals'
import LayoutAlerts from '~/components/LayoutAlerts'
// import Sidebar from '~/components/SidebarNav'

export default {
  components: {
    Navbar,
    Footer,
    // sidebar: Sidebar,
    // Loader,
    'layout-modals': LayoutModals,
    'layout-alerts': LayoutAlerts
  },
  computed: {
    loading() {
      return this.$store.state.loading
    },
    showModal() {
      return this.$store.state.showModal
    },
    hideModal() {
      return this.$store.state.hideModal
    }
  },
  watch: {
    showModal(newVal, oldVal) {
      if (this.showModal) {
        this.$bvModal.show(this.showModal)
      }
    },
    hideModal(newVal, oldVal) {
      if (this.hideModal) {
        this.$bvModal.hide(this.hideModal)
      }
    }
  }
  // methods: {
  //   hideModal(name) {
  //     this.$nextTick(() => {
  //       this.$bvModal.hide(name)
  //     })
  //   }
  // }
}
</script>
<style lang="scss">
a.navbar-link {
  text-decoration: none;
}
</style>
