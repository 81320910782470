const setUserData = (app, store, $auth) => ({
  setCurrentUser() {
    const user = {
      id: '',
      type: 'guest',
      email: '',
      name: '',
      wholesaler: false
    }
    const loggedIn = $auth ? $auth.loggedIn : null
    if (loggedIn) {
      const loggedInUser = $auth.user.data.attributes
      user.type = 'registered'
      user.email = loggedInUser.email
      user.wholesaler = loggedInUser.wholesaler
      user.name = `${loggedInUser.first_name} ${loggedInUser.last_name}`
      user.id = $auth.user.data.id
    } else if (store.state.cart && store.state.cart.cart) {
      // try the cart
      const cartEmail = store.state.cart.cart.attributes.email
      user.email = cartEmail === null ? '' : cartEmail
    }
    app.$bugsnag.setUser(user.id, user.email, user.name)
    app.$bugsnag.clearMetadata('customer')
    app.$bugsnag.addMetadata('customer', {
      type: user.type,
      wholesaler: user.wholesaler
    })
    return user
  }
})

export default ({ app, store }, inject) => {
  inject('currentUser', setUserData(app, store))
}
