import md5 from 'md5'
import ApiError from './error'
export default $axios => ({
  async ping() {
    try {
      const res = await $axios.get(`mailchimp/ping`)
      return res
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async lists() {
    try {
      const res = await $axios.get(`mailchimp/lists`)
      return res
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async getList(id) {
    try {
      const res = await $axios.get(`mailchimp/lists/${id}`)
      return res
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async subscribe(id, user) {
    try {
      const res = await $axios.post(`mailchimp/lists/${id}/members`, user)
      return res
    } catch (error) {
      const apiErr = {
        status: error.response.status,
        message: error.response.data.detail
      }
      return { error: apiErr, data: null }
    }
  },
  async update(id, user) {
    try {
      const hash = md5(user.email_address.toLowerCase())
      const res = await $axios.put(
        `mailchimp/lists/${id}/members/${hash}`,
        user
      )
      return res
    } catch (error) {
      const apiErr = {
        status: error.response.status,
        message: error.response.data.detail
      }
      return { error: apiErr, data: null }
    }
  },
  async members(id) {
    try {
      const res = await $axios.get(`mailchimp/lists/${id}/members`, {
        fields: 'email_address'
      })
      return res
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async getMember(id, email) {
    const hash = md5(email.toLowerCase())
    try {
      const res = await $axios.get(`mailchimp/lists/${id}/members/${hash}`, {
        fields: 'email_address'
      })
      console.log('mailchimp getMember', res)
      return res
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  }
})
