<template>
  <div class="topnav-nav mb-2">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="topnav__logo flex-sm-grow-1 mx-auto mb-2 mb-sm-0">
        <b-link to="/">
          <img src="~/assets/img/Stompint-Elephants-Logo.png" alt="Stomping Elephants" class="img-fluid stomp-logo">
        </b-link>
      </div>
      <div class="d-none d-sm-flex align-items-center flex-nowrap">
        <!-- HANDMADE LOGO -->
        <div class="float-left topnav-nav__handmade mr-3">
          <img src="~/assets/img/handmade-in-australia.png" alt="Handmade in Australia" class="img-fluid handmade-logo">
        </div>
        <!-- BUTTONS -->
        <div class="topnav-nav__btns order-lg-2 order-1">
          <b-button to="/new-in-store" size="sm" variant="primary">
            NEW in store
          </b-button>
          <b-button
            v-b-toggle.sidebar-1
            size="sm"
            variant="outline-secondary"
            name="toggleSidebar"
            class="ml-2"
          >
            <span name="toggleSidebar">
              MENU
              <fa icon="bars" name="toggleSidebar" />
            </span>
          </b-button>
        </div>
      </div>
    </div>
    <b-row class="text-center nav__ddown">
      <drop-down-menus class="d-none" />
    </b-row>
  </div>
</template>

<script>
import DropDownMenus from '~/components/DropDownMenus'

export default {
  components: {
    'drop-down-menus': DropDownMenus
  },
  data() {
    return {
      largeScreen: false,
      fullDropdown: false,
      smallDropdown: false
    }
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch('sitenav/toggleSidebar')
    }
  }
}
</script>
<style lang="scss">
@media (min-height: 500px) and (min-width: 736px) {
  .dropdown-full {
    display: block !important;
  }
}
</style>
