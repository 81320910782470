import normalize from 'json-api-normalizer'
import isDefined from './../utils/isDefined'

export const state = () => ({
  useShipping: true,
  email: '',
  firstname: '',
  lastname: '',
  phone: '',
  company: '',
  canContinue: false,
  shipments: null,
  shippingMethods: null,
  selectedShippingMethod: null,
  paymentMethods: null,
  selectedPaymentMethod: null,
  paymentSource: {},
  completeOrders: {},
  paypalAuthUrl: null,
  paypalData: null,
  checkoutStep: 'contactInfo',
  isPaypalPayment: false,
  checkoutSteps: {
    contactInfo: 'contactInfo',
    shippingAddress: 'shippingAddress',
    billingAddress: 'billingAddress',
    shippingMethod: 'shippingMethod',
    paymentMethod: 'paymentMethod',
    paymentDetails: 'paymentDetails',
    paymentInformation: 'paymentInformation',
    complete: 'complete'
  }
})

export const getters = {
  selectedPaymentName(state) {
    if (state.selectedPaymentMethod) {
      const id = state.selectedPaymentMethod
      const methods = state.paymentMethods
      if (!isDefined(methods)) {
        return ''
      }
      const filtered = methods.filter(m => {
        return m.id === id
      })
      return filtered.length > 0 ? filtered[0].attributes.name : ''
    }
    return ''
  },
  selectedPaymentMethodType(state) {
    if (state.selectedPaymentMethod) {
      const id = state.selectedPaymentMethod
      const methods = state.paymentMethods
      if (isDefined(methods)) {
        const filtered = methods.filter(m => {
          return m.id === id
        })
        return filtered.length > 0 ? filtered[0].attributes.type : ''
      }
    }
    return ''
  },
  shippingCost(state) {
    const id = state.selectedShippingMethod
    if (id) {
      const rates = normalize({ data: state.shippingMethods })
      return rates.shippingRate && rates.shippingRate[parseInt(id)]
        ? rates.shippingRate[id].attributes.displayCost
        : ''
    }
    return ''
  },
  contactInfo(state) {
    return {
      email: state.email,
      firstname: state.firstname,
      lastname: state.lastname,
      phone: state.phone,
      company: state.company
    }
  }
}

export const mutations = {
  SET_CAN_CONTINUE(state, canMove) {
    state.canContinue = canMove
  },
  SET_USE_SHIPPING(state, useShipping) {
    state.useShipping = useShipping
  },
  SET_SHIPMENTS(state, shipments) {
    state.shipments = shipments
  },
  SET_SHIPPING_METHODS(state, methods) {
    state.shippingMethods = methods
  },
  SET_SHIPPING_METHOD(state, method) {
    state.selectedShippingMethod = method
  },
  SET_PAYMENT_METHODS(state, methods) {
    state.paymentMethods = methods
  },
  SET_PAYMENT_METHOD(state, method) {
    state.selectedPaymentMethod = method
  },
  UPDATE_PAYMENT_SOURCE(state, { sourceNo, sourceDetails }) {
    // const updated = state.paymentSource
    // state.paymentSource = { ...state.paymentSource, ...updated }
    const updated = {}
    updated[parseInt(state.selectedPaymentMethod)] = sourceDetails
    state.paymentSource = updated
  },
  SET_COMPLETE_ORDERS(state, orderNumber, token) {
    state.completeOrders = {
      ...state.completeOrders,
      ...{ orderNumber: token }
    }
  },
  SET_EMAIL(state, email) {
    state.email = email
  },
  SET_FIRST_NAME(state, name) {
    state.firstname = name
  },
  SET_LAST_NAME(state, name) {
    state.lastname = name
  },
  SET_COMPANY(state, company) {
    state.company = company
  },
  SET_PHONE(state, phone) {
    state.phone = phone
  },
  SET_PAYPAL_AUTH_URL(state, data) {
    state.paypalAuthUrl = data.redirect_url
  },
  SET_PAYPAL_DATA(state, data) {
    state.paypalData = data
  },
  SET_CHECKOUT_STEP(state, step) {
    state.checkoutStep = step
  },
  SET_IS_PAYPAL_PAYMENT(state, isPayPal) {
    state.isPaypalPayment = isPayPal
  }
}

export const actions = {
  useShipping({ state, commit }) {
    commit('SET_USE_SHIPPING', true)
  },
  toggleUseShipping({ state, commit }) {
    commit('SET_USE_SHIPPING', !state.useShipping)
  },
  updateContactInfo({ commit }, contact) {
    if (contact.email) {
      commit('SET_EMAIL', contact.email)
    }
    if (contact.firstname) {
      commit('SET_FIRST_NAME', contact.firstname)
    }
    if (contact.lastname) {
      commit('SET_LAST_NAME', contact.lastname)
    }
    if (contact.company) {
      commit('SET_COMPANY', contact.company)
    }
    if (contact.phone) {
      commit('SET_PHONE', contact.phone)
    }
  },
  async updateShippingAddress({ dispatch, rootState }, { email, address }) {
    const body = {
      order: {
        email,
        ship_address_attributes: address
      }
    }
    const res = await this.$api.checkout.update(body)
    dispatch('cart/updateCart', res, { root: true })
  },
  async updateBillingAddress({ dispatch, rootState }, { email, address }) {
    const body = {
      order: {
        email,
        bill_address_attributes: address
      }
    }
    const res = await this.$api.checkout.update(body)
    dispatch('cart/updateCart', res, { root: true })
  },
  async updateShippingMethod({ state, dispatch, rootState }) {
    const shipmentsAttributes = state.shipments.map(s => {
      return {
        id: parseInt(s.id),
        selected_shipping_rate_id: parseInt(state.selectedShippingMethod)
      }
    })
    const body = {
      order: {
        shipments_attributes: shipmentsAttributes
      }
    }
    const res = await this.$api.checkout.update(body)
    await dispatch('cart/updateCart', res, { root: true })
  },
  async updatePaymentDetails({ state, dispatch, rootState }) {
    const body = {
      order: {
        payments_attributes: [
          {
            payment_method_id: parseInt(state.selectedPaymentMethod)
          }
        ]
      },
      payment_source: { ...state.paymentSource, ...{} }
    }
    const res = await this.$api.checkout.update(body)
    if (!res.error) {
      dispatch('cart/updateCart', res, { root: true })
    }
    return res
  },
  async moveNext({ dispatch, rootState, commit }, nextSate) {
    const currentState = rootState.cart.cart
      ? rootState.cart.cart.attributes.state
      : 'cart'
    const states = {
      cart: 0,
      address: 1,
      delivery: 2,
      payment: 3,
      confirm: 4,
      complete: 5
    }
    const canMoveNext =
      states[nextSate] > states[currentState] && currentState !== 'confirm'
    if (canMoveNext) {
      commit('SET_LOADING', true, { root: true })
      const res = await this.$api.checkout.next()
      dispatch('cart/updateCart', res, { root: true })
      commit('SET_LOADING', false, { root: true })
      setCompleteCookie(this.$cookies, res)
      return res
    }
  },
  async advance({ dispatch }) {
    const res = await this.$api.checkout.advance()
    // this.$bugsnag.notify(new Error('Store dispatch advance'), function(event) {
    //   event.severity = 'info'
    //   event.context = 'store'
    //   event.addMetadata('res', { res: res })
    // })
    dispatch('cart/updateCart', res, { root: true })
    setCompleteCookie(this.$cookies, res)
    return res
  },
  async paypalExpress({ commit, rootState, state }) {
    const { data, error } = await this.$api.paypal.express(
      rootState.cart.token,
      state.selectedPaymentMethod,
      rootState.frontBaseUrl
    )
    if (error) {
      commit('SET_CHECKOUT_STEP', 'paymentInformation')
      commit('error/SET_ERRORS', error, { root: true })
    }
    commit('SET_PAYPAL_AUTH_URL', data)
  },
  async paypalPayment({ commit, dispatch, state, rootState }, paypalData) {
    await commit('SET_PAYMENT_METHOD', paypalData.paymentMethodId)
    await commit('SET_IS_PAYPAL_PAYMENT', true)
    // await dispatch('setShippingRates'
    await dispatch('setPaymentMethods')
    const source = {
      token: paypalData.token,
      payer_id: paypalData.PayerID
    }
    commit('UPDATE_PAYMENT_SOURCE', {
      sourceNo: state.selectedPaymentMethod,
      sourceDetails: source
    })
    const body = {
      order: {
        payments_attributes: [
          {
            payment_method_id: parseInt(state.selectedPaymentMethod)
          }
        ]
      },
      payment_source: { ...state.paymentSource, ...{} }
    }
    const { error } = await this.$api.checkout.update(body)

    // this.$bugsnag.notify(new Error('Store dispatch paypalPayment'), function(
    //   event
    // ) {
    //   event.severity = 'info'
    //   event.context = 'store'
    //   event.addMetadata('query', { query: paypalData })
    //   event.addMetadata('body', { body: body })
    //   event.addMetadata('data', { data: data })
    // })
    if (!error) {
      await dispatch('advance')
      // commit('SET_CHECKOUT_STEP', 'complete')
    } else {
      this.$bugsnag.notify(error.error)
      commit('SET_CHECKOUT_STEP', 'paymentInformation')
      commit('error/SET_ERRORS', error, { root: true })
    }
  },
  async paypalCheckoutPayment(
    { commit, dispatch, state, rootState },
    paypalData
  ) {
    // await commit('SET_PAYMENT_METHOD', paypalData.paymentMethodId)
    await commit('SET_IS_PAYPAL_PAYMENT', true)
    // await dispatch('setShippingRates'
    await dispatch('setPaymentMethods')
    const source = {
      token: paypalData.token,
      payer_id: paypalData.payerID,
      transaction_id: paypalData.orderID
    }
    commit('UPDATE_PAYMENT_SOURCE', {
      sourceNo: state.selectedPaymentMethod,
      sourceDetails: source
    })
    const body = {
      order: {
        payments_attributes: [
          {
            payment_method_id: parseInt(state.selectedPaymentMethod)
          }
        ]
      },
      payment_source: { ...state.paymentSource, ...{} }
    }
    const { error } = await this.$api.checkout.update(body)
    // this.$bugsnag.notify(new Error('Store dispatch paypalPayment'), function(
    //   event
    // ) {
    //   event.severity = 'info'
    //   event.context = 'store'
    //   event.addMetadata('query', { query: paypalData })
    //   event.addMetadata('body', { body: body })
    //   event.addMetadata('data', { data: data })
    // })
    if (!error) {
      await dispatch('complete')
    } else {
      this.$bugsnag.notify(error.error)
      commit('SET_CHECKOUT_STEP', 'paymentInformation')
      commit('error/SET_ERRORS', error, { root: true })
    }
  },
  async complete({ commit, dispatch }) {
    const res = await this.$api.checkout.complete()
    if (!res.error) {
      setCompleteCookie(this.$cookies, res)
      dispatch('cart/updateCart', res, { root: true })
      return res
    } else {
      commit('error/SET_ERRORS', res.error, { root: true })
      return res
    }
  },
  async setShippingRates({ commit }) {
    const res = await this.$api.checkout.shippingRates()
    commit('SET_SHIPMENTS', res.data)
    commit('SET_SHIPPING_METHODS', res.included)
    commit('SET_LOADING', false, { root: true })
  },
  async setPaymentMethods({ commit, rootState, state }) {
    if (!state.paymentMethods || state.paymentMethods.length === 0) {
      commit('SET_LOADING', true, { root: true })
      const res = await this.$api.checkout.paymentMethods()
      commit('SET_PAYMENT_METHODS', res.data)
      commit('SET_LOADING', false, { root: true })
    }
  },
  resetCheckout({ commit }) {
    commit('SET_CHECKOUT_STEP', 'contactInfo')
    commit('SET_SHIPPING_METHOD', null)
    commit('SET_PAYMENT_METHOD', null)
    commit('SET_SHIPMENTS', [])
  },
  setCurrentState({ commit, rootState, state }) {
    const cart = rootState.cart.cart
    const currentState = cart ? rootState.cart.cart.attributes.state : 'cart'
    const amtRemain = cart
      ? parseFloat(cart.attributes.order_total_after_store_credit)
      : 1000
    const isEntireGiftCard = amtRemain === 0.0
    const paymentStep = isEntireGiftCard
      ? state.checkoutSteps.paymentInformation
      : state.checkoutSteps.paymentMethod

    const states = {
      cart: state.checkoutSteps.contactInfo,
      address: state.checkoutSteps.shippingAddress,
      delivery: state.checkoutSteps.shippingMethod,
      payment: paymentStep,
      confirm: state.checkoutSteps.paymentDetails,
      complete: state.checkoutSteps.complete
    }
    commit('SET_CHECKOUT_STEP', states[currentState])
  },
  async resetShipping({ dispatch, commit, state }) {
    await dispatch('setShippingRates')
    commit('SET_CHECKOUT_STEP', 'contactInfo')
  },
  clearShipping({ commit }) {
    commit('SET_SHIPPING_METHODS', null)
  }
}

const setCompleteCookie = (cookies, res) => {
  if (!res.error) {
    if (res.data && res.data.attributes.state === 'complete') {
      cookies.set('stomping_complete_token', res.data.attributes.token)
    }
  }
}

// const checkoutStepOrder = step => {
//   const checkoutSteps = {
//     contactInfo: 1,
//     shippingAddress: 2,
//     billingAddress: 3,
//     shippingMethod: 4,
//     paymentMethod: 5,
//     paymentDetails: 6,
//     paymentInformation: 7,
//     complete: 8
//   }
//   return checkoutSteps[step]
// }
