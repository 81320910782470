<template>
  <loading
    :active.sync="loading" 
    :can-cancel="false" 
    :is-full-page="true"
    :class="loadingClass"
  />
</template>
<script>
import Loading from 'vue-loading-overlay'
export default {
  components: {
    Loading
  },
  props: {
    loadingClass: {
      type: String,
      default: 'loading-page'
    }
  },
  data() {
    return {
      loading: false,
      throttle: 50
    }
  },
  computed: {
    stateLoading() {
      return this.$store.state.loading
    }
  },
  watch: {
    stateLoading() {
      this.loading = this.stateLoading
    }
  },
  methods: {
    start() {
      this.loading = true
    },
    finish() {
      this.loading = false
    }
  }
}
</script>
<style lang="scss">
.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding-top: 200px;
  font-size: 30px;
  font-family: sans-serif;
  z-index: 2001;
}
.loading-component {
  position: relative;
  width: 100%;
  height: 100%;
  // background: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding-top: 200px;
  font-size: 30px;
  font-family: sans-serif;
  z-index: 2000;
}
</style>
