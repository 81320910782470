import isDefined from '../utils/isDefined'

export default function({ $axios, store, redirect, app, $auth }) {
  $axios.onRequest(config => {
    $axios.setHeader('X-Spree-Order-Token', store.state.cart.token)
    // config.headers.common['X-Spree-Order-Token'] = store.state.cart.token
  })

  // if (store.state.cart.token) {
  //   $axios.setHeader('X-Spree-Order-Token', store.state.cart.token)
  // }
  store.watch(
    state => state.cart.token,
    (newValue, oldValue) => {
      if (isDefined(oldValue)) {
        app.$cookies.set('cart_token_old', oldValue)
      }
      if (!newValue) {
        store.dispatch('cart/clearCart')
      }
      $axios.setHeader('X-Spree-Order-Token', newValue)
    }
  )
  $auth.$storage.watchState('loggedIn', newValue => {
    if (!newValue) {
      store.dispatch('cart/clearCart')
    }
  })

  // if ($auth.loggedIn) {
  //   try {
  //     $auth.refreshTokens()
  //   } catch (error) {
  //     console.error('Error authenticating', error)
  //   }
  // }

  $axios.onResponse(res => {
    store.commit('error/CLEAR_ALL_ERRORS')
  })
  $axios.onError(error => {
    const status = parseInt(error.response && error.response.status)

    // const originalRequest = error.config

    // if ((status === 401 || status === 403) && !originalRequest._retry) {
    //   console.log('getting auth error', originalRequest.url)
    //   originalRequest._retry = true
    //   $auth.refreshTokens().then(() => {
    //     console.log('refreshing this mutha')
    //     $axios.setToken($auth.strategy.token.get(), 'Bearer')
    //   })
    //   return $axios.request(originalRequest)
    // }
    // return Promise.reject(error);
    const data = error.response ? error.request.data : null
    if (app.$currentUser) {
      app.$currentUser.setCurrentUser()
    }
    app.$bugsnag.clearMetadata('apiError')
    app.$bugsnag.addMetadata('apiError', { status, error: data })
    app.$bugsnag.notify(error)

    // don't show loging errors as it's handled in the form
    if (
      status === 400 &&
      error.response &&
      error.response.data &&
      error.response.data.error === 'invalid_grant'
    ) {
      return
    }

    if (status === 404) {
      if (
        error.response &&
        error.response.request &&
        error.response.request.responseURL &&
        error.response.request.responseURL.includes('api/cart')
      ) {
        return
        // return Promise.resolve(false)
      }
      if (
        error.response.data.type &&
        error.response.data.type.includes('mailchimp')
      ) {
        return
        // return Promise.resolve(false)
      }
    }

    // TODO: REMOVE THIS HACK
    if (status === 500 || status === 502) {
      console.error(status, error.response)
      console.error(status, error.response.data)
      return Promise.resolve(false)
      // app.$auth.logout().then(() => {
      //   // store.dispatch('cart/resetCart')
      //   console.log('500', error.response)
      //   console.log('500', error.response.data)
      // })
    }
    store.commit('SHOW_ERROR', false)
    store.commit('error/CLEAR_ALL_ERRORS')
    store.dispatch('error/setApiError', { status, error: error.response.data })

    const msg = 'Some line items in this order have insufficient quantity.'
    if (
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.includes(msg)
    ) {
      redirect('/bag')
    }
    // if (code === 400) {
    //   redirect('/400')
    // }
  })
}
