// import normalize from 'json-api-normalizer'
import { isEqual } from 'lodash'
import { toFloat, numberToMoney } from './../utils/format'
import isDefined from './../utils/isDefined'
import getIncluded from './../utils/getIncluded'

export const state = () => ({
  cart: null,
  token: null,
  lineItems: [],
  lineItemIds: [],
  lineItemCount: 0,
  lineItemPage: 1,
  lineItemPageCount: 1,
  lineItemImages: {},
  images: [],
  variants: [],
  specialInstructions: '',
  shipments: null,
  addresses: null,
  giftCards: [],
  payments: [],
  giftCardError: '',
  promotions: [],
  shippingOptions: [],
  mergedCartItems: false,
  bagging: false,
  shouldMerge: false,
  oldToken: ''
})

export const mutations = {
  SET_CART_TOKEN(state, token) {
    state.token = token
  },
  SET_CART(state, cart) {
    // if (process.client) {
    //   localStorage.setItem('stomping_cart_token', cart.attributes.token)
    // }
    if (isDefined(cart)) {
      state.cart = { ...cart }
      state.token = cart.attributes.token
      this.$cookies.set('stomping_cart_token', cart.attributes.token, {
        maxAge: 1209600
      })
    }
  },
  DELETE_CART(state) {
    state.cart = null
    state.token = null
  },
  SET_ERRORS(state, errors) {
    state.errors = errors
  },
  SET_LINE_ITEMS(state, items) {
    state.lineItems = items
    state.lineItemPage = 1
  },
  UPDATE_LINE_ITEM(state, updatedItem) {
    state.lineItems = [
      ...state.lineItems.map(
        i => (i.id !== updatedItem.id ? i : { ...i, ...updatedItem })
      )
    ]
  },
  SET_LINE_ITEM_PAGES(state, meta) {
    state.lineItemCount = isDefined(meta) ? meta.total_count : 0
    state.lineItemPageCount = isDefined(meta) ? meta.total_pages : 1
  },
  PAGINATE_LINE_ITEMS(state, { items, page }) {
    const lineItems = getIncluded(items, 'lineItem', true)
    const variants = getIncluded(items, 'variant', false)
    const imgs = getIncluded(items, 'image', true)
    state.lineItemPage = page
    state.variants = { ...state.variants, ...variants }
    state.images = [...state.images, ...imgs]
    state.lineItems = [...state.lineItems, ...lineItems]
  },
  SET_LINE_ITEM_IDS(state) {
    if (
      isDefined(state.cart) &&
      isDefined(state.cart.relationships) &&
      isDefined(state.cart.relationships.line_items)
    ) {
      const ids = state.cart.relationships.line_items.data.map(i => i.id)
      state.lineItemIds = ids
    } else {
      state.lineItemIds = []
    }
  },
  REFRESH_LINE_ITEMS(state) {
    state.lineItems = state.lineItems.filter(item =>
      state.lineItemIds.includes(item.id)
    )
  },
  SET_IMAGES(state, items) {
    state.images = items
  },
  SET_VARIANTS(state, variants) {
    state.variants = variants
  },
  SET_SPECIAL_INSTRUCTIONS(state, instructions) {
    if (instructions !== null) {
      state.specialInstructions = instructions
    }
  },
  SET_SHIPMENTS(state, shipments) {
    state.shipments = shipments
  },
  SET_ADDRESSES(state, addresses) {
    state.addresses = addresses
    // if (included) {
    //   state.addresses = included.filter(p => {
    //     return p.type === 'address'
    //   })
    // }
    // state.addresses = []
  },
  SET_GIFT_CARDS(state, { cart, paymentMethod }) {
    state.giftCards = cart.included.filter(p => {
      const isPayment = p.type === 'payment'
      const isGiftCard =
        p.relationships &&
        p.relationships.payment_method &&
        p.relationships.payment_method.data.id === paymentMethod.id
      return isPayment && isGiftCard
    })
  },
  SET_PAYMENTS(state, payments) {
    if (isDefined(payments)) {
      state.payments = payments.filter(p => {
        return p.type === 'payment'
      })
    }
  },
  SET_GIFT_CARD_ERROR(state, error) {
    state.giftCardError = error
  },
  SET_PROMOTIONS(state, promos) {
    state.promotions = promos
  },
  SET_SHIPPING_OPTIONS(state, options) {
    state.shippingOptions = options
  },
  SET_MERGED_CART_ITEMS(state, merged) {
    state.mergedCartItems = merged
  },
  SET_BAGGING(state, bagging) {
    state.bagging = bagging
  },
  SET_LINE_ITEM_IMAGES(state, ids) {
    state.lineItemImages = { ...state.lineItemImages, ...ids }
  },
  SET_SHOULD_MERGE(state, shouldMerge) {
    state.shouldMerge = shouldMerge
  },
  SET_OLD_TOKEN(state, token) {
    state.oldToken = token
  }
}

export const getters = {
  cart(state) {
    return state.cart
  },
  total(state) {
    return state.cart ? toFloat(state.cart.attributes.item_total) : 0
  },
  itemTotal(state) {
    return state.cart ? toFloat(state.cart.attributes.item_total) : 0
  },
  itemTotalExclGiftCards(state) {
    return state.cart
      ? toFloat(state.cart.attributes.item_total_excl_gift_cards)
      : 0
  },
  displayItemTotal(state) {
    if (isDefined(state.cart) && isDefined(state.cart.attributes)) {
      return state.cart.attributes.is_wholesale
        ? state.cart.attributes.display_wholesale_item_total
        : state.cart.attributes.display_item_total
    }
    return '$0.00'
  },
  shipTotal(state) {
    return state.cart ? toFloat(state.cart.attributes.ship_total) : 0
  },
  displayShipTotal(state) {
    return state.cart
      ? numberToMoney(state.cart.attributes.ship_total)
      : '$0.00'
  },
  totalBeforeTax(state) {
    return state.cart
      ? state.cart.attributes.total - state.cart.attributes.tax_total
      : 0
  },
  taxTotal(state) {
    return state.cart ? parseFloat(state.cart.attributes.tax_total) : 0
  },
  displayTaxTotal(state) {
    return state.cart ? state.cart.attributes.display_tax_total : '$0.00'
  },
  displayTotal(state) {
    return state.cart ? state.cart.attributes.display_total : '$0.00'
  },
  displayAdjustmentTotal(state) {
    return state.cart ? state.cart.attributes.display_adjustment_total : null
  },
  giftCardDisplayTotal(state) {
    return state.cart
      ? state.cart.attributes.display_total_applied_gift_card
      : '$0.00'
  },
  orderTotalAfterStoreCredit(state) {
    return state.cart
      ? state.cart.attributes.order_total_after_store_credit
      : 0.0
  },
  orderTotalAfterStoreCreditExclGiftCards(state) {
    return state.cart
      ? state.cart.attributes.order_total_after_store_credit_excl_gift_cards
      : 0.0
  },
  displayOrderTotalAfterStoreCredit(state) {
    return state.cart
      ? state.cart.attributes.display_order_total_after_store_credit
      : null
  },
  itemCount(state) {
    if (isDefined(state.cart) && isDefined(state.cart.attributes)) {
      return state.cart.attributes.item_count
    } else {
      return 0
    }
  },
  email(state) {
    return state.cart ? state.cart.attributes.email : ''
  },
  items(state) {
    // map lineItems.relationships.variant.data.id
    return []
    // return state.lineItems.map(line => {
    //   const variantId = parseInt(line.relationships.variant.data.id)
    //   const variant = state.variants[variantId]
    //   const productId = variant
    //     ? parseInt(variant.relationships.product.data.id)
    //     : variantId
    //   const images = state.images.filter(image => {
    //     return parseInt(image.attributes.viewableId) === productId
    //   })
    //   const item = {
    //     id: line.id,
    //     attributes: line.attributes,
    //     images: images,
    //     variant: state.variants[variantId]
    //   }
    //   return item
    // })
  },
  specialInstructions(state) {
    return state.specialInstructions
  },
  billingAddress(state) {
    return cartRelationship(state, 'billing_address', 'addresses')
  },
  shippingAddress(state) {
    return cartRelationship(state, 'shipping_address', 'addresses')
  },
  useShippingAddress(state) {
    const ship = cartRelationship(state, 'shipping_address', 'addresses')
    const bill = cartRelationship(state, 'billing_address', 'addresses')
    return ship && bill ? isEqual(ship.attributes, bill.attributes) : false
  },
  shippingMethods(state) {
    if (state.shipments) {
      const shipmentIds = Object.keys(state.shipments)
      return shipmentIds.map(id => {
        return state.shipments[id].attributes.selectedShippingRateName
      })
    }
    return []
  },
  appliedGiftCards(state) {
    if (state.payments) {
      return state.payments.filter(p => {
        if (p.relationships && p.relationships.source) {
          return p.relationships.source.data.type === 'gift_card'
        }
        return []
      })
    }
    return []
  },
  isWholesale(state) {
    return state.cart && state.cart.attributes.is_wholesale
  },
  displayWholesaleItemTotal(state) {
    return state.cart
      ? state.cart.attributes.display_wholesale_item_total
      : '$0.00'
  },
  shippingOptions(state) {
    if (state.shippingOptions && state.shippingOptions.length > 0) {
      return state.shippingOptions.map(opt => {
        return {
          id: opt.id,
          name: opt.attributes.name,
          price: opt.attributes.display_cost,
          free: opt.attributes.free
        }
      })
    } else {
      return []
    }
  },
  freeShipOption(state) {
    if (state.shippingOptions && state.shippingOptions.length > 0) {
      const options = state.shippingOptions.map(opt => {
        return opt.attributes.free_shipping_option
      })
      return options.includes(true)
    } else {
      return false
    }
  },
  insufficientStockLineIds(state) {
    return state.cart ? state.cart.attributes.insufficient_stock_line_ids : []
  },
  paymentComplete(state) {
    return state.cart
      ? parseFloat(state.cart.attributes.order_total_after_store_credit) === 0.0
      : false
  }
}

export const actions = {
  setCartToken({ commit }) {
    commit('SET_CART_TOKEN', null)
    const cartToken = this.$cookies.get('stomping_cart_token')
    commit('SET_CART_TOKEN', cartToken)
  },
  async setCart({ commit, dispatch, state }) {
    // const cartToken = this.$cookies.get('stomping_cart_token')
    // const bearer = this.$cookies.get('auth._token.local')
    const res = await this.$api.cart.get()
    updateCartAction(commit, dispatch, res)
  },
  async setCartIf({ dispatch, state }) {
    await dispatch('setCartToken')
    if (!isDefined(state.cart)) {
      await dispatch('setCart')
    }
  },
  async getCart({ commit, dispatch, state }, includeItems = false) {
    await dispatch('setCartToken')
    // const bearer = this.$cookies.get('auth._token.local')
    // const cartToken = this.$cookies.get('stomping_cart_token')
    const res = includeItems
      ? await this.$api.cart.getCartWithItems()
      : await this.$api.cart.getCart()

    return updateCartAction(commit, dispatch, res)
  },
  async getOrMergeCart({ commit, dispatch, state }) {
    const bearer = this.$cookies.get('auth._token.local')
    const res2 = await this.$api.cart.get(null, bearer)
    const cartUpdate = await updateCartAction(commit, dispatch, res2)
    await dispatch('setCartToken')
    if (state.shouldMerge) {
      const oldToken = state.oldToken
      await dispatch('clearCart')
      const mergeRes = await this.$api.cart.mergeCart(oldToken, bearer)
      commit('SET_MERGED_CART_ITEMS', true)
      commit('SET_SHOULD_MERGE', false)
      commit('SET_OLD_TOKEN', '')
      if (this.app.router.currentRoute.path === '/bag') {
        dispatch('setLineItems')
        dispatch('checkout/setPaymentMethods', null, { root: true })
        dispatch('estimateShipping')
      }
      return updateCartAction(commit, dispatch, mergeRes)
    } else {
      return cartUpdate
    }
  },
  async mergeCart({ commit, dispatch, state, getters }) {
    await dispatch('getCart', false)
    console.log('MERGE CART', state.token)
    // const bearer = this.$cookies.get('auth._token.local')
    // const oldCartToken = this.$cookies.get('cart_token_old')
    // const cartToken = this.$cookies.get('stomping_cart_token')

    // get existing cart
    const resExist = await this.$api.cart.get()
    // const dataExist = normalize(resExist)
    const hasExistItems =
      isDefined(resExist) &&
      isDefined(resExist.data) &&
      parseInt(resExist.data.attributes.item_count) > 0
    let existLineItems = []
    if (hasExistItems) {
      const resItems = await this.$api.lineItems.index()
      if (!resItems.error) {
        existLineItems = resItems.data
        // existLineItems = Object.keys(resItems.data.lineItem).map(itemId => {
        //   return dataExist.lineItem[itemId]
        // })
      }
    }
    let lineItemsToMerge = []
    // get the users cart
    const res = await this.$api.cart.get()

    const userLineItemsRes = await this.$api.lineItems.index()
    if (res.error) {
      return
    }

    const variantLineItems = {}
    commit('SET_MERGED_CART_ITEMS', hasExistItems)
    const hadPrevItems =
      isDefined(userLineItemsRes) &&
      isDefined(userLineItemsRes.meta) &&
      parseInt(userLineItemsRes.meta.count) > 0

    if (hadPrevItems) {
      // get a list of lineitems for each variant id if there are any
      userLineItemsRes.data.forEach(item => {
        if (isDefined(item.relationships.variant)) {
          variantLineItems[item.relationships.variant.data.id] = item.id
        }
      })
      // get a list of current line items that are not
      //  already in the user's cart
      const userLineItemIds = userLineItemsRes.data.map(i => i.id)
      lineItemsToMerge = existLineItems.filter(item => {
        return userLineItemIds[parseInt(item.id)] == null
      })
    } else {
      lineItemsToMerge = existLineItems
    }
    // update cart for current object
    updateCartAction(commit, dispatch, res)

    // merge items to the users cart
    lineItemsToMerge.map(async item => {
      const qty = item.attributes.quantity
      const variantId = item.relationships.variant.data.id
      const existLineitem = variantLineItems[variantId]
      if (existLineitem == null) {
        const mergeRes = await this.$api.cart.mergeToCart(variantId, qty)
        updateCartAction(commit, dispatch, mergeRes)
      } else {
        const lineId = existLineitem.id
        const newQty = isDefined(existLineitem.attributes)
          ? existLineitem.attributes.quantity + qty
          : qty
        const mergeRes = await this.$api.cart.setQuantity(lineId, newQty)
        updateCartAction(commit, dispatch, mergeRes)
      }
    })
  },
  async addToCart({ commit, state, dispatch }, { id, qty, giftCard }) {
    await dispatch('setCartIf')
    const res = await this.$api.cart.addToCart(id, qty, giftCard)
    if (!res.error) {
      commit('SET_MESSAGE', 'ITEM ADDED TO BAG', { root: true })
      commit('SHOW_MESSAGE', true, { root: true })
    }
    return updateCartAction(commit, dispatch, res)
  },
  async emptyCart({ commit, state, dispatch }) {
    await dispatch('setCartIf')
    const res = await this.$api.cart.emptyCart()
    if (res.error) {
      commit('SET_ERRORS', res.error)
    } else {
      commit('SET_MESSAGE', 'BAG EMPTIED', { root: true })
      commit('SHOW_MESSAGE', true, { root: true })
      await dispatch('estimateShipping')
      updateCartAction(commit, dispatch, res)
      // await dispatch('estimateShipping')
      // commit('SET_LINE_ITEMS', [])
      // commit('SET_CART', res.data)
    }
  },
  async setQuantity({ commit, state, dispatch }, { lineItemId, quantity }) {
    await dispatch('setCartIf')
    const res = await this.$api.cart.setQuantity(lineItemId, quantity)
    if (!res.error) {
      const currentlyWS = state.cart && state.cart.attributes.is_wholesale
      await dispatch('updateLineItem', lineItemId)
      commit('SET_MESSAGE', 'CART UPDATED', { root: true })
      commit('SHOW_MESSAGE', true, { root: true })
      await dispatch('estimateShipping')
      updateCartAction(commit, dispatch, res)
      const stillWS = state.cart && state.cart.attributes.is_wholesale
      if (currentlyWS !== stillWS) {
        await dispatch('setLineItems')
      }
    }
  },
  async updateLineItem({ state, commit }, lineItemId) {
    const res = await this.$api.lineItems.get(lineItemId)
    if (!res.error) {
      const normed = this.$normalizer.normalize({ data: [res.data] })
      const updatedItem = normed.lineItem[lineItemId]
      commit('UPDATE_LINE_ITEM', updatedItem)
    }
  },
  async removeLineItem({ commit, state, dispatch }, lineItemId) {
    await dispatch('setCartIf')
    const currentlyWS = state.cart && state.cart.attributes.is_wholesale
    const res = await this.$api.cart.removeLineItem(lineItemId)
    if (!res.error) {
      commit('SET_MESSAGE', 'ITEM REMOVED FROM BAG', { root: true })
      commit('SHOW_MESSAGE', true, { root: true })
      updateCartAction(commit, dispatch, res)
      const stillWS = state.cart && state.cart.attributes.is_wholesale
      if (currentlyWS !== stillWS) {
        await dispatch('setLineItems')
      } else {
        commit('REFRESH_LINE_ITEMS')
      }
      await dispatch('estimateShipping')
    }
  },
  async applyCoupon({ commit, state, dispatch }, couponCode) {
    await dispatch('setCartIf')
    const currentShipTotal = state.cart.attributes.ship_total
    const currentTotal =
      state.cart.attributes.order_total_after_store_credit_excl_gift_cards -
      currentShipTotal
    // const bearer = this.$cookies.get('auth._token.local')
    const res = await this.$api.cart.applyCoupon(couponCode)
    updateCartAction(commit, dispatch, res)
    const newShipTotal = state.cart.attributes.ship_total
    const newTotal =
      state.cart.attributes.order_total_after_store_credit_excl_gift_cards -
      newShipTotal
    const removeFreeShip = currentTotal >= 59.0 && newTotal < 59.0
    if (removeFreeShip) {
      const resetRes = await this.$api.cart.resetShipping()
      updateCartAction(commit, dispatch, resetRes)
      await dispatch('checkout/resetShipping', null, { root: true })
    }
    await dispatch('estimateShipping')
    if (this.app.router.currentRoute.path === '/bag') {
      dispatch('setLineItems')
      dispatch('checkout/setPaymentMethods', null, { root: true })
      dispatch('estimateShipping')
    }
    return true
  },
  async addGiftCard({ commit, dispatch, rootState }, code) {
    await dispatch('setCartIf')
    if (!isDefined(rootState.checkout.paymentMethods)) {
      await dispatch('checkout/setPaymentMethods')
    }
    const paymentMethod = rootState.checkout.paymentMethods.filter(m => {
      return m.attributes.type === 'Spree::PaymentMethod::GiftCard'
    })[0]
    const paymentSource = {}
    paymentSource[paymentMethod.id] = { code }
    const body = {
      order: {
        payments_attributes: [
          {
            payment_method_id: parseInt(paymentMethod.id)
          }
        ]
      },
      payment_source: paymentSource
    }
    const res = await this.$api.checkout.update(body)
    await dispatch('updateCart', res)
    if (!res.error) {
      commit('SET_GIFT_CARDS', { cart: res, paymentMethod })
    }
  },
  async removeGiftCard({ dispatch }, id) {
    const body = {
      order: {
        remove_gift_card: {
          id
        }
      }
    }
    const res = await this.$api.checkout.update(body)
    await dispatch('updateCart', res)
  },
  async removeCoupon({ commit, dispatch, state, rootState }, code) {
    const shipTotal = state.cart.attributes.ship_total
    const currentTotal =
      state.cart.attributes.order_total_after_store_credit_excl_gift_cards -
      shipTotal

    const res = await this.$api.cart.removeCoupon(code)
    await dispatch('updateCart', res)

    const newTotal =
      state.cart.attributes.order_total_after_store_credit_excl_gift_cards
    const allowFreeShip = currentTotal < 59.0 && newTotal >= 59.0
    if (allowFreeShip) {
      const resetRes = await this.$api.cart.resetShipping()
      updateCartAction(commit, dispatch, resetRes)
      await dispatch('checkout/resetShipping', null, { root: true })
    }
    await dispatch('estimateShipping')
    if (this.app.router.currentRoute.path === '/bag') {
      dispatch('setLineItems')
      dispatch('checkout/setPaymentMethods', null, { root: true })
      dispatch('estimateShipping')
    }
    return true
  },
  async setLineItems({ commit, state, dispatch }, page = 1) {
    if (isDefined(state.cart)) {
      const items = await this.$api.lineItems.index({ page })
      if (!items.error) {
        if (page === 1) {
          commit('SET_LINE_ITEMS', getIncluded(items, 'lineItem', true))
        } else {
          commit('PAGINATE_LINE_ITEMS', { items, page })
        }
        const imgs = {}
        items.data.forEach(i => {
          imgs[i.id] = i.attributes.variant_image
        })
        commit('SET_LINE_ITEM_IMAGES', imgs)
      } else {
        console.log('ERROR', !items.error)
      }
      commit('SET_LINE_ITEM_PAGES', items.meta)
    }
  },
  async setLineItemImages({ commit, state, dispatch }) {
    const imgCount = Object.keys(state.lineItemImages).length
    const imgs = {}
    const imgIds = Object.keys(state.lineItemImages)
    const missIds = state.lineItemIds.filter(id => !imgIds.includes(id))
    const perPage =
      isDefined(state.lineItems) && state.lineItems.length > 0
        ? state.lineItems.length
        : 1000
    if (imgCount === 0 || missIds.length > 0) {
      // set all images
      const { data, error } = await this.$api.lineItems.indexImages({
        perPage
      })
      if (!error) {
        data.forEach(i => {
          imgs[i.id] = i.attributes.variant_image
        })
      } else {
        console.log('ERROR', error)
      }
      commit('SET_LINE_ITEM_IMAGES', imgs)
    }
  },
  async setBagItems({ dispatch }) {
    await dispatch('getCart')
    await dispatch('setLineItems')
  },
  setSpecialInstructions({ commit }, instructions) {
    commit('SET_SPECIAL_INSTRUCTIONS', instructions)
  },
  async updateSpecialInstructions({ commit }, { orderNumber, instructions }) {
    const { data, error } = await this.$api.cart.updateSpecialInstructions(
      orderNumber,
      instructions
    )
    if (error) {
      commit('SET_ERRORS', error)
    } else {
      commit('SET_SPECIAL_INSTRUCTIONS', data.special_instructions)
    }
  },
  async resetCart({ commit, dispatch }) {
    const res = await this.$api.cart.resetCart()
    updateCartAction(commit, dispatch, res)
  },
  async updateCart({ commit, dispatch }, data) {
    await updateCartAction(commit, dispatch, data)
  },
  async setIncluded({ dispatch }, res) {
    await this.$normalizer.setIncluded('cart', res, 'shipment')
    await this.$normalizer.setIncluded('cart', res, 'promotion')
    await this.$normalizer.setIncluded('cart', res, 'address', false)
  },
  async estimateShipping({ commit }) {
    const { data } = await this.$api.cart.estimateShipping()
    if (data) {
      commit('SET_SHIPPING_OPTIONS', data)
    }
  },
  clearCart({ commit }) {
    this.$cookies.remove('stomping_cart_token')
    this.$cookies.remove('cart_token_old')
    commit('DELETE_CART')
  },
  setShouldMerge({ commit, state }) {
    if (state.cart && state.cart.attributes.item_count > 0) {
      this.$cookies.set('cart_token_old', state.cart.attributes.token)
      commit('SET_OLD_TOKEN', state.cart.attributes.token)
      commit('SET_SHOULD_MERGE', true)
    }
  }
}

const updateCartAction = async (commit, dispatch, res) => {
  if (isDefined(res)) {
    if (res.error && res.error.status === '404') {
      await dispatch('resetCart')
    } else {
      commit('SET_CART', res.data)
      commit('SET_LINE_ITEM_IDS')
      commit('SET_PAYMENTS', res.included)
      commit('SET_ADDRESSES', res.included)
      if (
        isDefined(res.data) &&
        isDefined(res.data.attributes.special_instructions)
      ) {
        commit(
          'SET_SPECIAL_INSTRUCTIONS',
          res.data.attributes.special_instructions
        )
      }
      await dispatch('setIncluded', res)
    }
  }
}

const cartRelationship = (state, relType, relState) => {
  if (state.cart && state[relState] && state.cart.relationships[relType]) {
    const id =
      state.cart.relationships[relType].data !== null &&
      state.cart.relationships[relType].data !== undefined
        ? state.cart.relationships[relType].data.id
        : null
    return state[relState][id]
  }
  return null
}
