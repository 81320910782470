import constants from './../utils/constants'
import ApiError from './error'

export default $axios => ({
  // get line_items
  async index(options = {}) {
    const defaultOptions = {
      includes: constants.lineItemsIncludes,
      page: 1,
      perPage: constants.lineItemsPerPage,
      sort: '-created_at'
    }
    const paramsOptions = { ...defaultOptions, ...options }
    const { sort, includes, perPage, page } = paramsOptions
    const params = `?sort=${sort}&include=${includes}&per_page=${perPage}&page=${page}&fields[line_item]=${
      constants.lineItemFields
    }`
    // const params = `${filter}sort=${sort}&include=${includes}&per_page=${perPage}&page=${page}`
    try {
      const { data } = await $axios.get(`api/line_items${sanitizeUrl(params)}`)
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async get(id, options = {}) {
    const defaultOptions = {
      includes: constants.lineItemsIncludes,
      page: 1,
      perPage: constants.lineItemsPerPage,
      sort: '-created_at',
      fields: constants.lineItemFields
    }
    const paramsOptions = { ...defaultOptions, ...options }
    const { sort, includes, perPage, page, fields } = paramsOptions
    const params = `?sort=${sort}&include=${includes}&per_page=${perPage}&page=${page}&fields[line_item]=${fields}`
    try {
      const { data } = await $axios.get(
        `api/line_items/${id}${sanitizeUrl(params)}`
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async indexImages(options = {}) {
    const defaultOptions = {
      page: 1,
      perPage: 1000
    }
    const paramsOptions = { ...defaultOptions, ...options }
    const { perPage, page } = paramsOptions
    const params = `?fields[line_item]=variant_image&per_page=${perPage}&page=${page}`
    try {
      const { data } = await $axios.get(`api/line_items${sanitizeUrl(params)}`)
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  }
})

// const setHeader = (token, bearer) => {
//   const opts = {
//     headers: {
//       Authorization: bearer || false
//     }
//   }
//   if (token) {
//     opts.headers['X-Spree-Order-Token'] = token
//   }
//   return opts
// }

const sanitizeUrl = url => url.split('#').join('%23')
