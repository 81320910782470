import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

config.autoAddCss = false

    import  { faHome as fortawesomefreesolidsvgicons_faHome } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faHome)

    import  { faSearch as fortawesomefreesolidsvgicons_faSearch } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSearch)

    import  { faBars as fortawesomefreesolidsvgicons_faBars } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faBars)

    import  { faKey as fortawesomefreesolidsvgicons_faKey } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faKey)

    import  { faShoppingBag as fortawesomefreesolidsvgicons_faShoppingBag } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faShoppingBag)

    import  { faBell as fortawesomefreesolidsvgicons_faBell } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faBell)

    import  { faCheck as fortawesomefreesolidsvgicons_faCheck } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCheck)

    import  { faPencilAlt as fortawesomefreesolidsvgicons_faPencilAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPencilAlt)

    import  { faTimes as fortawesomefreesolidsvgicons_faTimes } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faTimes)

    import  { faAngleLeft as fortawesomefreesolidsvgicons_faAngleLeft } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faAngleLeft)

    import  { faAngleRight as fortawesomefreesolidsvgicons_faAngleRight } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faAngleRight)

    import  { faAngleDoubleRight as fortawesomefreesolidsvgicons_faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faAngleDoubleRight)

    import  { faUser as fortawesomefreesolidsvgicons_faUser } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faUser)

    import  { faLock as fortawesomefreesolidsvgicons_faLock } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faLock)

    import  { faLockOpen as fortawesomefreesolidsvgicons_faLockOpen } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faLockOpen)

    import  { faChevronUp as fortawesomefreesolidsvgicons_faChevronUp } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faChevronUp)

    import  { faChevronDown as fortawesomefreesolidsvgicons_faChevronDown } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faChevronDown)

    import  { faEnvelope as fortawesomefreesolidsvgicons_faEnvelope } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faEnvelope)

    import  { faRedo as fortawesomefreesolidsvgicons_faRedo } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faRedo)

    import  { faHeart as fortawesomefreesolidsvgicons_faHeart } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faHeart)

    import  { faMapMarkerAlt as fortawesomefreesolidsvgicons_faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faMapMarkerAlt)

    import  { faCircle as fortawesomefreesolidsvgicons_faCircle } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCircle)

    import  { faPlus as fortawesomefreesolidsvgicons_faPlus } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPlus)

    import  { faMinus as fortawesomefreesolidsvgicons_faMinus } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faMinus)

    import  { faExclamationCircle as fortawesomefreesolidsvgicons_faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faExclamationCircle)

    import  { faHeart as fortawesomefreeregularsvgicons_faHeart } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faHeart)

    import  { faCopyright as fortawesomefreeregularsvgicons_faCopyright } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faCopyright)

    import  { faBell as fortawesomefreeregularsvgicons_faBell } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faBell)

    import  { faCalendarAlt as fortawesomefreeregularsvgicons_faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faCalendarAlt)

    import  { faTrashAlt as fortawesomefreeregularsvgicons_faTrashAlt } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faTrashAlt)

    import  { faCreditCard as fortawesomefreeregularsvgicons_faCreditCard } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faCreditCard)

    import  { faQuestionCircle as fortawesomefreeregularsvgicons_faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faQuestionCircle)

    import  { faFacebookSquare as fortawesomefreebrandssvgicons_faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faFacebookSquare)

    import  { faFacebookF as fortawesomefreebrandssvgicons_faFacebookF } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faFacebookF)

    import  { faInstagram as fortawesomefreebrandssvgicons_faInstagram } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faInstagram)

    import  { faPinterestP as fortawesomefreebrandssvgicons_faPinterestP } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faPinterestP)

    import  { faTwitter as fortawesomefreebrandssvgicons_faTwitter } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faTwitter)

    import  { faCcVisa as fortawesomefreebrandssvgicons_faCcVisa } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faCcVisa)

    import  { faCcMastercard as fortawesomefreebrandssvgicons_faCcMastercard } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faCcMastercard)

Vue.component('fa', FontAwesomeIcon)
Vue.component('fa-layers', FontAwesomeLayers)
Vue.component('fa-layers-text', FontAwesomeLayersText)
