<template>
  <div class="contact-us-form">
    <h5 v-if="!noTitle">
      {{ title }}
    </h5>
    <div>
      <b-form @submit.prevent="validateBeforeSubmit" @reset="onReset">
        <contact-fields ref="contactFields" />
        <b-form-group
          id="message-group"
          label="What can we help you with?:"
          label-for="message-text"
        >
          <b-form-textarea
            id="message-text"
            v-model="message"
            v-validate="{ required: true, min: 3, max: 600 }"
            :state="validateState('message')"
            name="message"
            aria-describedby="message-feedback"
            placeholder="What's on your mind?"
            rows="10"
            max-rows="15"
          />
          <b-form-invalid-feedback id="message-feedback">
            Region required (min. 3 characters, max. 600 characters)
          </b-form-invalid-feedback>
        </b-form-group>
        <no-ssr>
          <recaptcha
            @error="onRecaptchaError"
            @success="onRecaptchaSuccess"
            @expired="onRecaptchaExpired"
          />
        </no-ssr>
        <b-button type="submit" variant="primary">
          Submit
        </b-button>
        <b-button type="reset" variant="outline-dark">
          Reset
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import ContactFields from '~/components/ContactFields'
import validateState from './../mixins/validateState'

export default {
  components: {
    'contact-fields': ContactFields
  },
  mixins: [validateState],
  props: {
    title: {
      type: String,
      default: 'Contact Form'
    },
    noTitle: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      message: '',
      submitted: false
    }
  },
  computed: {
    formData() {
      return {
        msgType: 'general_enquiry',
        formData: {
          ...{ message: this.message },
          ...this.$refs.contactFields.contactData
        }
      }
    }
  },
  methods: {
    async validData() {
      const thisIsValid = await this.isValid()
      const validContact = await this.$refs.contactFields.isValid()
      return thisIsValid && validContact
    },
    async validateBeforeSubmit() {
      const canSubmit = await this.validData()
      if (!canSubmit) {
        return
      }
      try {
        // const token = await this.$recaptcha.getResponse()
        await this.$recaptcha.reset()
        await this.$store.dispatch('submitContactForm', this.formData)
        this.onReset()
        this.submitted = true
      } catch (error) {
        console.log('Login error:', error)
      }
    },
    onReset() {
      this.$refs.contactFields.reset()
      this.message = ''
      this.$nextTick(() => {
        // this.$refs.observer.reset()
        // this.veeErrors.clear()
        this.resetState()
        this.$refs.contactFields.resetState()
        if (this.submitted) {
          this.submitted = false
          this.$emit('contact-us-submitted')
        }
      })
    },
    onRecaptchaSuccess(token) {
      console.log('Succeeded')
    },
    onRecaptchaExpired() {
      console.log('Expired')
    },
    onRecaptchaError(error) {
      console.log('Error happened:', error)
    }
  }
}
</script>
<style lang="scss">
.contact-us-form {
  margin: 1.5rem 0;
}
@media (min-width: 768px) {
  .contact-us-form {
    margin: 1.5rem 1.5rem;
  }
}
</style>
