export default {
  methods: {
    categoryNavItems(category, themes) {
      return themes.map(t => {
        const catRoute = this.$store.state.sitenav.navItems[category].route
        return {
          label: t.attributes.name,
          route: `${catRoute}/${baseLink(t.attributes.permalink)}`
        }
      })
    }
  }
}

const baseLink = link => {
  return link.split('/').reverse()[0]
}
