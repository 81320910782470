<template>
  <div class="stomping-alerts">
    <b-row>
      <div v-if="whiteBackground" class="full-width-div bg-white" />
      <b-col class="mt-4">
        <p class="pink">
          Stomping Alerts
          <fa :icon="['far', 'bell']" size="1x" class="ml-1" />
        </p>
        <p class="small">
          Join our community of amazing Stompers! Sign up to the Stomping newsletter to learn about new products, exclusive deals and online-only promotions for Australia’s best handmade jewellery.
        </p>
        <div class="d-flex flex-grow mb-3 stomping-alerts__controls">
          <div class="mr-2 w-100">
            <b-form-input
              id="mailing-name"
              v-model="name"
              v-validate="{ required: true, min: 3 }"
              :state="validateState('mailing-name')"
              name="mailing-name"
              aria-describedby="mailing-name-feedback"
              size="sm"
              type="text"
              placeholder="FIRST NAME"
            />
            <b-form-invalid-feedback id="mailing-name-feedback">
              Name required (min. 3 characters)
            </b-form-invalid-feedback>
          </div>
          <div class="mr-2 w-100">
            <b-form-input
              id="mailing-email"
              v-model="email"
              v-validate="'required|email'"
              name="mailing-email"
              size="sm"
              type="text"
              placeholder="EMAIL"
              :state="validateState('mailing-email')"
              aria-describedby="mailing-email-feedback"
            />
            <b-form-invalid-feedback id="mailing-email-feedback">
              Invalid email
            </b-form-invalid-feedback>
          </div>
          <div class="mr-2 w-100">
            <b-form-input
              id="mailing-date"
              v-model="date"
              v-validate="'date_format:dd/MM'"
              :value="formatDate"
              name="mailing-date"
              size="sm"
              type="text"
              :state="validateState('mailing-date')"
              aria-describedby="mailing-date-feedback"
              :placeholder="placeholder"
            />
            <b-form-invalid-feedback id="mailing-date-feedback">
              Date must be in the dd/mm format
            </b-form-invalid-feedback>
          </div>
          <b-btn
            size="sm"
            class="mx-1 px-5 py-0"
            :disabled="disabled"
            @click="subscribe"
          >
            JOIN
          </b-btn>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="error.length > 0">
      <b-col>
        <p class="se-pink">
          {{ error }}
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
// import mailchimp from '@mailchimp/mailchimp_marketing'
import validateState from './../mixins/validateState'
export default {
  mixins: [validateState],
  props: {
    whiteBackground: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: '',
      email: '',
      date: '',
      format: 'DD/MM',
      placeholder: 'BIRTHDAY (DD/MM)',
      error: '',
      disabled: false
    }
  },
  computed: {
    formatDate() {
      return this.date ? moment(this.date).format('DD/MM/YYYY') : ''
    },
    contact() {
      const bday =
        this.date !== '' && this.date.split('/').length > 0
          ? this.date
              .split('/')
              .reverse()
              .join('/')
          : ''
      return {
        email_address: this.email, // we set 'emailAddress' key in the front-end
        status: 'subscribed', //  signifies user is subscribed for mailchimp(https://goo.gl/ejX1nu)
        merge_fields: {
          FNAME: this.name,
          MMERGE4: bday
        }
      }
    }
  },
  methods: {
    handleNewDateInput(d) {
      this.date = d
    },
    openCalendar() {
      this.$refs.birthday.showCalendar()
    },
    resetForm() {
      this.email = ''
      this.name = ''
      this.date = ''
    },
    successMessage(message) {
      this.$bvToast.toast(message, {
        title: 'Thanks',
        variant: 'success',
        autoHideDelay: 5000,
        appendToast: true
      })
    },
    async subscribe() {
      const id = this.$store.state.stompingAlertsId
      this.disabled = true
      this.error = ''
      const { error } = await this.$api.mailchimp.getMember(id, this.email)
      if (error === null || error === undefined) {
        // user already subscribed
        const { error } = await this.$api.mailchimp.update(id, this.contact)
        if (error !== null && error !== undefined) {
          this.error = error.message
          this.resetState()
        } else {
          this.successMessage(
            `Thank you ${this.name}. Your subscription has been updated.`
          )
        }
      } else {
        const { error } = await this.$api.mailchimp.subscribe(id, this.contact)
        if (error !== null && error !== undefined) {
          this.error = error.message
        } else {
          this.successMessage(
            `Thank you ${this.name}. You have been added to our list.`
          )
        }
      }
      this.resetForm()
      this.resetState()
      this.disabled = false
    }
  }
}
</script>
