import Vue from 'vue'
import { loadScript } from '@paypal/paypal-js'

export default ({ $config: { paypalKey } }) => {
  try {
    loadScript({
      'client-id': paypalKey,
      currency: 'AUD',
      locale: 'en_AU'
    })
      .then(paypal => {
        const PayPalButton = paypal.Buttons.driver('vue', Vue)
        Vue.component('PaypalButton', PayPalButton)
      })
      .catch(error => {
        console.error('failed to load the PayPal JS SDK script!!!!!', error)
      })
  } catch (error) {
    console.error('failed to load the PayPal JS SDK script', error)
  }
}
