export const getParam = (url, param) => {
  const params = {}
  url
    .substring(url.indexOf('?') + 1)
    .split('&')
    .map(p => {
      const a = p.split('=')
      params[a[0]] = a[1]
    })
  return params[param]
}
