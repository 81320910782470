import getIncluded from './getIncluded'
export default product => {
  const optionTypes = getIncluded(product, 'optionType')
  const optionValues = getIncluded(product, 'optionValue')
  const types = {}
  if (optionTypes === null || optionTypes === undefined) {
    return {}
  }
  optionTypes.map(t => {
    types[t.id] = {
      id: t.id,
      name: t.attributes.name,
      presentation: t.attributes.presentation,
      position: t.attributes.position,
      values: {}
    }
  })
  optionValues.map(v => {
    types[v.relationships.optionType.data.id].values[v.id] = {
      id: v.id,
      name: v.attributes.name,
      presentation: v.attributes.presentation,
      position: v.attributes.position
    }
  })
  return types
}
