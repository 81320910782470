<template>
  <div class="d-flex search-item__outer justify-content-between flex-wrap">
    <div class="d-flex flex-grow-1">
      <div class="image-content">
        <img :src="imageUrl" class="cartitem__image">
      </div>
      <div class="flex-grow-1">
        <p class="product-name">
          {{ product.attributes.name }}
        </p>
        <p class="search-item__price">
          <span>
            {{ product.attributes.displayPrice }}
          </span>
        </p>
      </div>
      <div>
        <b-button size="sm" @click="productClick(product)">
          View
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {}
    },
    image: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    imageUrl() {
      return this.image ? this.image.attributes.styles[0].publicUrl : ''
    }
  },
  methods: {
    productClick(product) {
      this.$store.commit('HIDE_NAV_SMALL')
      this.$router.push(`/shop/${product.attributes.slug}`)
      this.$emit('product-click')
    }
  }
}
</script>
<style lang="scss">
.product-name {
  overflow: hidden;
}
</style>
