<template>
  <div>
    <b-form v-if="loggedIn">
      <b-form-group
        id="ig-name"
        label-for="review__name"
      >
        <slot name="label">
          <div class="mb-1">
            <span class="se-pink">
              *
            </span>
            Name
          </div>
        </slot>
        <b-form-input
          id="review__name"
          v-model="name"
          v-validate="{ required: true, min: 3 }"
          :state="validateState('review__name')"
          name="review__name"
          aria-describedby="review__name-fb"
          required
          placeholder="name"
        />
        <b-form-invalid-feedback id="review__name-fb">
          Name required (min. 3 characters)
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="ig-title"
        label-for="review__title"
      >
        <slot name="label">
          <div class="mb-1">
            <span class="se-pink">
              *
            </span>
            Review Title
          </div>
        </slot>
        <b-form-input
          id="review__title"
          v-model="title"
          v-validate="{ required: true, min: 3 }"
          :state="validateState('review__title')"
          name="review__title"
          aria-describedby="review__title-fb"
          required
          placeholder="Review title"
        />
        <b-form-invalid-feedback id="review__title-fb">
          Title required (min. 3 characters)
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="review__review"
        label-for="textarea"
      >
        <slot name="label">
          <div class="mb-1">
            <span class="se-pink">
              *
            </span>
            Review
          </div>
        </slot>      
        <b-form-textarea
          id="textarea"
          v-model="review"
          v-validate="{ required: true, min: 3, max: 400 }"
          :state="validateState('review__review')"
          name="review__review"
          aria-describedby="review__review-fb"
          required
          placeholder="Write your review"
          rows="6"
          max-rows="8"
        />
        <b-form-invalid-feedback id="review__review-fb">
          Review required (min. 3 characters, max. 400 characters)
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="review__rating"
        label="Rating"
        label-for="textarea"
      >
        <no-ssr>
          <star-rating
            v-model="rating"
            v-validate="{ required: true }"
            :state="validateState('review__rating')"
            name="review__rating"
            aria-describedby="review__rating-fb"
            required
            :star-size="30"
            :increment="1"
            :show-rating="true"
            :read-only="false"
            class="mr-1 mb-1"
          />
        </no-ssr>
        <b-form-invalid-feedback id="review__rating-fb" :force-show="ratingError">
          Rating required
        </b-form-invalid-feedback>
      </b-form-group>
    </b-form>
    <div v-else>
      <p>
        You must be logged in to leave a review!
      </p>
      <sign-up-form />
      <p>
        Don't have an account. 
        <b-link to="/sign-up">
          Create one
        </b-link>
        to get started.
      </p>
    </div>
  </div>
</template>

<script>
import validateState from './../mixins/validateState'
import SignInUpForm from '~/components/SignInUpForm'
export default {
  components: {
    'sign-up-form': SignInUpForm
  },
  mixins: [validateState],
  data() {
    return {
      name: '',
      title: '',
      review: '',
      rating: null
    }
  },
  computed: {
    loggedIn() {
      return this.$auth.loggedIn
    },
    ratingError() {
      const ratingErrors = this.veeErrors.items.filter(e => {
        return e.field === 'review__rating'
      })
      return ratingErrors && ratingErrors.length > 0
    }
  }
}
</script>
