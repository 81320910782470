<template>
  <div>
    <div
      v-if="show"
      class="filter-group__dropdown-contents"
    >
      <span v-if="inlineProducts" class="mt-3">
        <div v-for="product in inlineProducts.product" :key="'filter-group-product_' + product.id" class="filter-group__dropdown-items">
          <Inline-search-item :product="product" :image="productImage(product)" class="mb-3" />
        </div>
        <div class="ml-3 mb-1 d-flex justify-content-between">
          <div>
            {{ count }} products found
            <b-link @click="linkClick">
              (See more...)
            </b-link>
          </div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InlineSearchItem from '~/components/InlineSearchItem'
// import FilterTags from '~/components/FilterTags'
export default {
  components: {
    // 'filter-tags': FilterTags,
    'Inline-search-item': InlineSearchItem
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    inlineSearch: {
      type: Boolean,
      default: true
    },
    inlineProducts: {
      type: Object,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      filterText: 'products/filterText',
      filterSize: 'products/filterSize',
      filterColors: 'products/filterColors'
    }),
    tagContainerStyle() {
      return this.show
        ? 'background-color: white; margin-left: 0.1rem; margin-right: 0.5rem;'
        : ''
    }
  },
  methods: {
    nullOrUndefined(obj) {
      return obj === null || obj === undefined
    },
    productImage(product) {
      if (
        this.nullOrUndefined(product.relationships.images.data) ||
        this.nullOrUndefined(product.relationships.images.data[0])
      ) {
        return ''
      }
      const id = product.relationships.images.data[0].id
      return this.inlineProducts.image ? this.inlineProducts.image[id] : ''
    },
    tagsUpdated() {
      this.$emit('filter')
    },
    async linkClick() {
      if (this.$route.name === 'shop') {
        this.$store.commit('products/SHOP_UPDATED', true)
        await this.$store.dispatch('products/productsList')
        this.$emit('link-clicked')
        this.$store.commit('HIDE_NAV_SMALL')
      } else {
        this.$router.push('/shop')
        this.$store.commit('HIDE_NAV_SMALL')
      }
    }
  }
}
</script>
