<template>
  <b-form-input
    v-if="loaded"
    :id="id"
    ref="search"
    v-model="keywords"
    :autofocus="autoFocus"
    class="mr-auto w-100 no-highlight"
    size="sm"
    type="text"
    autocomplete="off"
    placeholder="what are you looking for?"
  />
</template>
<script>
import { mapState } from 'vuex'
import { debounce } from 'lodash'
export default {
  props: {
    loaded: {
      type: Boolean,
      default: true
    },
    active: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'search-input'
    },
    inlineSearch: {
      type: Boolean,
      default: false
    },
    autoFocus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('products', {
      filterText: state => state.filterText
    }),
    keywords: {
      get() {
        return this.active ? this.filterText.join(' ') : ''
      },
      set: debounce(async function(value) {
        this.$store.commit('products/SET_SORT', 'best_match')
        const payload = {
          filterType: 'text',
          filter: value,
          listProd: !this.inlineSearch
        }
        await this.$store.dispatch('products/updateFilters', payload)
        // this.inlineFilter()
        this.$emit('filter')
      }, 500)
    }
  }
}
</script>
