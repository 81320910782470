<template>
  <div class="mb-3">
    <b-navbar
      id="navbar-main"
      type="dark"
      variant="secondary"
      class="p-1 text-center navbar__bottom"
    >
      <b-navbar-brand
        class="p-0 d-none d-sm-flex"
        to="/"
      >
        <span class="mr-1">
          <fa :icon="['fas', 'home']" size="1x" />
        </span>
        <!-- <img src="~/assets/img/Australia-Icon.png" alt="Australia" class="mr-1"> -->
        Home
      </b-navbar-brand>
      <b-navbar-nav class="ml-sm-auto d-none d-sm-flex navbar-main__nav">
        <account-dropdown />
        <bag-button />
        <!-- <b-button link class="small-padding-y pr-1 ml-auto align-items-center">Search</b-button> -->
        <!-- <cart-dropdown /> -->
        <span class="ml-2">|</span>
        <b-nav-item-dropdown
          ref="search-dropdown"
          no-caret
          right
          class="small-padding-y pr-1 ml-auto align-items-center"
          @show="searchShown"
          @hide="searchHidden"
        >
          <template slot="button-content">
            <fa icon="search" />
          </template>
          <b-dropdown-form inline class="d-none d-sm-block small-padding-y" @submit.stop.prevent>
            <b-form-group>
              <div class="searchBlock__dropdown d-flex">
                <label class="flex-shrink-1 navbar__hello mr-2" for="inlineFormCustomSelectPref">hello,</label>
                <search-bar
                  :id="'dd-search-bar'"
                  ref="search"
                  :show="true"
                  :loaded="searchLoaded"
                  :inline-search="true"
                  :auto-focus="true"
                  class=""
                  @filter="inlineFilter"
                />
              </div>
            </b-form-group>
          </b-dropdown-form>
          <filter-tags :items="tagItems" :inline-search="true" class="ml-1" @filter="inlineFilter" />
          <inline-filter
            :show="show"
            :active="active"
            :inline-products="inlineProducts"
            :count="count"
            :inline-search="true" 
            @filter="inlineFilter"
            @link-clicked="closeDropdown"
          />
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <nav-small />
    </b-navbar>
    <b-collapse id="navbar__small-dropdowns" v-model="collapseVisible" class="">
      <b-card>
        <!-- SEARCH FORM -->
        <b-form
          v-if="navSmallComponent === 'search'"
          class="w-100"
          @submit.stop.prevent
        >
          <b-form-group>
            <div class="searchBlock__dropdown d-flex w-100 px-3">
              <label class="flex-shrink-1 navbar__hello mr-2" for="inlineFormCustomSelectPref">hello,</label>
              <search-bar
                :id="'dd-search-bar'"
                ref="search"
                :show="true"
                :loaded="collapseVisible"
                :inline-search="true" 
                class=""
                @filter="inlineFilter"
              />
            </div>
          </b-form-group>
          <filter-tags :items="tagItems" :inline-search="true" class="ml-1" @filter="inlineFilter" />
          <inline-filter
            :show="show"
            :active="true"
            :inline-products="inlineProducts"
            :count="count"
            :inline-search="true" 
            @filter="inlineFilter"
            @link-clicked="closeDropdown"
          />
        </b-form>
        <!-- ACCOUNT NAV -->
        <account-nav-items v-if="navSmallComponent === 'account'" />
        <!-- BAG NAV -->
        <!-- <cart-items v-if="navSmallComponent === 'bag'" /> -->
        <!-- <bag-button /> -->
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import normalize from 'json-api-normalizer'
// import CartDropdown from './CartDropdown'
import SearchBar from '~/components/SearchBar'
import InlineFilter from '~/components/InlineFilter'
import FilterTags from '~/components/FilterTags'
// import CartItems from '~/components/CartItems'
import AccountNavItems from '~/components/AccountNavItems'
import NavSmall from '~/components/NavSmall'
import AccountDropdown from './AccountDropdown'
import BagButton from './BagButton'
export default {
  components: {
    // 'cart-dropdown': CartDropdown,
    'account-dropdown': AccountDropdown,
    'inline-filter': InlineFilter,
    'filter-tags': FilterTags,
    'search-bar': SearchBar,
    'nav-small': NavSmall,
    // 'cart-items': CartItems,
    'account-nav-items': AccountNavItems,
    'bag-button': BagButton
  },
  data() {
    return {
      count: 0,
      inlineProducts: null,
      searchLoaded: false,
      active: false,
      collapseVisible: false
    }
  },
  computed: {
    ...mapGetters({
      itemCount: 'cart/itemCount',
      displayTotal: 'cart/displayTotal',
      items: 'cart/items',
      filters: 'products/filters',
      keywordFilter: 'products/keywordFilter',
      filterText: 'products/filterText',
      filterSize: 'products/filterSize',
      filterColors: 'products/filterColors'
    }),
    bagText() {
      return this.itemCount === 1
        ? `Shopping bag ${this.itemCount} item (${this.displayTotal})`
        : `Shopping bag ${this.itemCount} items (${this.displayTotal})`
    },
    bagTextXS() {
      return `(${this.displayTotal})`
    },
    loggedIn() {
      return this.$store.state.account.loggedIn
    },
    filterText() {
      return this.$store.state.products.filterText
    },
    hasFilters() {
      return this.filterText.length > 0
    },
    show() {
      return this.hasFilters
    },
    tagItems() {
      const keywords = this.filterText.map(k => {
        return { name: k, type: 'text' }
      })
      const colors = this.filterColors.map(c => {
        return { name: c, type: 'color' }
      })
      const sizes = this.filterSize.map(s => {
        return { name: s, type: 'size' }
      })
      return [...keywords, ...sizes, ...colors]
    },
    navSmallShown() {
      return this.$store.state.navSmallShown
    },
    navSmallComponent() {
      return this.$store.state.navSmallComponent
    }
  },
  watch: {
    navSmallShown(newVal, oldVal) {
      this.collapseVisible = newVal
    }
  },
  methods: {
    async inlineFilter() {
      this.active = true
      const options = {
        filter: this.keywordFilter,
        page: 1,
        perPage: 3
      }
      const data = await this.$api.products.index(options)
      const normed = normalize(data)
      this.count = data.meta.total_count
      this.inlineProducts = normed
    },
    searchShown() {
      this.active = true
      this.searchLoaded = true
    },
    searchHidden() {
      this.active = false
      this.searchLoaded = false
    },
    closeDropdown() {
      this.$refs['search-dropdown'].hide(true)
    }
  }
}
</script>
<style lang="scss">
#primary-nav {
  .dropdown-toggle,
  .navbar-brand {
    font-size: 0.92rem !important;
    &:hover {
      color: #d9d9d9 !important;
    }
  }
  a.nav-link {
    &:hover {
      color: #d9d9d9 !important;
    }
  }
}
</style>
