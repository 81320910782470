<template>
  <div>
    <b-list-group horizontal class="filter-tags">
      <b-list-group-item v-for="(item, index) in items" :key="item.type + '-' + item.name" :class="{'flex-grow-1' : index === (items.length-1)}">
        {{ item.name }}
        <fa
          icon="times"
          class="filter-tag-icon"
          @click.stop.prevent="tagClicked(item)"
        />
      </b-list-group-item>
      <b-list-group-item v-if="items.length > 0">
        <span class="hand-pointer se-pink-hover" @click.stop.prevent="clearAll">
          CLEAR FILTERS
          <!-- <fa
            icon="times"
            class="filter-tag-icon"
            @click.stop.prevent="clearAll"
          /> -->
        </span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    inlineSearch: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      filterText: 'products/filterText',
      filterSize: 'products/filterSize',
      filterColors: 'products/filterColors'
    }),
    keywordItems() {
      const keywords = this.filterText.map(k => {
        return { name: k, type: 'text' }
      })
      return keywords
    },
    colorItems() {
      const colors = this.filterColors.map(c => {
        return { name: c, type: 'color' }
      })
      return colors
    },
    sizeItems() {
      const sizes = this.filterSize.map(s => {
        return { name: s, type: 'size' }
      })
      return sizes
    }
  },
  methods: {
    async tagClicked(tag) {
      let filter = []
      switch (tag.type) {
        case 'text':
          filter = this.keywordItems
            .filter(k => k.name !== tag.name)
            .map(k => k.name)
            .join(' ')
            .trim()
          break
        case 'size':
          filter = this.sizeItems
            .filter(k => k.name !== tag.name)
            .map(k => k.name)
          break
        default:
          filter = this.colorItems
            .filter(k => k.name !== tag.name)
            .map(k => k.name)
          break
      }
      const payload = {
        filterType: tag.type,
        filter: filter,
        listProd: !this.inlineSearch
      }
      await this.$store.dispatch('products/updateFilters', payload)
      this.$emit('filter')
    },
    async clearAll() {
      await this.$store.dispatch('products/resetFilterBar')
      await this.$store.dispatch('products/productsList')
      this.$emit('filter')
    }
  }
}
</script>
<style lang="scss">
.filter-tag-icon {
  cursor: pointer;
}
.hand-pointer {
  cursor: pointer;
}
.se-pink-hover {
  &:hover {
    color: #f05d77;
  }
}
</style>
