<template>
  <b-nav v-if="loggedIn" vertical class="ml-3">
    <b-nav-item to="/account/info" @click="hideCollapse">
      <fa :icon="['fas', 'user']" size="1x" />
      Profile 
    </b-nav-item>
    <b-nav-item to="/account/orders" @click="hideCollapse">
      <fa :icon="['fas', 'shopping-bag']" size="1x" />
      Orders 
    </b-nav-item>
    <b-nav-item to="/account/wishlist" @click="hideCollapse">
      <fa :icon="['fas', 'heart']" size="1x" />
      Wish List 
    </b-nav-item>
    <b-nav-item @click="logOutAndHide">
      <fa :icon="['fas', 'lock']" size="1x" />
      Log out
    </b-nav-item>
  </b-nav>
  <b-nav v-else vertical class="ml-3">
    <b-nav-item @click="showSignUp()">
      <fa :icon="['fas', 'pencil-alt']" size="1x" class="p-0" />
      Sign up
    </b-nav-item>
    <b-nav-item @click="showLogin()">
      <fa :icon="['fas', 'key']" size="1x" class="p-0" />
      Log in
    </b-nav-item>
  </b-nav>
</template>

<script>
export default {
  methods: {
    hideCollapse() {
      this.$store.commit('HIDE_NAV_SMALL')
    },
    logOutAndHide() {
      this.logOut()
      this.$store.commit('HIDE_NAV_SMALL')
    }
  }
}
</script>
