import { titleCase } from './../utils/format'

export default ({ store, $auth, redirect, route }) => {
  $auth.$storage.watchState('loggedIn', newValue => {
    if (newValue) {
      const user = $auth.user
      const name = user.data.attributes.first_name
        ? user.data.attributes.first_name
        : user.data.attributes.email.split('@')[0]
      store.commit('account/SET_LAST_USER', titleCase(name))
      if (store.state.account.passwordReset) {
        store.commit(
          'SET_MESSAGE',
          `Hello ${titleCase(name)}, your password has been reset.`
        )
      } else {
        store.commit('SET_MESSAGE', `Hello ${titleCase(name)}`)
      }
      store.commit('SHOW_MESSAGE', true)
      // redirect if in checkout
      if (route.path === '/checkout') {
        store.dispatch('checkout/clearShipping')
        redirect('/bag')
      }
    } else {
      const user = titleCase(store.state.account.lastUser)
      store.commit('SET_MESSAGE', `Goodbye ${user}`)
      store.commit('SHOW_MESSAGE', true)
    }
  })
}
