export default $axios => ({
  async index(iso) {
    try {
      const data = await $axios.get(
        `api/wholesalers?include=user,business_address&per_page=500`
      )
      return data
    } catch (error) {
      return error
    }
  }
})
