import nuxtStorage from 'nuxt-storage'

export default async function({ store, route, from }) {
  const current = nuxtStorage.localStorage.getData('currentPage')
  if (current && current.from === route.name) {
    const productsPageNo =
      store.state.prevPageParams && store.state.prevPageParams.productsPageNo
        ? store.state.prevPageParams.productsPageNo
        : 1
    await store.commit('products/SET_CURRENT_PAGE', productsPageNo)
    await store.dispatch('products/restoreFilters')
  } else if (
    route.name &&
    route.name !== 'shop-slug' &&
    route.name.includes('shop-')
  ) {
    await store.commit('products/SET_CURRENT_PAGE', 1)
    await store.dispatch('products/resetFilters')
    await store.dispatch('products/updateFilterStorage')
  }
  const fromName = from ? from.name : ''
  const toName = route.name
  const payload = {
    to: toName,
    from: fromName,
    page: store.state.products.currentPage
  }
  nuxtStorage.localStorage.setData('currentPage', payload, 1, 'd')
  store.dispatch('setRandSeed')
}
