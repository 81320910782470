import Vue from 'vue'
import { numberToMoney } from './../utils/format'
Vue.mixin({
  computed: {
    wholesaleRemaining() {
      const total = this.$store.state.cart.cart
        ? this.$store.state.cart.cart.attributes.wholesale_item_total
        : 0.0
      return numberToMoney(300.0 - total)
    },
    isUnderWholesaleMin() {
      const isWholesaler = this.$auth.user
        ? this.$auth.user.data.attributes.wholesaler
        : false
      const isWholesale =
        this.$store.state.cart.cart &&
        this.$store.state.cart.cart.attributes.is_wholesale
      return isWholesaler && !isWholesale
    }
  },
  methods: {
    underMinWholesale() {
      console.log('RUNNING METHOD')
      if (this.$auth.loggedIn && this.isUnderWholesaleMin) {
        this.$store.commit('SHOW_MODAL', null)
        this.$store.commit('SHOW_MODAL', 'min-order-modal')
      }
    }
  }
})
