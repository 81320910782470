<template>
  <b-nav-item-dropdown no-caret class="small-padding-y" right>
    <!-- Using 'button-content' slot -->
    <template slot="button-content">
      <span>
        <div class="text-small pl-2 d-none d-sm-block">
          <no-ssr>
            <fa-layers class="mr-2">
              <fa :icon="['fas', 'user']" size="1x" />
              <fa :icon="icons" size="1x" transform="down-3 right-8 shrink-6" class="pink" />
            </fa-layers>
          </no-ssr>
          My Account
        </div>
        <div class="text-small pl-2 d-block d-sm-none">
          <no-ssr>
            <fa-layers class="mr-2">
              <fa :icon="['fas', 'user']" size="1x" />
              <fa :icon="icons" size="1x" transform="down-3 right-8 shrink-6" class="pink" />
            </fa-layers>
          </no-ssr>
        </div>
      </span>
    </template>
    <span v-show="loggedIn">
      <b-dropdown-item to="/account/info" class="small-padding-y">
        <fa :icon="['fas', 'user']" size="1x" />
        Profile 
      </b-dropdown-item>
      <b-dropdown-item to="/account/orders" class="small-padding-y">
        <fa :icon="['fas', 'shopping-bag']" size="1x" />
        Orders 
      </b-dropdown-item>
      <b-dropdown-item to="/account/wishlist" class="small-padding-y">
        <fa :icon="['fas', 'heart']" size="1x" />
        Wish List 
      </b-dropdown-item>
      <b-dropdown-item class="small-padding-y" @click="logOut">
        <fa :icon="['fas', 'lock']" size="1x" />
        Log out
      </b-dropdown-item>
    </span>
    <span v-show="!loggedIn">
      <b-dropdown-item class="small-padding-y" @click="showSignUp()">
        <fa :icon="['fas', 'pencil-alt']" size="1x" class="p-0" />
        Sign up
      </b-dropdown-item>
      <b-dropdown-item class="small-padding-y" @click="showLogin()">
        <fa :icon="['fas', 'key']" size="1x" class="p-0" />
        Log in
      </b-dropdown-item>
    </span>    
  </b-nav-item-dropdown>
</template>

<script>
export default {
  computed: {
    icons() {
      return this.loggedIn ? ['fas', 'lock-open'] : ['fas', 'lock']
    }
  }
}
</script>
