<template>
  <div>
    <contact-form ref="contact-form" :action="null" :valid-data-action="'account/isValidData'" />
    <b-form-row>
      <b-col lg="6">
        <!-- PASSWORD -->
        <b-form-group
          id="fieldset2"
        >
          <slot name="label">
            <div class="mb-1">
              <span class="se-pink">
                *
              </span>
              Password:
            </div>
          </slot>

          <b-form-input
            id="login__password-input"
            ref="password"
            v-model="password"
            v-validate="{ required: true }"
            type="password"
            name="password"
            aria-describedby="login__password-feedback"
            placeholder="Your password"
            @input="isValid"
          />
          <div v-if="veeErrors.first('password')" class="invalid-feedback" style="display: block;">
            {{ veeErrors.first('password') }}
          </div>      
        </b-form-group>
      </b-col>
      <!-- PASSWORD CONFIRM -->
      <b-col lg="6">
        <b-form-group
          id="fieldset3"
        >
          <slot name="label">
            <div class="mb-1">
              <span class="se-pink">
                *
              </span>
              Password Confirmation:
            </div>
          </slot>
          <b-form-input
            id="login__email-login__password-confirm"
            v-model="passwordConfirmation"
            v-validate="'required|confirmed:password'"
            data-vv-as="Password Confirmation"
            name="password-confirm"
            type="password"
            placeholder="Confirm password"
            @input="isValid"
          />

          <div v-if="veeErrors.first('password-confirm')" class="invalid-feedback" style="display: block;">
            {{ veeErrors.first('password-confirm') }}
          </div>
        </b-form-group>
      </b-col>
      <b-col>
        <div v-if="loginError" class="invalid-feedback mt-3" style="display: block;">
          <span v-if="loginError.error === 'invalid_grant'">
            Email or Password is incorrect
          </span>
          <span v-else>
            <p>
              {{ loginError.message }}
            </p>
          </span>
        </div>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <p class="mt-2">
          Already have an account? 
          <b-link @click="showLogin">
            Log in
          </b-link>
        </p>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import validateState from './../mixins/validateState'
// import { titleCase } from './../utils/format'
import ContactForm from '~/components/ContactForm'

export default {
  components: {
    'contact-form': ContactForm
  },
  mixins: [validateState],
  data() {
    return {
      password: null,
      passwordConfirmation: '',
      error: null
    }
  },
  computed: {
    formValid() {
      return this.veeErrors && this.veeErrors.items.length === 0
    },
    loginError() {
      return this.$store.state.error.loginError
    },
    payload() {
      return {
        email: this.$refs['contact-form'].email,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
        first_name: this.$refs['contact-form'].firstname,
        last_name: this.$refs['contact-form'].lastname,
        phone: this.$refs['contact-form'].phone,
        company: this.$refs['contact-form'].company
      }
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      this.signUpForm ? this.signup() : this.login()
    },
    async signup() {
      try {
        this.$store.commit('error/SET_LOGIN_ERROR', null)
        const valid = await this.isValid()
        const userFieldsValid = await this.$refs['contact-form'].isValid()
        if (!valid || !userFieldsValid) {
          return
        }
        await this.$store.dispatch('account/register', this.payload)
        if (!this.loginError) {
          this.$emit('account-created')
        }
      } catch (e) {
        console.log('auth error', e)
        this.error = e.response.data.message
      }
    }
  }
}
</script>
