<template>
  <div>
    <b-form @submit.prevent="validateBeforeSubmit" @reset="onReset">
      <contact-fields ref="contactFields" />
      <b-form-row>
        <b-col cols="sm-6">
          <b-form-group
            id="input-group-1"
            label="Suburb:"
            label-for="stockist-suburb"
          >
            <b-form-input
              id="stockist-suburb"
              v-model="suburb"
              v-validate="{ required: true, min: 3 }"
              :state="validateState('suburb')"
              name="suburb"
              aria-describedby="suburb-feedback"
              size="md"
              type="text"
              required
              placeholder="SUBURB"
            />
            <b-form-invalid-feedback id="suburb-feedback">
              Suburb required (min. 3 characters)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="sm-6">
          <b-form-group
            id="input-group-postcode"
            label="Postcode:"
            label-for="stockist-postcode"
          >
            <b-form-input
              id="stockist-postcode"
              v-model="postcode"
              v-validate="{ required: true, min: 4 }"
              :state="validateState('postcode')"
              name="postcode"
              aria-describedby="postcode-feedback"
              size="md"
              type="text"
              required
              placeholder="POSTCODE"
            />
            <b-form-invalid-feedback id="postcode-feedback">
              Postcode required (min. 4 characters)
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-group
        id="input-group-1"
        label="What kind of shop/store do you own?:"
        label-for="stockist-store"
      >
        <b-form-input
          id="stockist-store"
          v-model="store"
          v-validate="{ required: true, min: 3 }"
          :state="validateState('store')"
          name="store"
          aria-describedby="store-feedback"
          size="md"
          type="text"
          required
          placeholder="STORE TYPE"
        />
        <b-form-invalid-feedback id="store-feedback">
          Store type required (min. 3 characters)
        </b-form-invalid-feedback>
      </b-form-group>         
      <b-form-group
        id="about-group"
        label="Tell us a little about your business:"
        label-for="about-text"
      >
        <b-form-textarea
          id="about-text"
          v-model="about"
          v-validate="{ required: true, min: 3, max: 550 }"
          :state="validateState('about')"
          name="about"
          aria-describedby="about-feedback"
          placeholder="ABOUT YOUR BUSINESS"
          rows="8"
          max-rows="12"
        />
        <b-form-invalid-feedback id="about-feedback">
          Region required (min. 3 characters, max. 550 characters)
        </b-form-invalid-feedback>
      </b-form-group>
      <no-ssr>
        <recaptcha
          @error="onRecaptchaError"
          @success="onRecaptchaSuccess"
          @expired="onRecaptchaExpired"
        />
      </no-ssr>
      <b-button type="submit" variant="primary">
        Submit
      </b-button>
      <b-button type="reset" variant="outline-dark">
        Reset
      </b-button>
    </b-form>
  </div>
</template>

<script>
import ContactFields from '~/components/ContactFields'
import validateState from './../../mixins/validateState'
export default {
  components: {
    'contact-fields': ContactFields
  },
  mixins: [validateState],
  data() {
    return {
      suburb: '',
      postcode: '',
      store: '',
      about: ''
    }
  },
  computed: {
    formData() {
      return {
        msgType: 'stockist_enquiry',
        formData: {
          ...{
            suburb: this.suburb,
            postcode: this.postcode,
            store_type: this.store,
            about: this.about
          },
          ...this.$refs.contactFields.contactData
        }
      }
    }
  },
  methods: {
    async validData() {
      const thisIsValid = await this.isValid()
      const validContact = await this.$refs.contactFields.isValid()
      return thisIsValid && validContact
    },
    async validateBeforeSubmit() {
      const canSubmit = await this.validData()
      if (!canSubmit) {
        return
      }
      try {
        await this.$recaptcha.getResponse()
        await this.$recaptcha.reset()
        await this.$store.dispatch('submitContactForm', this.formData)
        this.onReset()
        this.$emit('stockists-form-submitted')
      } catch (error) {
        console.log('Error:', error)
      }
    },
    onReset() {
      this.$refs.contactFields.reset()
      this.suburb = ''
      this.postcode = ''
      this.store = ''
      this.resetState()
      this.$refs.contactFields.resetState()
    },
    onRecaptchaSuccess(token) {
      console.log('Succeeded')
    },
    onRecaptchaExpired() {
      console.log('Expired')
    },
    onRecaptchaError(error) {
      console.log('Error happened:', error)
    }
  }
}
</script>
