import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cbd4fa14 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _727dfbe4 = () => interopDefault(import('../pages/bag.vue' /* webpackChunkName: "pages/bag" */))
const _1cb34a30 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _c470ac24 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4d5748a0 = () => interopDefault(import('../pages/new-in-store.vue' /* webpackChunkName: "pages/new-in-store" */))
const _37e8fae4 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _34ed5763 = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _0406ba31 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _b500732a = () => interopDefault(import('../pages/site-terms.vue' /* webpackChunkName: "pages/site-terms" */))
const _4cd89e8a = () => interopDefault(import('../pages/tell-me-more/index.vue' /* webpackChunkName: "pages/tell-me-more/index" */))
const _408bc2be = () => interopDefault(import('../pages/temp.vue' /* webpackChunkName: "pages/temp" */))
const _5f61b2f0 = () => interopDefault(import('../pages/account/create.vue' /* webpackChunkName: "pages/account/create" */))
const _f6a5238c = () => interopDefault(import('../pages/account/info.vue' /* webpackChunkName: "pages/account/info" */))
const _17cc4c98 = () => interopDefault(import('../pages/account/orders/index.vue' /* webpackChunkName: "pages/account/orders/index" */))
const _035228b2 = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _16b8f736 = () => interopDefault(import('../pages/account/reset-password.vue' /* webpackChunkName: "pages/account/reset-password" */))
const _6a297c5e = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _3bd57d3c = () => interopDefault(import('../pages/help/contact.vue' /* webpackChunkName: "pages/help/contact" */))
const _62cf5b32 = () => interopDefault(import('../pages/help/faq.vue' /* webpackChunkName: "pages/help/faq" */))
const _4e89057e = () => interopDefault(import('../pages/help/payments.vue' /* webpackChunkName: "pages/help/payments" */))
const _44e1b97c = () => interopDefault(import('../pages/help/shipping.vue' /* webpackChunkName: "pages/help/shipping" */))
const _6fb14c1f = () => interopDefault(import('../pages/shop/dangle-earrings/index.vue' /* webpackChunkName: "pages/shop/dangle-earrings/index" */))
const _792ca864 = () => interopDefault(import('../pages/shop/other/index.vue' /* webpackChunkName: "pages/shop/other/index" */))
const _29287a04 = () => interopDefault(import('../pages/shop/stud-earrings/index.vue' /* webpackChunkName: "pages/shop/stud-earrings/index" */))
const _1fe8099d = () => interopDefault(import('../pages/tell-me-more/our-story.vue' /* webpackChunkName: "pages/tell-me-more/our-story" */))
const _70ee4be5 = () => interopDefault(import('../pages/tell-me-more/our-team.vue' /* webpackChunkName: "pages/tell-me-more/our-team" */))
const _58efee28 = () => interopDefault(import('../pages/tell-me-more/stockists.vue' /* webpackChunkName: "pages/tell-me-more/stockists" */))
const _500a8dc8 = () => interopDefault(import('../pages/account/orders/_id.vue' /* webpackChunkName: "pages/account/orders/_id" */))
const _220917cc = () => interopDefault(import('../pages/shop/dangle-earrings/_slug/index.vue' /* webpackChunkName: "pages/shop/dangle-earrings/_slug/index" */))
const _a0e06ec2 = () => interopDefault(import('../pages/shop/other/_slug/index.vue' /* webpackChunkName: "pages/shop/other/_slug/index" */))
const _6b1307b9 = () => interopDefault(import('../pages/shop/stud-earrings/_slug/index.vue' /* webpackChunkName: "pages/shop/stud-earrings/_slug/index" */))
const _6ad3f2fa = () => interopDefault(import('../pages/orders/_id.vue' /* webpackChunkName: "pages/orders/_id" */))
const _6e2a0744 = () => interopDefault(import('../pages/shop/_slug/index.vue' /* webpackChunkName: "pages/shop/_slug/index" */))
const _12055b6a = () => interopDefault(import('../pages/shop/_slug/reviews/index.vue' /* webpackChunkName: "pages/shop/_slug/reviews/index" */))
const _0f273052 = () => interopDefault(import('../pages/shop/_slug/reviews/_id.vue' /* webpackChunkName: "pages/shop/_slug/reviews/_id" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _cbd4fa14,
    name: "account"
  }, {
    path: "/bag",
    component: _727dfbe4,
    name: "bag"
  }, {
    path: "/checkout",
    component: _1cb34a30,
    name: "checkout"
  }, {
    path: "/help",
    component: _c470ac24,
    name: "help"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/new-in-store",
    component: _4d5748a0,
    name: "new-in-store"
  }, {
    path: "/privacy",
    component: _37e8fae4,
    name: "privacy"
  }, {
    path: "/shop",
    component: _34ed5763,
    name: "shop"
  }, {
    path: "/sign-up",
    component: _0406ba31,
    name: "sign-up"
  }, {
    path: "/site-terms",
    component: _b500732a,
    name: "site-terms"
  }, {
    path: "/tell-me-more",
    component: _4cd89e8a,
    name: "tell-me-more"
  }, {
    path: "/temp",
    component: _408bc2be,
    name: "temp"
  }, {
    path: "/account/create",
    component: _5f61b2f0,
    name: "account-create"
  }, {
    path: "/account/info",
    component: _f6a5238c,
    name: "account-info"
  }, {
    path: "/account/orders",
    component: _17cc4c98,
    name: "account-orders"
  }, {
    path: "/account/password",
    component: _035228b2,
    name: "account-password"
  }, {
    path: "/account/reset-password",
    component: _16b8f736,
    name: "account-reset-password"
  }, {
    path: "/account/wishlist",
    component: _6a297c5e,
    name: "account-wishlist"
  }, {
    path: "/help/contact",
    component: _3bd57d3c,
    name: "help-contact"
  }, {
    path: "/help/faq",
    component: _62cf5b32,
    name: "help-faq"
  }, {
    path: "/help/payments",
    component: _4e89057e,
    name: "help-payments"
  }, {
    path: "/help/shipping",
    component: _44e1b97c,
    name: "help-shipping"
  }, {
    path: "/shop/dangle-earrings",
    component: _6fb14c1f,
    name: "shop-dangle-earrings"
  }, {
    path: "/shop/other",
    component: _792ca864,
    name: "shop-other"
  }, {
    path: "/shop/stud-earrings",
    component: _29287a04,
    name: "shop-stud-earrings"
  }, {
    path: "/tell-me-more/our-story",
    component: _1fe8099d,
    name: "tell-me-more-our-story"
  }, {
    path: "/tell-me-more/our-team",
    component: _70ee4be5,
    name: "tell-me-more-our-team"
  }, {
    path: "/tell-me-more/stockists",
    component: _58efee28,
    name: "tell-me-more-stockists"
  }, {
    path: "/account/orders/:id",
    component: _500a8dc8,
    name: "account-orders-id"
  }, {
    path: "/shop/dangle-earrings/:slug",
    component: _220917cc,
    name: "shop-dangle-earrings-slug"
  }, {
    path: "/shop/other/:slug",
    component: _a0e06ec2,
    name: "shop-other-slug"
  }, {
    path: "/shop/stud-earrings/:slug",
    component: _6b1307b9,
    name: "shop-stud-earrings-slug"
  }, {
    path: "/orders/:id?",
    component: _6ad3f2fa,
    name: "orders-id"
  }, {
    path: "/shop/:slug",
    component: _6e2a0744,
    name: "shop-slug"
  }, {
    path: "/shop/:slug/reviews",
    component: _12055b6a,
    name: "shop-slug-reviews"
  }, {
    path: "/shop/:slug/reviews/:id",
    component: _0f273052,
    name: "shop-slug-reviews-id"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
