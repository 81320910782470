import ApiError from './error'

export default $axios => ({
  async express(orderToken, paymentMethodId, base) {
    const opts = {
      headers: {
        'X-Spree-Order-Token': orderToken
      }
    }
    // const base = process.env.baseURL
    try {
      const res = await $axios.post(
        `api/paypal`,
        {
          payment_method_id: paymentMethodId,
          confirm_url: `${base}/checkout?paypal=true&paymentMethodId=${paymentMethodId}`,
          cancel_url: `${base}/checkout?paypal=true&cancel=true`
        },
        opts
      )
      return res
    } catch (error) {
      console.error('express', error.response)
      return { error: ApiError(error), data: null }
    }
  }
})
