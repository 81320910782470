// import normalize from 'json-api-normalizer'
import constants from './../utils/constants'
import ApiError from './error'

export default $axios => ({
  // get a product {products/:id}
  async get(id, params) {
    if (params == null) {
      params =
        '?include=default_variant,images,taxons,variants,option_types,variants.option_values'
    }
    try {
      const { data } = await $axios.get(
        `api/products/${id}${sanitizeUrl(params)}`
      )
      return data
    } catch (err) {
      return { error: ApiError(err), data: null }
    }
  },
  // Get the sugested products
  async haveYouSeen(params) {
    // TODO: set customised filters for the user
    // or no user return the three latest in each
    // category
    // may need a custom route in backend
    if (params == null) {
      params = `?sort=-updated_at&include=images,default_variant&per_page=20&product_count=20`
    }
    try {
      const { data } = await $axios.get(
        `api/products/haveyouseen${sanitizeUrl(params)}`
      )
      return { data }
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async byName(name) {
    try {
      const params = `?filter[name]=${name}`
      const { data } = await $axios.get(`api/products${sanitizeUrl(params)}`)
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async search(options) {
    const defaultOptions = {
      includes: constants.productInclude,
      page: 1,
      perPage: constants.productPerPage,
      sort: 'best_match'
    }
    const paramsOptions = { ...defaultOptions, ...options }
    const { sort, includes, perPage, page } = paramsOptions
    const filter = paramsOptions.filter ? firstParam(paramsOptions.filter) : '?'
    const params = `${filter}sort=${sort}&include=${includes}&per_page=${perPage}&page=${page}`
    try {
      const { data } = await $axios.get(
        `api/products/search${sanitizeUrl(params)}`
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  // get products
  async index(options) {
    // async index(params, page, numberRes) {
    const defaultOptions = {
      includes: constants.productInclude,
      page: 1,
      perPage: constants.productPerPage,
      sort: '-created_at',
      seed: 0
    }
    const paramsOptions = { ...defaultOptions, ...options }
    const { sort, includes, perPage, page, seed } = paramsOptions
    const filter = paramsOptions.filter ? firstParam(paramsOptions.filter) : '?'
    const params = `${filter}sort=${sort}&include=${includes}&per_page=${perPage}&page=${page}&seed=${seed}`
    try {
      const { data } = await $axios.get(`api/products${sanitizeUrl(params)}`)
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  }
})

const sanitizeUrl = url => url.split('#').join('%23')

const firstParam = param => {
  let first = param
  if (param.charAt(0) === '&') first = param.substring(1)
  if (param.charAt(0) !== '?') first = `?${param}`
  return `${first}&`
}
