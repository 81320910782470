export const getRandom = (arr, n) => {
  const result = new Array(n)
  let len = arr.length
  const taken = new Array(len)
  if (n > len) return arr
  while (n--) {
    const x = Math.floor(Math.random() * len)
    result[n] = arr[x in taken ? taken[x] : x]
    taken[x] = --len in taken ? taken[len] : len
  }
  return result
}

export const sortNameAlpha = arr => {
  return arr.sort((a, b) => {
    const textA = a.attributes.name.toUpperCase()
    const textB = b.attributes.name.toUpperCase()
    return textA < textB ? -1 : textA > textB ? 1 : 0
  })
}
