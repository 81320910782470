export default $axios => ({
  async show(iso) {
    try {
      const { data } = await $axios.get(`api/countries/${iso}?include=states`)
      return data
    } catch (error) {
      return error
    }
  }
})
