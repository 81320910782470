<template>
  <div>
    <!-- EMAIL -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="ig-email"
          label-for="input-email"
        >
          <slot name="label">
            <div class="mb-1">
              <span class="se-pink">
                *
              </span>
              Email address:
            </div>
          </slot>
          <b-form-input
            id="input-email"
            ref="email"
            v-model="email"
            v-validate="'required|email'"
            name="email"
            :state="validateState('email')"
            aria-describedby="contact-email-fb"
            type="email"
            required
            placeholder="enter email"
            @input="updateContact"
          />
          <b-form-invalid-feedback id="contact-email-fb">
            Invalid email
          </b-form-invalid-feedback> 
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          id="ig-email-confirm"
          label-for="input-email-confirm"
        >
          <slot name="label">
            <div class="mb-1">
              <span class="se-pink">
                *
              </span>
              Confirm email:
            </div>
          </slot>
          <b-form-input
            id="input-email-confirm"
            v-model="emailConfirm"
            v-validate="'confirmed:email|required'"
            name="input-email-confirm"
            :state="validateState('input-email-confirm')"
            aria-describedby="contact-email-comfirm-fb"
            type="email"
            required
            placeholder="confirm email"
            @input="updateContact"
          />
          <b-form-invalid-feedback id="contact-email-comfirm-fb">
            Emails must match
          </b-form-invalid-feedback> 
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- NAMES -->
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="ig-firstname"
          label-for="contact-firstname"
        >
          <slot name="label">
            <div class="mb-1">
              <span class="se-pink">
                *
              </span>
              First Name:
            </div>
          </slot>
          <b-form-input
            id="contact-firstname"
            v-model="firstname"
            v-validate="{ required: true, min: 3 }"
            :state="validateState('contact-firstname')"
            name="contact-firstname"
            aria-describedby="contact-firstname-fb"
            required
            placeholder="first name"
            @input="updateContact"
          />
          <b-form-invalid-feedback id="contact-firstname-fb">
            First name required (min. 3 characters)
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group id="ig-lastname" label-for="contact-lastname">
          <slot name="label">
            <div class="mb-1">
              <span class="se-pink">
                *
              </span>
              Last Name:
            </div>
          </slot>
          <b-form-input
            id="contact-lastname"
            v-model="lastname"
            v-validate="{ required: true, min: 3 }"
            :state="validateState('contact-lastname')"
            name="contact-lastname"
            aria-describedby="contact-lastname-fb"
            required
            placeholder="last name"
            @input="updateContact"
          />
          <b-form-invalid-feedback id="contact-lastname-fb">
            Last name required (min. 3 characters)
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col lg="6">
        <b-form-group
          id="ig-phone"
          label-for="input-phone"
        >
          <slot name="label">
            <div class="mb-1">
              <span class="se-pink">
                *
              </span>
              Phone number:
            </div>
          </slot>
          <b-form-input
            id="contact-phone"
            v-model="phone"
            v-validate="{ required: true, regex: /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/ }"
            :state="validateState('contact-phone')"
            name="contact-phone"
            aria-describedby="contact-phone-fb"
            type="tel"
            required
            placeholder="phone number"
            @input="updateContact"
          />
          <b-form-invalid-feedback id="contact-phone-fb">
            Invalid phone number
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          id="ig-company"
          label-for="input-company"
        >
          <slot name="label">
            <div class="mb-1">
              Company:
            </div>
          </slot>
          <b-form-input
            id="input-company"
            v-model="company"
            type="text"
            placeholder="company"
            @input="updateContact"
          />
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import validateState from './../mixins/validateState'
export default {
  mixins: [validateState],
  props: {
    action: {
      type: String,
      default: 'checkout/updateContactInfo'
    },
    validDataAction: {
      type: String,
      default: 'account/isValidData'
    }
  },
  data() {
    return {
      firstname: '',
      lastname: '',
      email: null,
      emailConfirm: null,
      company: null,
      address1: '',
      address2: '',
      city: '',
      zipCode: '',
      phone: '',
      state_name: '',
      country_name: '',
      country_iso3: '',
      country_iso: '',
      state_code: ''
    }
  },
  computed: {
    contactInfo() {
      return {
        email: this.email,
        contact: {
          firstname: this.firstname,
          lastname: this.lastname,
          company: this.company,
          phone: this.phone
        }
      }
    }
  },
  watch: {
    loggedIn(newVal, oldVal) {
      this.initData()
    }
  },
  beforeMount() {
    this.initData()
  },
  methods: {
    async updateContact() {
      const obj = {
        email: this.email,
        lastname: this.lastname,
        firstname: this.firstname,
        company: this.company,
        phone: this.phone
      }
      if (this.action) {
        await this.$store.dispatch(this.action, obj)
      }
      if (this.validDataAction) {
        const isValid = await this.isValid()
        await this.$store.dispatch(this.validDataAction, isValid)
      }
    },
    resetForm() {
      this.firstnemailme = ''
      this.emailConfirm = ''
      this.firstname = ''
      this.lastname = ''
      this.phone = ''
      this.company = ''
      this.resetState()
    },
    initData() {
      if (this.$auth.loggedIn) {
        this.emailConfirm = this.$auth.user.data.attributes.email
        this.email = this.$auth.user.data.attributes.email
        const address =
          this.$store.state.account.billingAddress ||
          this.$store.state.account.shippingAddress ||
          this.$store.state.account.businessAddress
        if (address) {
          this.firstname = address.attributes.firstname
          this.lastname = address.attributes.lastname
          this.phone = address.attributes.phone
          this.company = address.attributes.company
        }
        const user = this.$auth.user
        if (user) {
          this.firstname = user.data.attributes.first_name || this.firstname
          this.lastname = user.data.attributes.last_name || this.lastname
          this.phone = user.data.attributes.phone || this.phone
          this.company = user.data.attributes.company || this.company
        }
        this.updateContact()
      } else if (this.$store.getters['checkout/contactInfo']) {
        const checkoutContact = this.$store.getters['checkout/contactInfo']
        this.firstname = checkoutContact.firstname
        this.lastname = checkoutContact.lastname
        this.email = checkoutContact.email
        this.emailConfirm = checkoutContact.email
        this.company = checkoutContact.company
        this.phone = checkoutContact.phone
      } else {
        this.resetForm()
      }
    }
  }
}
</script>
