<template>
  <footer class="footer">
    <div class="container">
      <div class="d-block d-sm-none">
        <b-row>
          <dl role="tablist" class="footer__accordion">
            <dt v-for="(value, key) in categories" :key="'footer-nav__' + key" class="text-center">
              <b-button
                v-b-toggle="'accordion-' + key"
              >
                {{ value }}
                <span class="when-opened">
                  <p :ref="'footer__chevron-' + key" class="m-0">
                    <fa :icon="['fas', 'chevron-up']" size="1x" class="p-0" />
                  </p>
                </span>
                <span class="when-closed">
                  <p :ref="'footer__chevron-' + key" class="m-0">
                    <fa :icon="['fas', 'chevron-down']" size="1x" class="p-0" />
                  </p>
                </span>
              </b-button>
              <!-- <p v-for="link in linksByCategory[key]" :key="'footer-nav__link-' + link">
                {{ link.label }}
              </p> -->
              <b-collapse :id="'accordion-' + key" role="tabpanel">
                <b-nav vertical>
                  <b-nav-item
                    v-for="link in linksByCategory[key]"
                    :key="'footer-nav__link-' + link.label"
                    :to="link.route"
                  >
                    {{ link.label }}
                  </b-nav-item>
                </b-nav>
              </b-collapse>
            </dt>
          </dl>
        </b-row>
      </div>
      <div class="d-none d-sm-block">
        <b-row>
          <b-col v-for="(value, key) in categories" :key="'footer-nav__' + key">
            <p>{{ value }}</p>
            <!-- <p v-for="link in linksByCategory[key]" :key="'footer-nav__link-' + link">
              {{ link.label }}
            </p> -->
            <b-nav vertical>
              <b-nav-item
                v-for="link in linksByCategory[key]"
                :key="'footer-nav__link-' + link.label"
                :to="link.route"
              >
                {{ link.label }}
              </b-nav-item>
            </b-nav>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
      </div>
      <b-row class="footer-copyright">
        <div id="footer-copyright__text" class="flex-grow-1">
          <span>
            Copyright 
            <fa :icon="['far', 'copyright']" size="1x" style="width: 0.8rem;" />
            <span class="pr-2">Stomping Elephants Pty Ltd 2020. All Rights Reserved</span>
            <span class="text-center">|</span>
            <b-link to="/site-terms">
              Site Terms
            </b-link>
            <span class="text-center">|</span>
            <b-link to="/privacy">
              Privacy
            </b-link>
          </span>
          <p class="small">
            *Free shipping offer excludes Bon Maxi earring boards and e-Vouchers
          </p>
        </div>
        <div id="footer-copyright__logos">
          <div class="">
            <span>
              <img src="~/assets/img/handmade-in-australia.png" alt="Handmade in Australia" class="footer__handmade img-fluid">
              <b-link href="https://www.facebook.com/stompingelephantsmackay" target="_blank">
                <fa :icon="['fab', 'facebook-square']" size="2x" />
              </b-link>
              <b-link href="https://www.instagram.com/stompingelephants/" target="_blank">
                <fa :icon="['fab', 'instagram']" style="font-size: 2.08rem" />
              </b-link>
            </span>
          </div>
        </div>
      </b-row>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      products: 'sitenav/productsLinks',
      about: 'sitenav/aboutLinks',
      help: 'sitenav/helpLinks',
      allLinks: 'sitenav/allLinks',
      categories: 'sitenav/categories',
      linksByCategory: 'sitenav/linksByCategory'
    })
  }
}
</script>
