<template>
  <span class="dropdown-caret">
    <span v-if="open">
      <fa :icon="['fas', 'chevron-up']" size="1x" />
    </span>
    <span v-else>
      <fa :icon="['fas', 'chevron-down']" size="1x" />
    </span>
  </span>
</template>
<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss">
.dropdown-caret {
  font-size: 0.9rem;
}
</style>
