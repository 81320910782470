import Vue from 'vue'
Vue.mixin({
  computed: {
    loggedIn() {
      return this.$auth.loggedIn
    }
  },
  methods: {
    showLogin() {
      this.hideCollapse()
      this.$store.commit('HIDE_SIGNUP')
      this.$store.commit('SHOW_MODAL', 'sign-in-modal')
    },
    showSignUp() {
      this.hideCollapse()
      this.$store.commit('HIDE_LOGIN')
      this.$store.commit('SHOW_MODAL', 'sign-up-modal')
    },
    async logOut() {
      this.hideCollapse()
      await this.$auth.logout()
      // this.$store.dispatch('cart/resetCart')
    },
    hideCollapse() {
      this.$store.commit('HIDE_NAV_SMALL')
    }
  }
})
