const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['filterState'] = require('../middleware/filterState.js')
middleware['filterState'] = middleware['filterState'].default || middleware['filterState']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['reset-state'] = require('../middleware/reset-state.js')
middleware['reset-state'] = middleware['reset-state'].default || middleware['reset-state']

middleware['storeRedirect'] = require('../middleware/storeRedirect.js')
middleware['storeRedirect'] = middleware['storeRedirect'].default || middleware['storeRedirect']

middleware['userCart'] = require('../middleware/userCart.js')
middleware['userCart'] = middleware['userCart'].default || middleware['userCart']

export default middleware
