import normalize from 'json-api-normalizer'
import isDefined from '../utils/isDefined'
import { getParam } from './../utils/urlHelpers'

export const state = () => ({
  shippingAddress: null,
  billingAddress: null,
  businessAddress: null,
  favorites: [],
  orders: [],
  lastUser: '',
  loggedIn: false,
  validUpdateData: false,
  passwordReset: false,
  page: 1,
  currentPage: 1,
  pageCount: 1,
  count: 0,
  totalCount: 0
})

export const mutations = {
  SET_USER(state, user) {
    state.user = user
  },
  SET_ADDRESSES(state, data) {
    state.addresses = data
  },
  SET_BILLING_ADDRESS(state) {
    if (state.user) {
      const userNormed = normalize(state.user)
      const hasRel =
        state.user.data.relationships &&
        state.user.data.relationships.default_billing_address &&
        state.user.data.relationships.default_billing_address.data
      if (hasRel) {
        const billAddId =
          state.user.data.relationships.default_billing_address.data.id
        state.billingAddress = userNormed.address[billAddId]
      }
    }
  },
  SET_SHIPPING_ADDRESS(state) {
    if (state.user) {
      const userNormed = normalize(state.user)
      const hasRel =
        state.user.data.relationships &&
        state.user.data.relationships.default_shipping_address &&
        state.user.data.relationships.default_shipping_address.data
      if (hasRel) {
        const shipAddId =
          state.user.data.relationships.default_shipping_address.data.id
        state.shippingAddress = userNormed.address[shipAddId]
      }
    }
  },
  SET_BUSINESS_ADDRESS(state) {
    if (state.user) {
      const busAddresses = state.user.included.filter(i => {
        return i.type === 'business_address'
      })
      state.businessAddress = busAddresses.length > 0 ? busAddresses[0] : null
    }
  },
  SET_USER_FAVORITES(state, user) {
    if (user && user.included) {
      const favorites = {}
      user.included.forEach(f => {
        if (
          f.type === 'favorite' &&
          f.attributes.favoritable_type === 'Spree::Product'
        ) {
          favorites[f.attributes.favoritable_id] = f
        }
      })
      state.favorites = favorites
    } else {
      state.favorites = {}
    }
  },
  TOGGLE_FAVORITE(state, fav) {
    const exists =
      state.favorites[fav.attributes.favoritable_id] !== null &&
      state.favorites[fav.attributes.favoritable_id] !== undefined
    if (exists) {
      const favs = { ...state.favorites }
      delete favs[fav.attributes.favoritable_id]
      state.favorites = favs
    } else {
      state.favorites = {
        ...state.favorites,
        ...{ [fav.attributes.favoritable_id]: fav }
      }
    }
  },
  CLEAR_PROFILE_DATA(state) {
    state.shippingAddress = null
    state.billingAddress = null
    state.favorites = {}
    state.orders = []
    state.favorites = []
  },
  SET_LAST_USER(state, lastUser) {
    state.lastUser = lastUser
  },
  SET_ORDERS(state, orders) {
    state.orders = orders
  },
  SET_VALID_UPDATE_DATA(state, valid) {
    state.validUpdateData = valid
  },
  SET_RESET_PASSWORD(state, hasBeenReset) {
    state.passwordReset = hasBeenReset
  },
  SET_PAGES(state, data) {
    if (data.links) {
      const page = getParam(data.links.self, 'page')
      state.currentPage = parseInt(page) || 1
      state.pageCount = data.meta.total_pages
      state.count = data.meta.count
      state.totalCount = data.meta.total_count
    }
  },
  SET_CURRENT_PAGE(state, page) {
    state.currentPage = page
  }
}

export const getters = {
  useShippingAddress(state) {
    if (state.shippingAddress && state.billingAddress) {
      const shipAtts = JSON.stringify(state.shippingAddress.attributes)
      const billAtts = JSON.stringify(state.billingAddress.attributes)
      return shipAtts === billAtts
    }
    return true
  },
  favoriteProducts(state) {
    if (state.favorites) {
      return Object.keys(state.favorites)
      // return state.favorites.map(f => {
      //   if (
      //     f.type === 'favorite' &&
      //     f.attributes.favoritable_type === 'Spree::Product'
      //   ) {
      //     return f.attributes.favoritable_id
      //   }
      // })
    }
    return []
  },
  isWholesaler(state) {
    return state.user && state.user.data.attributes.wholesaler
  }
}

export const actions = {
  // This was before the auth changes on the main site
  // async getAccount({ commit }) {
  //   const res = await this.$api.user.account()
  //   if (res.error) {
  getAccount({ commit }) {
    // const bearer = this.$cookies.get('auth._token.local')
    // const res = await this.$api.user.account(bearer)
    if (!this.$auth.user) {
      commit('CLEAR_PROFILE_DATA')
    } else {
      commit('SET_USER', this.$auth.user)
      commit('SET_SHIPPING_ADDRESS')
      commit('SET_BUSINESS_ADDRESS')
      commit('SET_BILLING_ADDRESS')
      commit('SET_USER_FAVORITES', this.$auth.user)
    }
  },
  async refreshToken({ commit }) {
    try {
      commit('error/SET_LOGIN_ERROR', null, { root: true })
      await this.$auth.refreshTokens()
    } catch (error) {
      if (error.response) {
        commit('error/SET_LOGIN_ERROR', error.response.data, { root: true })
      } else {
        commit('error/SET_LOGIN_ERROR', error.data, { root: true })
      }
    }
  },
  async register({ commit }, payload) {
    commit('error/SET_LOGIN_ERROR', null, { root: true })
    commit('SET_LOADING', true, { root: true })
    const { data, error } = await this.$api.user.createUser(payload)
    if (error) {
      commit('error/SET_LOGIN_ERROR', error, { root: true })
      commit('SET_LOADING', false, { root: true })
    } else {
      if (payload.email && data.attributes.email === payload.email) {
        await this.$auth.loginWith('local', {
          data: {
            grant_type: 'password',
            username: payload.email,
            password: payload.password
          }
        })
      }
      commit('SET_SHIPPING_ADDRESS')
      commit('SET_BUSINESS_ADDRESS')
      commit('SET_BILLING_ADDRESS')
      commit('SET_LOADING', false, { root: true })
    }
  },
  async loginLocal({ commit, rootState, dispatch }, { email, password }) {
    try {
      commit('error/SET_LOGIN_ERROR', null, { root: true })
      commit('SET_LOADING', true, { root: true })
      if (isDefined(rootState.cart.token)) {
        this.$cookies.set('cart_token_old', rootState.cart.token)
      }
      const { data } = await this.$auth.loginWith('local', {
        data: {
          grant_type: 'password',
          username: email,
          password
        }
      })
      commit('SET_SHIPPING_ADDRESS')
      commit('SET_BILLING_ADDRESS')
      commit('SET_BUSINESS_ADDRESS')
      commit('SET_USER_FAVORITES', this.$auth.user)
      commit('SET_LOADING', false, { root: true })
      return { data, error: null }
    } catch (error) {
      commit('SET_LOADING', false, { root: true })
      if (error.response) {
        commit('error/SET_LOGIN_ERROR', error.response.data, { root: true })
      } else {
        commit('error/SET_LOGIN_ERROR', error.data, { root: true })
      }
      return { data: null, error }
    }
  },
  async addFavorite({ commit }, id) {
    const bearer = this.$cookies.get('auth._token.local')
    const { data, error } = await this.$api.favorites.create(id, bearer)
    if (error) {
      commit('error/SET_ERRORS', error, { root: true })
    } else {
      commit('TOGGLE_FAVORITE', data)
      await this.$auth.fetchUser()
    }
  },
  async deleteFavorite({ commit, state, getters }, id) {
    const bearer = this.$cookies.get('auth._token.local')
    const { data, error } = await this.$api.favorites.delete(id, bearer)
    if (error) {
      commit('error/SET_ERRORS', error, { root: true })
    } else {
      commit('TOGGLE_FAVORITE', data)
      await this.$auth.fetchUser()
    }
  },
  async clearFavorites({ dispatch }) {
    const bearer = this.$cookies.get('auth._token.local')
    const { error } = await this.$api.favorites.empty(bearer)
    if (!error) {
      await this.$auth.fetchUser()
      await dispatch('getAccount')
    }
  },
  async getOrders({ commit, state }) {
    const options = {
      page: state.currentPage
    }
    const res = await this.$api.user.orders(options)
    if (res.error) {
      commit('error/SET_ERRORS', res.error, { root: true })
    } else {
      commit('SET_ORDERS', res.data)
      commit('SET_PAGES', res)
    }
  },
  async updateInfo({ commit }, payload) {
    const res = await this.$api.user.update(payload)
    if (res.error) {
      commit('CLEAR_PROFILE_DATA')
    } else {
      setUser(commit, res, `Account Updated`)
    }
  },
  async changePassword({ commit }, payload) {
    const res = await this.$api.user.update(payload)
    if (res.error) {
      commit('CLEAR_PROFILE_DATA')
    } else {
      setUser(commit, res, `Password Changed`)
    }
  },
  async resetPassword({ commit, dispatch }, payload) {
    const { data, error } = await this.$api.user.resetPassword(payload)
    if (error) {
      commit('error/SET_ERRORS', error, { root: true })
    } else {
      commit('SET_RESET_PASSWORD', true)
      await dispatch('loginLocal', {
        email: data.attributes.email,
        password: payload.password
      })
    }
  },
  isValidData({ commit }, valid) {
    commit('SET_VALID_UPDATE_DATA', valid)
  }
}

const setUser = (commit, user, msg) => {
  commit('SET_USER', user)
  commit('SET_SHIPPING_ADDRESS')
  commit('SET_BUSINESS_ADDRESS')
  commit('SET_BILLING_ADDRESS')
  commit('SET_USER_FAVORITES', user)
  commit('SET_MESSAGE', msg, { root: true })
  commit('SHOW_MESSAGE', true, { root: true })
}
