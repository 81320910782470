import nuxtStorage from 'nuxt-storage'
import normalize from 'json-api-normalizer'
import isDefined from '../utils/isDefined'
import { getParam } from './../utils/urlHelpers'
import { sortNameAlpha } from './../utils/arrayUtils'
import { cleanCommas } from './../utils/format'

// import { lowerCase } from '~/utils/format'
// import { parseProducts } from './../utils/products'

export const state = () => ({
  perPage: 24,
  page: 1,
  sort: 'random',
  include: '',
  fields: '',
  filterColors: [],
  filterThemes: [],
  filterText: [],
  filterSize: [],
  filterCategory: [],
  haveYouSeen: null,
  products: {},
  sortedProducts: [],
  images: {},
  taxon: {},
  taxonomies: {},
  variants: {},
  optionTypes: {},
  taxonFilter: [],
  count: 0,
  totalCount: 0,
  pageCount: 0,
  currentPage: 1,
  fixedCategories: ['stud-earrings', 'dangle-earrings', 'new'],
  fixedTaxons: {},
  reviewSettings: {},
  reviewSubmitted: false,
  newReview: null,
  filterBarTags: [],
  productsUpdating: false,
  shopUpdated: false
})

export const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = products
  },
  SET_SORTED_PRODUCTS(state, sorted) {
    const a = new Set([...sorted])
    state.sortedProducts = [...a]
  },
  SET_IMAGES(state, images) {
    state.images = { ...state.images, ...images }
  },
  SET_HAVE_YOU_SEEN(state, data) {
    const normed = normalize(data)
    state.haveYouSeen = { ...state.haveYouSeen, ...normed.product }
  },
  SET_TAXONS(state, { taxons, taxonName }) {
    if (taxonName === 'categoryTaxons') {
      state.categoryTaxons = taxons
    }
    if (taxonName === 'themeTaxons') {
      state.themeTaxons = taxons
    }
    if (taxonName === 'materialTaxons') {
      state.materialTaxons = taxons
    }
    if (taxonName === 'sizeTaxons') {
      state.sizeTaxons = taxons
    }
    if (taxonName === 'colorTaxons') {
      state.colorTaxons = taxons
    }
    state.taxons = [...new Set([...state.taxons, ...taxons])]
  },
  SET_CATEGORY_TAXONS(state, taxons) {
    state.categoryTaxons = taxons
  },
  ADD_TAXON(state, taxon) {
    // state.taxons = { ...state.taxons, ...taxons }
    state.taxons = [...state.taxons, ...[taxon]]
  },
  SET_VARIANTS(state, variants) {
    state.variants = { ...state.variants, ...variants }
  },
  SET_PRODUCTPROPERTIES(state, properties) {
    state.productProperties = { ...state.productProperties, ...properties }
  },
  SET_OPTIONTYPES(state, options) {
    state.optionTypes = { ...state.optionTypes, ...options }
  },
  SET_FILTER_COLORS(state, colors) {
    state.filterColors = colors
  },
  SET_FILTER_SIZES(state, sizes) {
    state.filterSize = sizes
  },
  SET_FILTER_CATEGORIES(state, categories) {
    state.filterCategory = categories
  },
  SET_FILTER_THEMES(state, themes) {
    state.filterThemes = themes
  },
  SET_FILTER_TEXT(state, text) {
    if (Array.isArray(text)) {
      state.filterText = text
    } else {
      const trimmed = text.trim()
      if (trimmed.length === 0) {
        state.filterText = []
      } else {
        state.filterText = trimmed.split(' ')
        // state.filterText = [trimmed, ...trimmed.split(' ')]
        // state.filterText = state.filterText.unshift(trimmed)
      }
    }
  },
  SET_TAXON_FILTER(state, data) {
    state.taxonFilter = [data.data.id]
  },
  UPDATE_TAXON_FILTER(state, ids) {
    state.taxonFilter = [...state.taxonFilter, ...ids]
  },
  SET_PRODUCT_TYPE_FILTER(state, productType) {
    state.taxonFilter = productType
  },
  SET_PAGES(state, data) {
    if (data.links) {
      const page = getParam(data.links.self, 'page')
      state.currentPage = parseInt(page) || 1
      state.pageCount = data.meta.total_pages
      state.count = data.meta.count
      state.totalCount = data.meta.total_count
    }
  },
  SET_CURRENT_PAGE(state, page) {
    state.currentPage = parseInt(page) || 1
  },
  RESET_FILTERS(state) {
    state.filterText = []
    state.filterSize = []
    state.filterColors = []
    state.filterCategory = []
    state.filterThemes = []
    state.filterBarTags = []
  },
  SET_FIXED_TAXONS(state, { taxons, category }) {
    const link = baseLink(category)
    const catTax = getState(state, `taxonomies.categories.taxons.${link}`)
    if (catTax !== null) {
      catTax.fixedTaxons = taxons
    }
    state.fixedTaxons[category] = taxons
  },
  SET_REVIEW_SETTINGS(state, settings) {
    state.reviewSettings = settings
  },
  SET_REVIEW_SUBMITTED(state, value) {
    state.reviewSubmitted = value
  },
  SET_NEW_REVIEW(state, review) {
    state.newReview = review
  },
  RESET_PRODUCTS(state) {
    state.currentPage = 1
    state.pageCount = 0
    state.count = 0
    state.totalCount = 0
    state.products = []
    state.images = []
    state.variants = []
  },
  SET_FILTER_BAR_TAGS(state, tags) {
    state.filterBarTags = tags
  },
  SET_PRODUCTS_UPDATING(state, updating) {
    state.productsUpdating = updating
  },
  SHOP_UPDATED(state, updated) {
    state.shopUpdated = updated
  },
  SET_SORT(state, sort) {
    state.sort = sort
  },
  SET_TAXONOMIES(state, res) {
    let data = res.data
    const included = res.included
    const taxonomies = {}
    if (!Array.isArray(data)) {
      data = [data]
    }
    data.forEach(t => {
      if (t !== undefined) {
        taxonomies[baseLink(t.attributes.permalink)] = t
        const taxons = {}
        included.forEach(c => {
          if (c.relationships.parent.data.id === t.id) {
            const key = c.attributes.permalink.replace(
              `${t.attributes.permalink}/`,
              ''
            )
            taxons[key.toLowerCase()] = c
          }
        })
        taxonomies[t.attributes.permalink].taxons = taxons
      }
    })
    state.taxonomies = taxonomies
  }
}

export const getters = {
  filterColors(state) {
    return state.filterColors
  },
  filterText(state) {
    return state.filterText
  },
  filterSize(state) {
    return state.filterSize
  },
  products(state) {
    return state.products
  },
  productsParams(state) {
    // TODO: Need to set for types of products
    const params = `?page=${state.page}&per_page${state.perPage}`
    return params
  },
  taxonByName(state) {
    const taxons = {}
    state.taxons.map(t => (taxons[t.attributes.permalink] = t.id))
    return taxons
  },
  extraCategoryIds(state) {
    const extras = getExtraCategories(state)
    return extras.map(e => e.id)
  },
  extraCategories(state) {
    return getExtraCategories(state)
  },
  selectedColorIds(state, getters) {
    const colorTaxons = state.filterColors.map(c => {
      return getColor(state, c.split('/').reverse()[0])
    })
    return colorTaxons.filter(f => f !== null).map(f => {
      return f.id
    })
  },
  selectedSizeIds(state, getters) {
    const sizeTaxons = state.filterSize.map(s => {
      return getSize(state, s.split('/').reverse()[0])
    })
    return sizeTaxons.filter(f => f != null).map(f => f.id)
  },
  filters(state, getters) {
    const hasTextFilter = state.filterText.length > 0
    const hasSizeFilter = state.filterSize.length > 0
    const hasColorFilter = state.filterColors.length > 0
    const hasThemeFilter = state.filterThemes.length > 0
    const hasCategoryFilter = state.filterCategory.length > 0
    const noFilter =
      !hasSizeFilter &&
      !hasTextFilter &&
      !hasColorFilter &&
      !hasCategoryFilter &&
      !hasThemeFilter
    if (noFilter) {
      return ''
    }
    const filter = []
    if (hasTextFilter) {
      filter.push(`filter[by_keyword]=${state.filterText}`)
    }
    if (hasColorFilter) {
      const selectedColors = getters.selectedColorIds
      if (isDefined(selectedColors)) {
        const taxonIds = selectedColors.join(',')
        filter.push(`filter[stomp][color]=${cleanCommas(taxonIds)}`)
      }
    }
    if (hasCategoryFilter) {
      if (state.filterCategory !== null) {
        const ids = state.filterCategory.map(c => {
          return c.id
        })
        filter.push(`filter[stomp][category]=${cleanCommas(ids.join(','))}`)
      }
    }
    if (hasThemeFilter) {
      const ids = state.filterThemes.join(',')
      filter.push(`filter[stomp][theme]=${cleanCommas(ids)}`)
    }
    if (hasSizeFilter) {
      const ids = getters.selectedSizeIds.join(',')
      filter.push(`filter[stomp][size]=${cleanCommas(ids)}`)
    }
    return `?${filter.join('&')}`
  },
  keywordFilter(state) {
    const hasTextFilter = state.filterText.length > 0
    if (hasTextFilter) {
      return `filter[by_keyword]=${state.filterText}`
    }
    return ''
  },
  laserCutThemes(state) {
    return getThemes(state, 'laser-cut')
  },
  laserCutMaterials(state) {
    return getMaterials(state, 'laser-cut')
  },
  studThemes(state) {
    return getThemes(state, 'stud-earrings')
  },
  studMaterials(state) {
    return getMaterials(state, 'stud-earrings')
  },
  dangleThemes(state) {
    return getThemes(state, 'dangle-earrings')
  },
  dangleMaterials(state) {
    return getMaterials(state, 'dangle-earrings')
  },
  necklaceThemes(state) {
    return getThemes(state, 'necklaces')
  },
  necklaceMaterials(state) {
    return getMaterials(state, 'necklaces')
  }
}

export const actions = {
  async getProducts({ commit, state }, ids) {
    const filterIds = ids ? `&filter[ids]=${ids.join(',')}` : ''
    const options = {
      page: state.page,
      perPage: state.perPage,
      filter: filterIds
    }
    const { data, error } = await this.$api.products.index(options)
    if (error) {
      commit('error/SET_ERRORS', error.message, { root: true })
    } else {
      commit('SET_PRODUCTS', data)
      this.$normalizer.flatten('products', data)
    }
  },
  async haveYouSeen({ commit, state, rootState }) {
    const options = {
      page: state.currentPage,
      sort: 'random',
      perPage: 4,
      seed: rootState.randSeed
    }
    if (
      state.haveYouSeen === null ||
      state.haveYouSeen === undefined ||
      Object.keys(state.haveYouSeen).length < 30
    ) {
      const data = await this.$api.products.index(options)
      // const { data, error } = await this.$api.products.haveYouSeen()
      try {
        commit('SET_HAVE_YOU_SEEN', data)
        const normed = normalize(data)
        commit('SET_IMAGES', normed.image)
        commit('SET_VARIANTS', normed.variant)
      } catch (error) {
        console.log('haveYouSeen', error.message)
      }
    }
  },
  async productsList({ commit, state, getters, rootState }, ids) {
    const filters = []
    if (ids) {
      filters.push(`filter[ids]=${ids.join(',')}`)
    }
    if (getters.filters.length > 0) {
      filters.push(getters.filters)
    }
    const options = {
      filter: filters.join('&'),
      page: state.currentPage,
      sort: state.sort,
      seed: rootState.randSeed
    }
    // const data =
    //   filters.length > 0
    //     ? await this.$api.products.search(options)
    //     : await this.$api.products.index(options)

    const data = await this.$api.products.index(options)

    commit('SET_PAGES', data)
    try {
      if (data.data && data.data.length === 0) {
        commit('SET_PRODUCTS', [])
        commit('SET_IMAGES', [])
        commit('SET_VARIANTS', [])
      }
      this.$normalizer.flatten('products', data)
    } catch (error) {
      console.log(error)
      commit('SET_PRODUCTS_UPDATING', false)
      commit('error/SET_ERRORS', error.message, { root: true })
    }
  },
  async newInStore({ commit, state, getters }) {
    const options = {
      filter: '',
      page: 1,
      sort: '-created_at'
    }
    const data = await this.$api.products.index(options)

    commit('SET_PAGES', data)
    try {
      if (data.data && data.data.length === 0) {
        commit('SET_PRODUCTS', [])
        commit('SET_IMAGES', [])
        commit('SET_VARIANTS', [])
      }
      this.$normalizer.flatten('products', data)
    } catch (error) {
      console.log(error)
      commit('SET_PRODUCTS_UPDATING', false)
      commit('error/SET_ERRORS', error.message, { root: true })
    }
  },
  async setProductTypeFilter({ commit, state }, taxon) {
    if (!taxon) {
      commit('SET_FILTER_CATEGORIES', [])
    } else {
      const data = await this.$api.taxons.get(taxon)
      try {
        const obj = normalize(data)
        commit('SET_FILTER_CATEGORIES', Object.keys(obj.taxon))
      } catch (error) {
        commit('error/SET_ERRORS', error.message, { root: true })
      }
    }
  },
  setCategoryFilter({ commit, state }, taxonNames) {
    if (!taxonNames) {
      commit('SET_FILTER_CATEGORIES', [])
    } else {
      const filters = taxonNames.map(t => {
        return getCategory(state, t.split(' ').join('-'))
      })
      commit('SET_FILTER_CATEGORIES', filters)
    }
  },
  setExtrasFilter({ commit, getters }) {
    commit('SET_FILTER_CATEGORIES', getters.extraCategories)
  },
  async setThemeFilter({ commit, dispatch, state }, taxonNames) {
    if (!state.taxonomies) {
      await dispatch('setTaxonomies')
      await dispatch('setFixedTaxons')
    }
    const themes = []
    state.filterCategory.forEach(cat => {
      if (cat.fixedTaxons !== null && cat.fixedTaxons !== undefined) {
        cat.fixedTaxons.forEach(f => {
          if (taxonNames.includes(baseLink(f.attributes.permalink))) {
            themes.push(f)
          }
        })
      } else if (taxonNames.includes(baseLink(cat.attributes.permalink))) {
        themes.push(cat)
      }
    })
    const filters = themes.filter(t => t !== null).map(t => {
      return t.id
    })
    commit('SET_FILTER_THEMES', filters)
  },
  async updateFilters({ commit, dispatch }, { filterType, filter, listProd }) {
    const list = listProd === undefined || listProd === true
    switch (filterType) {
      case 'color':
        // await dispatch('getTaxons', filter)
        commit('SET_FILTER_COLORS', filter)
        break
      case 'text':
        commit('SET_FILTER_TEXT', filter)
        break
      case 'size':
        // await dispatch('getTaxons', filter)
        commit('SET_FILTER_SIZES', filter)
        break
      default:
        break
    }
    if (list) {
      commit('SET_PRODUCTS_UPDATING', true)
      commit('SET_CURRENT_PAGE', 1)
      await dispatch('productsList')
      commit('SET_PRODUCTS_UPDATING', false)
    }
    dispatch('updateFilterStorage')
  },
  // async setTaxons({ commit, state }) {
  //   if (state.taxons.length === 0) {
  //     const { data } = await this.$api.taxons.index('&filter[roots]=true')
  //     commit('SET_TAXONS', data)
  //   }
  // },
  async getTaxon({ commit, dispatch, getters }, permalink) {
    // const link = permalink.replace(' ', '-').toLowerCase()
    const link = permalink.split(' ').join('-')
    if (!getters.taxonByName[link]) {
      const { data } = await this.$api.taxons.get(link)
      if (data) {
        commit('ADD_TAXON', data)
      }
    }
  },
  async getTaxons({ commit, dispatch, getters }, permalinks) {
    const results = permalinks.map(p => {
      return dispatch('getTaxon', p)
    })
    await Promise.all(results)
  },
  async setTaxonomies({ commit }) {
    const res = await this.$api.taxons.index(
      '&filter[roots]=true&include=children&fields[taxon]=name,permalink,parent'
    )
    commit('SET_TAXONOMIES', res)
  },
  async setCategoryTaxonomies({ commit }) {
    const res = await this.$api.taxons.get('categories', 'include=children')
    commit('SET_TAXONOMIES', res)
  },
  resetFilters({ commit }) {
    commit('RESET_FILTERS')
  },
  resetFilterBar({ commit, dispatch }) {
    commit('SET_FILTER_COLORS', [])
    commit('SET_FILTER_SIZES', [])
    commit('SET_FILTER_TEXT', [])
    dispatch('updateFilterStorage')
  },
  restoreFilters({ commit }) {
    const filters = nuxtStorage.localStorage.getData('filters')
    if (filters) {
      commit('SET_FILTER_COLORS', filters.filterColors)
      commit('SET_FILTER_SIZES', filters.filterSize)
      commit('SET_FILTER_TEXT', filters.filterText)
    }
  },
  updateFilterStorage({ state }) {
    updateFilterStorage(state)
  },
  async setFixedTaxons({ commit, state, dispatch }) {
    if (!state.taxonomies) {
      await dispatch('setTaxonomies')
    }
    const fixedCats = state.fixedCategories
    const catTaxons = []
    await Promise.all(
      fixedCats.map(async cat => {
        const route = cat.startsWith('categories/') ? cat : `categories/${cat}`
        const data = await this.$api.taxons.getCoincidentTaxons(route)
        const taxons = taxonsToArray(data)
        if (taxons) {
          catTaxons.push({ taxons, category: cat })
        }
      })
    )
    catTaxons.forEach(t => {
      commit('SET_FIXED_TAXONS', t)
    })
  },
  async reviewSettings({ commit }) {
    const data = await this.$api.reviews.settings()
    commit('SET_REVIEW_SETTINGS', data)
  },
  async createReview({ commit, state }, review) {
    commit('SET_REVIEW_SUBMITTED', false)
    const { data, error } = await this.$api.reviews.create(review)
    if (!error) {
      commit('SET_REVIEW_SUBMITTED', true)
      const msg = state.reviewSettings.data.attributes
        .include_unapproved_reviews
        ? 'Review added'
        : 'Review submitted for approval'
      commit('SET_MESSAGE', msg, { root: true })
      commit('SHOW_MESSAGE', true, { root: true })
      commit('SET_NEW_REVIEW', data.data)
    }
  },
  resetProducts({ commit }) {
    commit('RESET_PRODUCTS')
  },
  async favoriteProducts({ rootState, dispatch }) {
    const favs = rootState.account.favorites
    if (favs && Object.keys(favs).length > 0) {
      try {
        await dispatch('setProductTypeFilter')
        await dispatch('productsList', Object.keys(favs))
      } catch (error) {
        console.log(error)
      }
    } else {
      await dispatch('resetProducts')
    }
  },
  setFilterBarTags({ state, commit }) {
    const keywords = state.filterText.map(k => {
      return { name: k, type: 'text' }
    })
    const colors = state.filterColors.map(c => {
      return { name: c, type: 'color' }
    })
    const sizes = state.filterSize.map(s => {
      return { name: s, type: 'size' }
    })
    const tags = [...keywords, ...sizes, ...colors]
    commit('SET_FILTER_BAR_TAGS', tags)
  }
}

const taxonsToArray = data => {
  const normed = normalize({ data: data.included })
  if (normed && normed.taxon) {
    return Object.keys(normed.taxon).map(id => {
      return normed.taxon[id]
    })
  }
  return []
}

const updateFilterStorage = state => {
  nuxtStorage.localStorage.setData(
    'filters',
    {
      filterText: state.filterText,
      filterSize: state.filterSize,
      filterColors: state.filterColors,
      filterCategory: state.filterCategory,
      filterThemes: state.filterThemes
    },
    1,
    'd'
  )
}

const getState = (state, path) => {
  const elements = path.split('.')
  let rootPath = state[elements[0]]
  if (rootPath === null || rootPath === undefined) {
    return null
  }
  for (let index = 1; index < elements.length; index++) {
    const nextPath = elements[index]
    if (rootPath[nextPath] === null || rootPath[nextPath] === undefined) {
      return null
    }
    rootPath = rootPath[nextPath]
  }
  return rootPath
}

const getThemes = (state, category) => {
  const statePath = `taxonomies.categories.taxons.${category}.fixedTaxons`
  const themes = getState(state, statePath)
  const themeTaxons = themes
    ? themes.filter(t => t.attributes.permalink.startsWith('theme'))
    : []
  return sortNameAlpha(themeTaxons)
}

const getMaterials = (state, category) => {
  const statePath = `taxonomies.categories.taxons.${category}.fixedTaxons`
  const themes = getState(state, statePath)
  const materials = themes
    ? themes.filter(t => t.attributes.permalink.startsWith('material'))
    : []
  return sortNameAlpha(materials)
}

const getColor = (state, color) => {
  const statePath = `taxonomies.colour.taxons.${color.toLowerCase()}`
  return getState(state, statePath)
}

const getSize = (state, size) => {
  const statePath = `taxonomies.earring-size.taxons.${size.toLowerCase()}`
  return getState(state, statePath)
}

const getCategory = (state, category) => {
  const statePath = `taxonomies.categories.taxons.${category.toLowerCase()}`
  return getState(state, statePath)
}

const getExtraCategories = state => {
  const cats = getState(state, 'taxonomies.categories.taxons')
  if (cats === null) {
    return []
  }
  const extras = Object.keys(cats).filter(k => {
    return !state.fixedCategories.includes(baseLink(k))
  })
  const more = extras.map(k => {
    return cats[k]
  })
  return sortNameAlpha(more)
}

const baseLink = link => {
  return link.split('/').reverse()[0]
}
