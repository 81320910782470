<template>
  <div>
    <b-nav-item to="/bag" class="bag-nav">
      <span class="d-none d-sm-block">
        <fa :icon="['fas', 'shopping-bag']" size="1x" />
        {{ bagText }}
      </span>
      <span class="d-block d-sm-none">
        <fa :icon="['fas', 'shopping-bag']" size="1x" />
        {{ bagTextXS }}
      </span>
    </b-nav-item> 
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      itemCount: 'cart/itemCount',
      displayItemTotal: 'cart/displayItemTotal'
    }),
    bagText() {
      return this.itemCount === 1
        ? `Shopping bag ${this.itemCount} item (${this.displayItemTotal})`
        : `Shopping bag ${this.itemCount} items (${this.displayItemTotal})`
    },
    bagTextXS() {
      return `(${this.displayItemTotal})`
    }
  }
}
</script>
<style lang="scss">
li.nav-item.bag-nav {
  a {
    padding: 0;
  }
}
</style>
