import ApiError from './error'

export default $axios => ({
  async index() {
    try {
      const { data } = await $axios.get(`api/carousel_images`)
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  }
})
