import normalize from 'json-api-normalizer'

export default (data, type, asArray = true) => {
  const obj = normalize(data)
  const typeObj = obj[type]
  if (typeObj) {
    if (asArray) {
      return Object.keys(typeObj).map(k => {
        return typeObj[k]
      })
    }
    return typeObj
  }
  return asArray ? [] : {}
}
