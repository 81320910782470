<template>
  <b-container>
    <div class="wrapper">
      <Topnav />
      <nuxt />
      <sidebar />
      <slot>
        <have-you-seen v-if="showHaveYouSeen" />
        <b-row v-if="showAlerts" size="lg">
          <b-col size="lg">
            <Alerts :white-background="whiteBackground" />
          </b-col>
        </b-row>
      </slot>
      <!-- <div v-show="showSidebar" class="overlay active" /> -->
    </div>
  </b-container>
</template>
<script>
import Alerts from '~/components/Alerts'
import HaveYouSeen from '~/components/HaveYouSeen'
import Topnav from '~/components/Topnav'
import Sidebar from '~/components/SidebarNav'

export default {
  components: {
    Alerts,
    Topnav,
    sidebar: Sidebar,
    'have-you-seen': HaveYouSeen
  },
  props: {
    showAlerts: {
      type: Boolean,
      default: true
    },
    showHaveYouSeen: {
      type: Boolean,
      default: true
    },
    whiteBackground: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showSidebar() {
      return this.$store.state.sitenav.sidebarShown
    }
  }
}
</script>
