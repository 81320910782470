import ApiError from './error'

export default $axios => ({
  async settings() {
    try {
      const { data } = await $axios.get(`api/reviews/settings`)
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  // Create a review
  async create(reviewParams) {
    try {
      const { data } = await $axios.post(`api/reviews`, reviewParams)
      return { data, error: null }
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async list(productId, perPage, page) {
    try {
      const { data } = await $axios.get(
        `api/reviews?product_id=${productId}&per_page=${perPage}&page=${page}&include=product`
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async get(id) {
    try {
      const { data } = await $axios.get(`api/reviews/${id}?include=product`)
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  }
})
