<template>
  <div>
    <b-form-row>
      <b-col sm="8">
        <b-form-group
          id="name-group"
          label="Name:"
          label-for="name"    
        >
          <b-form-input
            id="name"
            v-model="name"
            v-validate="{ required: true, min: 3 }"
            :state="validateState('name')"
            name="name"
            aria-describedby="name-feedback"
            size="md"
            type="text"
            placeholder="NAME"
          />
          <b-form-invalid-feedback id="name-feedback">
            Name required (min. 3 characters)
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          id="phone-group"
          label="Phone:"
          label-for="contact-phone"    
        >
          <b-form-input
            id="contact-phone"
            v-model="phone"
            v-validate="{ required: true, regex: /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/ }"
            name="contact-phone"
            size="md"
            type="tel"
            placeholder="PHONE"
            :state="validateState('contact-phone')"
            aria-describedby="contact-phone-feedback"
          />
          <b-form-invalid-feedback id="contact-phone-feedback">
            Invalid phone number
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col sm="6">
        <b-form-group
          id="email-group"
          label="Email:"
          label-for="contact-email"    
        >
          <b-form-input
            id="contact-email"
            ref="contact-email"
            v-model="email"
            v-validate="'required|email'"
            name="contact-email"
            size="md"
            type="text"
            placeholder="EMAIL"
            required
            :state="validateState('contact-email')"
            aria-describedby="contact-email-feedback"
          />
          <b-form-invalid-feedback id="contact-email-feedback">
            Invalid email
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group
          id="email-group-confirm"
          label="Email confirmation:"
          label-for="contact-email-confirm"    
        >
          <b-form-input
            id="contact-email-confirm"
            v-model="emailConfirm"
            v-validate="'confirmed:contact-email|required'"
            name="contact-email-confirm"
            size="md"
            type="text"
            placeholder="CONFIRM EMAIL"
            required
            :state="validateState('contact-email-confirm')"
            aria-describedby="contact-email-confirm-feedback"
          />
          <b-form-invalid-feedback id="contact-email-confirm-feedback">
            Emails must match
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
  </div> 
</template>

<script>
import validateState from './../mixins/validateState'

export default {
  mixins: [validateState],
  data() {
    return {
      name: '',
      email: null,
      emailConfirm: null,
      phone: ''
    }
  },
  computed: {
    contactData() {
      return {
        name: this.name,
        email: this.email,
        phone: this.phone
      }
    }
  },
  methods: {
    reset() {
      this.name = ''
      this.email = ''
      this.phone = ''
    }
  }
}
</script>
