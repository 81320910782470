import ApiError from './error'

export default $axios => ({
  async create(id, bearer) {
    try {
      const opts = {
        headers: {
          Authorization: bearer || false
        }
      }
      const body = {
        favoritable_type: 'Spree::Product',
        favoritable_id: id
      }
      const { data } = await $axios.post(`api/favorites`, body, opts)
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async delete(id, bearer) {
    try {
      const opts = {
        headers: {
          Authorization: bearer || false
        }
      }
      const { data } = await $axios.delete(`api/favorites/${id}`, opts)
      return data
    } catch (error) {
      console.log(error)
      return { error: ApiError(error), data: null }
    }
  },
  async empty(bearer) {
    try {
      const opts = {
        headers: {
          Authorization: bearer || false
        }
      }
      const { data } = await $axios.delete(`api/favorites/empty`, opts)
      return data
    } catch (error) {
      console.log(error)
      return { error: ApiError(error), data: null }
    }
  }
})
