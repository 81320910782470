import constants from '../utils/constants'
import ApiError from './error'

export default $axios => ({
  async orderStatus(orderToken, orderNumber) {
    const opts = {
      headers: {
        'X-Spree-Order-Token': orderToken
      }
    }
    try {
      const { data } = await $axios.get(
        `api/order_status/${orderNumber}?include=${constants.orderInclude}`,
        opts
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  }
})
