<template>
  <div>
    <b-overlay :show="running" :opacity="0.65" rounded="sm">
      <h2>{{ title }}</h2>
      <b-form-group
        v-if="showEmail && !tokenSent"
        id="fieldset1"
      >
        <label for="login__email-input">Email Address</label>
        <b-form-input
          id="login__email-input"
          v-model="email"
          v-validate="'required|email'"
          name="email"
          type="text"
          placeholder="Your email address"
          :state="validateState('email')"
          aria-describedby="login__email-input-feedback"
          data-vv-validate-on="input"
        />
        <b-form-invalid-feedback id="login__email-input-feedback">
          {{ veeErrors.first('email') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <div v-else>
        <p>
          Your password recovery information has been sent to your email address.
        </p>
        <p>
          Note: The recovery link is only valid for the next 10 minutes, so hurry!
        </p>
      </div>
      <b-form-row>
        <b-col v-if="!resetPasswordToken" :lg="signUpForm ? 6 : 12">
          <b-form-group
            id="fieldset2"
          >
            <slot name="label">
              <div class="mb-1">
                <span class="se-pink">
                  *
                </span>
                Password:
              </div>
            </slot>

            <b-form-input
              id="login__password-input"
              ref="password"
              v-model="password"
              v-validate="{ required: true }"
              type="password"
              name="password"
              aria-describedby="login__password-feedback"
              placeholder="Your password"
              @input="isValid"
              @keydown.enter.native="submit"
            />
            <div v-if="veeErrors.first('password')" class="invalid-feedback" style="display: block;">
              {{ veeErrors.first('password') }}
            </div>      
          </b-form-group>
        </b-col>
        <b-col v-if="signUpForm" lg="6">
          <b-form-group
            id="fieldset3"
          >
            <slot name="label">
              <div class="mb-1">
                <span class="se-pink">
                  *
                </span>
                Password Confirmation:
              </div>
            </slot>
            <b-form-input
              v-if="signUpForm" 
              id="login__email-login__password-confirm"
              v-model="passwordConfirmation"
              v-validate="'required|confirmed:password'"
              data-vv-as="Password Confirmation"
              name="password-confirm"
              type="password"
              placeholder="Confirm password"
              @input="isValid"
            />

            <div v-if="veeErrors.first('password-confirm')" class="invalid-feedback" style="display: block;">
              {{ veeErrors.first('password-confirm') }}
            </div>
          </b-form-group>
        </b-col>
        <b-col>
          <div v-if="loginError" class="invalid-feedback mt-3" style="display: block;">
            <span v-if="loginError.error === 'invalid_grant'">
              Email or Password is incorrect
            </span>
            <span v-else>
              <p>
                {{ loginError.message }}
              </p>
            </span>
          </div>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col v-if="resetPasswordToken && !tokenSent" sm="5" order-sm="2">
          <b-button
            variant="primary"
            block
            :disabled="running"
            @click="submitReset"
          >
            Recover password
          </b-button>
        </b-col>
        <b-col sm="7" order-sm="1">
          <b-button variant="link" class="pl-0" @click="showReset">
            {{ !resetPasswordToken ? 'Forgot your password?' : 'Back to login' }}
          </b-button>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <p class="mt-2">
            Don't have an account. 
            <b-link @click="showSignUp">
              Create one
            </b-link>
            to get started.
          </p>
        </b-col>
      </b-form-row>
      <b-button
        v-if="!hideButtons"
        variant="primary"
        class="mt-2"
        :disabled="running"
        @click="onSubmit"
      >
        {{ signUpForm ? 'Create account' : 'Login' }}
      </b-button>
    </b-overlay>
  </div>
</template>

<script>
import validateState from './../mixins/validateState'
import { titleCase } from './../utils/format'
export default {
  mixins: [validateState],
  props: {
    title: {
      type: String,
      default: 'Login to your account'
    },
    signUpForm: {
      type: Boolean,
      default: false
    },
    hideButtons: {
      type: Boolean,
      default: false
    },
    showEmail: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      email: '',
      password: null,
      passwordConfirmation: '',
      error: null,
      resetPasswordToken: false,
      tokenSent: false,
      running: false
    }
  },
  computed: {
    formValid() {
      return this.veeErrors && this.veeErrors.items.length === 0
    },
    loginError() {
      return this.$store.state.error.loginError
    },
    loginErrMsgs() {
      if (this.loginError && this.loginError.errors) {
        return Object.keys(this.loginError.errors).map(k => {
          return `${titleCase(k, '_')} ${this.loginError.errors[k]}`
        })
      }
      return []
    }
  },
  mounted() {
    this.running = false
    this.error = null
    this.$store.commit('error/SET_LOGIN_ERROR', null)
    this.$emit('hide-reset')
  },
  methods: {
    onSubmit(evt) {
      this.running = true
      evt.preventDefault()
      this.signUpForm ? this.signup() : this.login()
    },
    async submit() {
      const valid = await this.isValid()
      if (valid) {
        this.$emit('submit')
      }
      this.running = true
    },
    async login() {
      const valid = await this.isValid()
      if (!valid) {
        return
      }
      this.running = true
      this.$store.commit('SET_LOADING', true)
      const creds = { email: this.email, password: this.password }
      const { error } = await this.$store.dispatch('account/loginLocal', creds)
      if (!error) {
        await this.$store.dispatch('cart/setShouldMerge', null, { root: true })
        await this.$store.dispatch('cart/getOrMergeCart', null, { root: true })
        this.$emit('logged-in')
      } else {
        this.error = error.response ? error.response.data.message : error
      }
      this.running = false
    },
    async signup() {
      try {
        const valid = await this.isValid()
        if (!valid) {
          return
        }
        const payload = {
          email: this.email,
          password: this.password,
          passwordConfirm: this.passwordConfirmation
        }
        await this.$store.dispatch('cart/setShouldMerge')
        await this.$store.dispatch('account/register', payload)
        if (!this.loginError) {
          await this.$store.dispatch('cart/getOrMergeCart')
          this.$emit('account-created')
        }
      } catch (e) {
        console.log('auth error', e)
        this.error = e.response.data.message
      }
      this.running = false
    },
    showReset() {
      this.resetPasswordToken = !this.resetPasswordToken
      this.resetPasswordToken
        ? this.$emit('show-reset')
        : this.$emit('hide-reset')
      this.tokenSent = false
    },
    async submitReset() {
      const valid = await this.isValid()
      if (valid) {
        const { error } = await this.$api.user.recoverPassword(this.email)
        if (error) {
          this.$store.commit('error/SET_LOGIN_ERROR', error)
        } else {
          this.$store.commit('error/SET_LOGIN_ERROR', null)
          this.tokenSent = true
        }
      }
    }
  }
}
</script>
