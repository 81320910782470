<template>
  <div class="productcard__row">
    <b-row class="mt-4">
      <div class="full-width-div bg-white" />
      <p class="col-12 mt-2 pink">
        {{ title }}
      </p>
    </b-row>
    <b-row>
      <div class="full-width-div bg-white" />
      <b-col v-for="product in filteredHaveYouSeen" :key="'product_' + product.id" sm="12" md="6" lg="3">
        <product-card :product="product" class="mb-4" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ProductCard from '~/components/ProductCard'
import { getRandom } from './../utils/arrayUtils'
import { mapState } from 'vuex'
export default {
  components: {
    'product-card': ProductCard
  },
  props: {
    title: {
      type: String,
      default: 'Have you seen...'
    }
  },
  data() {
    return {
      haveYouSeenProducts: []
    }
  },
  computed: {
    ...mapState('products', {
      haveYouSeen: state => state.haveYouSeen
    }),
    filteredHaveYouSeen() {
      if (this.haveYouSeen) {
        const ids = Object.keys(this.haveYouSeen)
        const randIds = getRandom(ids, 5)
        const slug =
          this.$route.name === 'shop-slug' ? this.$route.params.slug : ''
        const products = randIds.map(i => this.haveYouSeen[i])
        const productsFiltered = products.filter(
          p => p.attributes.slug !== slug
        )
        return productsFiltered.slice(0, 4)
      } else {
        return {}
      }
    }
  }
}
</script>
