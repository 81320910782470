export default {
  data() {
    return {
      validState: false
    }
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    async validate() {
      const res = await this.$validator.validateAll()
      this.$store.commit('checkout/SET_CAN_CONTINUE', res)
    },
    isValid() {
      this.validState = this.$validator.validateAll()
      return this.validState
    },
    resetState() {
      this.$validator.reset()
    }
  }
}
