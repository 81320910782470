// import constants from '../utils/constants'
// import ApiError from './error'
// import axios from 'axios'
import constants from './../utils/constants'
export default $axios => ({
  async exists(url, fallbackUrl) {
    const url2 = url.replace(constants.spacesPath, constants.cdnPath)
    try {
      await $axios.get(url2)
      return url2
    } catch (error) {
      const fb = fallbackUrl.replace(constants.spacesPath, constants.cdnPath)
      try {
        await $axios.get(fallbackUrl)
        return fb
      } catch (error) {
        return url
      }
    }
  }
})
