export default {
  title: 'FAQ',
  description:
    'Browse through our Frequently Asked Questions (FAQs) for answers to the most common questions we get here at Stomping Elephants including shipping information, returns policy and questions about our earrings and other products.',
  keywords: ['help', 'faq'],
  content: {
    'faq-1': {
      q: {
        'faq-1__q-1': {
          tag: 'p',
          value: 'Can I wear my Stomping Elephants jewellery in the water?'
        }
      },
      a: {
        'faq-1__a-1': {
          tag: 'p',
          value:
            'To keep your Stomping Style looking pristine, we recommend that you do not wear your earrings or in the water.'
        }
      }
    },
    'faq-2': {
      q: {
        'faq-2__q-1': {
          tag: 'p',
          value: 'What if I have sensitive skin?'
        }
      },
      a: {
        'faq-2__a-1': {
          tag: 'p',
          value:
            'We care about your health and only use quality products. Our earring posts are made from hypoallergenic surgical steel -- perfect for sensitive ears. Our necklace chains are made of stainless steel. Glass pendant backs are made of zinc alloy. All materials are lead and nickel free.'
        }
      }
    },
    'faq-3': {
      q: {
        'faq-3__q-1': {
          tag: 'p',
          value: 'Are your earrings suitable for children?'
        }
      },
      a: {
        'faq-3__a-1': {
          tag: 'p',
          value:
            'Our earrings are suitable for any age (once ears have been pierced), however, we <strong>strongly recommend</strong> that younger children are supervised at all times while wearing them as the small parts could easily be swallowed or be a choking hazard. Stomping Elephants Pty Ltd will not be held liable for any loss, damages or injuries related to the misuse of our products.'
        }
      }
    },
    'faq-4': {
      q: {
        'faq-4__q-1': {
          tag: 'p',
          value: 'Do you accept returns or exchanges?'
        }
      },
      a: {
        'faq-4__a-1': {
          tag: 'p',
          value:
            'All of our products are handmade with care - because of this, there may be elements of handmade charm, which only add to the uniqueness of your individual earrings! When choosing an item, please do so with care as we do not refund or exchange for change of mind. If you are unhappy with an item, please <a href="/help/contact">contact us</a> to discuss options. Postage costs cannot be refunded.'
        }
      }
    },
    'faq-5': {
      q: {
        'faq-5__q-1': {
          tag: 'p',
          value: 'Do you do custom orders?'
        }
      },
      a: {
        //'faq-5_a-1': {
        //  tag: 'strong',
        //  value:
        //    '**PLEASE NOTE** Production for custom earring orders is currently closed. Please check back regularly for any updates about when custom production will re-open.'
        //},
        'faq-5__a-1': {
          tag: 'p',
          class: 'mt-3',
          value:
            'Yes, we LOVE doing custom orders! Whether it be a sports team logo, business logo, or club/community group logo, custom orders are a perfect way to advertise your brand. Our custom orders are based on the following criteria:'
        },
        'faq-5__a-2': {
          tag: 'ul',
          value: [
            'Minimum order is 20 pairs of earrings. Prices start from $8 per stud pair or $20 per dangle pair. Volume discounts may apply - see order form for further pricing details.',
            'A one-time $35 set-up fee will apply. Print-ready artwork is required. Acceptable file types include: vector AI, EPS, or PDF (preferred) or high-resolution PNG with transparent background.',
            'We require a 50% non-refundable deposit upon receiving your order form. No work will commence until the deposit has been received.',
            'If using a company or team logo, you must supply a letter of authorisation stating that you have permission to use the logo.',
            'Stomping Elephants will not be held liable for any infringment of copyright, trademark or intellectual property laws.',
            'Turnaround time is 2-5 weeks from design approval and payment of invoice (not date of order).',
            'Postage (standard tracked) is $15.',
            'All customs will be printed on a material agreed upon at the time of order (eg: acrylic, wood).',
            'For more information, please <a title="Custom Order Enquiry" href="/help/contact">contact us about your custom order enquiry</a>.'
          ]
        }
      }
    },
    'faq-6': {
      q: {
        'faq-6__q-1': {
          tag: 'p',
          value: 'How do I care for my jewellery?'
        }
      },
      a: {
        'faq-6__a-1': {
          tag: 'p',
          value:
            "If you're here, it most likely means you've bought some Stomping goodies, so thank you! As our products are handmade (with lots of love), they require some care to keep them looking their best. Even the best-made, highest quality jewellery is delicate, so please use these helpful tips to keep your own stash in tip top shape:"
        },
        'faq-6__a-2': {
          tag: 'ul',
          value: [
            'Keep your Stomping Elephants jewellery stored in a clean, dry environment away from direct sunlight and avoid getting your jewellery wet. This includes, but is not limited to swimming, bathing and showering.',
            'Put your jewellery on after applying cosmetics, moisturisers, or spraying perfumes as this may damage your jewellery.',
            'Avoid wearing your jewellery to bed. Although our earrings are made with hypoallergenic surgical steel for sensitive ears, leaving them in all night can cause irritation and can damage the product.',
            'Clean your jewellery using a damp cloth. Never use chemicals, and dry completely with a soft cloth.&nbsp;',
            'When removing your earrings, we recommend that you apply pressure to the pad of the earring stud post and pull off the clasp rather than pulling on the front of the earring, which may cause the glue to weaken.'
          ]
        }
      }
    },
    'faq-7': {
      q: {
        'faq-7__q-1': {
          tag: 'p',
          value: 'Is my personal information kept private?'
        }
      },
      a: {
        'faq-7__a-1': {
          tag: 'p',
          value:
            'Your privacy is important to us. All information collected is only used for the purpose of processing your order. For more information, please view our <a href="/privacy">privacy policy</a>'
        }
      }
    },
    'faq-8': {
      q: {
        'faq-8__q-1': {
          tag: 'p',
          value: 'What are the accepted methods of payment?'
        }
      },
      a: {
        'faq-8__a-1': {
          tag: 'p',
          value:
            'We accept payment through our secure checkout via credit card or PayPal. If paying via credit card, please note we do not store your personal credit card information. Processing time begins as soon as the funds have cleared. All prices displayed are in Australian Dollars.'
        }
      }
    },
    'faq-9': {
      q: {
        'faq-9__q-1': {
          tag: 'p',
          value: 'What are the shipping and pick-up policies?'
        }
      },
      a: {
        'faq-9__a-1': {
          tag: 'p',
          value:
            'For all information about shipping and pickup please see our <a href="/help/shipping">Shipping and Returns</a> page.'
        }
      }
    },
    'faq-10': {
      wrapClass: 'row',
      q: {
        'faq-10__q-1': {
          tag: 'p',
          value: 'What are your earring sizes?'
        }
      },
      a: {
        'faq-10__a-1': {
          tag: 'p',
          class: 'ml-3',
          value:
            "Please check the earring size carefully in the description of each product. To determine the earring's size, we measure from the largest point of the earring (for both width and height) to determine its size category. Categories are within millimetres of each other as shown below, going from a medium to a large, for example, is a very small jump, so please check carefully when choosing based on size."
        },
        'faq-10__a-2': {
          tag: 'span',
          class: 'ml-3 mr-sm-4',
          value:
            '<p class="mb-1">XS - 8-9mm</p><p class="mb-1">Small - 10-11mm</p><p class="mb-1">Medium - 12-13mm</p><p class="mb-1">Large - 14-15mm</p><p class="mb-1">XL - 16-17mm</p><p class="mb-1">2XL - 18-19mm</p><p class="mb-1">3XL - 20-21mm</p><p class="mb-1">4XL - 22mm+</p>'
        },
        'faq-10__a-4': {
          tag: 'sizechart',
          class: 'ml-3',
          value: ''
        }
      }
    },
    'faq-11': {
      q: {
        'faq-11__q-1': {
          tag: 'p',
          value: 'Where can I redeem e-Vouchers?'
        }
      },
      a: {
        'faq-11__a-1': {
          tag: 'p',
          value:
            'e-Vouchers can only be redeemed online at <a href="/">www.stompingelephants.com.au</a>. Simply add the e-Voucher code to the checkout in the "Gift Card" field and click "Add Gift Card" to apply. e-Vouchers are not redeemable at markets, stockists, or anywhere else Stomping Elephants products are sold.'
        }
      }
    },
    'faq-12': {
      q: {
        'faq-12__q-1': {
          tag: 'p',
          value: 'How do you determine postage costs?'
        }
      },
      a: {
        'faq-12__a-1': {
          tag: 'p',
          value:
            'To ensure your earrings arrive safely and our wonderful postal workers stay stab-free (yes, this has happened once many years ago - sorry Aus Post!), we can only send your new pretty earring packages as a tracked parcel. Unfortunately, we don’t set the price of postage and we are at the mercy of <a href="https://auspost.com.au/business/shipping/check-postage-costs#qld-parcel" target="blank">Australia Post</a> and other couriers who determine the shipping rates (yes it sucks, we agree). We also then have to factor in costs involved in the packaging such as boxes, bags, tape, labels, etc. We offer very affordable everyday prices on our earrings and have remained very competitive over the years. We understand this fee can be a shock to some, which is why we offer free postage for Australian domestic orders $49 and over (excludes e-Vouchers). Many of our customers take advantage of this offer by inviting friends to go in on the order so that you can quickly qualify for free shipping and get more bang for your buck together.'
        }
      }
    }
  }
}
