export default $axios => ({
  async staff() {
    try {
      const data = await $axios.get(`api/account/staff?per_page=100`)
      return data
    } catch (error) {
      return error
    }
  },
  async reps() {
    try {
      const data = await $axios.get(`api/account/reps?per_page=100`)
      return data
    } catch (error) {
      return error
    }
  }
})
