import faq from './static/faq'
import ourTeam from './static/our-team'
import AccountInfo from './static/account-info'
import AccountOrders from './static/account-orders'
import AccountFavourites from './static/account-favourites'
import AccountPassword from './static/account-password'
import PageMetaData from './static/page-metadata'
import NewInStore from './static/new-in-store'
import home from './static/home'
import Privacy from './static/privacy'
import SiteTerms from './static/site-terms'

export default {
  static: {
    'tell-me-more-our-team': ourTeam,
    'help-faq': faq,
    'account-info': AccountInfo,
    'account-orders': AccountOrders,
    'account-favourites': AccountFavourites,
    'account-password': AccountPassword,
    'new-in-store': NewInStore,
    'site-terms': SiteTerms,
    privacy: Privacy,
    index: home
  },
  meta: route => {
    return PageMetaData[route]
  }
}
