import ApiError from './error'
import constants from './../utils/constants'

export default $axios => ({
  async get(includeItems = false) {
    try {
      const res = includeItems
        ? await this.getCartWithItems()
        : await this.getCart()
      if (!res.error) {
        return res
      } else {
        return await this.createCart()
      }
    } catch (err) {
      return { error: ApiError(err), data: null }
    }
  },
  async getCart() {
    try {
      const { data } = await $axios.get(
        `api/cart?include=${constants.cartInclude}&fields[cart]=${
          constants.cartFields
        }`
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async getCartWithItems() {
    try {
      const { data } = await $axios.get(
        `api/cart?include=${constants.cartIncludeWithItems}&fields[cart]=${
          constants.cartFields
        }`
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async mergeCart(oldCartToken, bearer) {
    try {
      // const opts = setHeader(null, bearer)
      const incs = `include=${constants.cartIncludeWithItems}`
      const { data } = await $axios.patch(
        `api/cart/merge?other_order_token=${oldCartToken}&${incs}&fields[cart]=${
          constants.cartFields
        }`
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async createCart() {
    try {
      const { data } = await $axios.post(
        `api/cart?include=${constants.cartInclude}`,
        null
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  resetCart() {
    return this.createCart()
  },
  async addToCart(id, qty, giftCard) {
    try {
      const body =
        giftCard === null
          ? { variant_id: id, quantity: qty }
          : { variant_id: id, quantity: qty, gift_card: giftCard }
      const { data } = await $axios.post(
        `api/cart/add_item?fields[cart]=item_count,is_wholesale,display_item_total,wholesale_item_total,display_wholesale_item_total,token,insufficient_stock_line_ids`,
        body
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async mergeToCart(id, qty, giftCard) {
    try {
      const body =
        giftCard === null
          ? { variant_id: id, quantity: qty }
          : { variant_id: id, quantity: qty, gift_card: giftCard }
      const { data } = await $axios.post(
        `api/cart/add_item?include=${constants.cartInclude}&fields[cart]=${
          constants.cartFields
        }`,
        body
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async emptyCart() {
    try {
      const { data } = await $axios.patch(
        `api/cart/empty?include=${constants.cartInclude}`,
        null
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async setQuantity(lineItemId, qty) {
    try {
      const body = {
        line_item_id: parseInt(lineItemId),
        quantity: parseInt(qty)
      }
      const { data } = await $axios.patch(
        `api/cart/set_quantity?include=${constants.cartInclude}&fields[cart]=${
          constants.cartFields
        }`,
        body
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async removeLineItem(lineItemId) {
    try {
      const v = `?include=${constants.cartInclude}`
      const { data } = await $axios.delete(
        `api/cart/remove_line_item/${lineItemId}${v}&fields[cart]=${
          constants.cartFields
        }`
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  // uses the legacy api to update an order
  // must have the admin code in the header
  async updateSpecialInstructions(orderNumber, instructions) {
    try {
      const body = {
        special_instructions: instructions
      }
      const { data } = await $axios.patch(
        `legacyapi/orders/${orderNumber}`,
        body
      )
      return { data }
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async applyCoupon(couponCode) {
    try {
      const body = { coupon_code: couponCode }
      const { data } = await $axios.patch(
        `api/cart/apply_coupon_code?include=${constants.cartInclude}`,
        body
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async removeCoupon(couponCode) {
    try {
      const { data } = await $axios.delete(
        `api/cart/remove_coupon_code/${couponCode}?include=${
          constants.cartInclude
        }`
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async estimateShipping() {
    try {
      const { data } = await $axios.get(`api/cart/estimate_shipping_rates`)
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async resetShipping() {
    try {
      const { data } = await $axios.patch(
        `api/cart/reset_shipping?include=${constants.cartInclude}`,
        null
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  }
})

// const setHeader = (token, bearer) => {
//   const opts = {
//     headers: {
//       Authorization: bearer || false
//     }
//   }
//   if (token) {
//     opts.headers['X-Spree-Order-Token'] = token
//   }
//   return opts
// }

// const setCartHeader = token => {
//   const opts = {
//     headers: {}
//   }
//   opts.headers['X-Spree-Order-Token'] = token
//   return opts
// }
