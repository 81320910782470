import products from './products'
import taxons from './taxons'
import cart from './cart'
import lineItems from './lineItems'
import user from './user'
import countries from './countries'
import checkout from './checkout'
import orders from './orders'
import images from './images'
import favorites from './favorites'
import reviews from './reviews'
import wholesalers from './wholesalers'
import carousel from './carousel'
import paypal from './paypal'
import site from './site'
import team from './team'
import contact from './contact'
import mailchimp from './mailchimp'

export default $axios => ({
  cart: cart($axios),
  lineItems: lineItems($axios),
  products: products($axios),
  taxons: taxons($axios),
  user: user($axios),
  countries: countries($axios),
  checkout: checkout($axios),
  orders: orders($axios),
  images: images($axios),
  favorites: favorites($axios),
  reviews: reviews($axios),
  wholesalers: wholesalers($axios),
  team: team($axios),
  carousel: carousel($axios),
  paypal: paypal($axios),
  contact: contact($axios),
  mailchimp: mailchimp($axios),
  site: site
})
