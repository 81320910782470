export const state = () => ({
  staff: [],
  reps: []
})

export const mutations = {
  SET_STAFF(state, staff) {
    state.staff = staff
  },
  SET_REPS(state, reps) {
    state.reps = reps
  }
}

export const getters = {
  staff(state) {
    if (state.staff && state.staff.data && state.staff.data.length > 0) {
      return state.staff.data.map(s => s.attributes)
    }
    return []
  },
  reps(state) {
    if (state.reps && state.reps.data && state.reps.data.length > 0) {
      return state.reps.data.map(s => s.attributes)
    }
    return []
  }
}

export const actions = {
  async getStaff({ commit }) {
    const { data, error } = await this.$api.team.staff()
    if (!error) {
      commit('SET_STAFF', data)
    } else {
      commit('error/SET_ERRORS', error.message, { root: true })
    }
  },
  async getReps({ commit }) {
    const { data, error } = await this.$api.team.reps()
    if (!error) {
      commit('SET_REPS', data)
    } else {
      commit('error/SET_ERRORS', error.message, { root: true })
    }
  }
}

// const chunk = arr => {
//   const chunked = []
//   let index = 0
//   while (index < arr.length) {
//     chunked.push(arr.slice(index, 3 + index))
//     index += 3
//   }
//   return chunked
// }
