export default {
  title: 'Our Team',
  description:
    'Find our more about the amazing team who are behind the popular Stomping Elephants name! We are a diverse group of Stompers with one thing in common - our passion for fun, affordable earrings.',
  keywords: ['team', 'staff', 'contact'],
  content: {
    'stomping-hq': {
      'abby-jane': {
        name: {
          value: 'Abby-Jane'
        },
        title: {
          value: 'Chief Stomper'
        },
        about: {
          value:
            'Meet Abby - multi-tasker extraordinaire. The Original Stomper, Abby calls her work space in her garage at home, "Stomping HQ". Abby is a mother to two beautiful, vibrant mini-stompers and working from home means she can be involved in her daughters\' activities. She is very passionate about Stomping Elephants and loves providing fun, affordable stomping style for all the amazing stompers.'
        }
      },
      emma: {
        name: {
          value: 'Emma'
        },
        title: {
          value: 'Production Whipcracker'
        },
        about: {
          value:
            'After spending nearly two years as the Cairns rep Emma jumped at the opportunity to be a part of Stomping here in Mackay at both HQ and as our local market rep. Emma has recently relocated from Cairns with her husband and two small children to spend time with family. Emma loves working in production and helping make the magic happen. You will also see Emma around some of our local markets. Emma loves getting out and meeting our wonderful Stompers and see them proudly showing off our amazing range.'
        }
      },
      kym: {
        name: {
          value: 'Kym'
        },
        title: {
          value: 'Amazing All Rounder'
        },
        about: {
          value:
            'Kym is a great lover of all things handmade and has a genuine appreciation for creative people. She has been friends with Abby (our Chief Stomper) since age 19, and says it has been so exciting watching her create her Stomping Elephants journey. Kym feels extremely lucky that she is also an important part of that journey and that she gets to help produce all of the amazing earrings right here in her home town of Mackay. She is also a proud wife and mum of a family of boys, so she says wearing Stomping Elephants is what helps to keep her girly (even if her collection of over 370 pairs includes a lot of super hero and sport themed earrings picked by her boys).'
        }
      },
      nikki: {
        name: {
          value: 'Nikki'
        },
        title: {
          value: 'Digital Whiz'
        },
        about: {
          value:
            "Enter Stomping's resident nerd. A mother to two gorgeous little boys, Nikki has a quirky passion for all things tech and artsy. A graphic designer by profession, Nikki not only built Stomping's online presence from scratch, but she also designed the beautiful Stomping branding that you see on all of our materials. A jack-of-all-trades, Nikki also designs some of our earrings (including custom designs!) and photographs all our Stomping models."
        }
      },
      louise: {
        name: {
          value: 'Louise'
        },
        title: {
          value: 'Digital Whiz'
        },
        about: {
          value:
            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id sit est necessitatibus exercitationem fugit dolore, quas ullam veritatis quis minus dicta? Cupiditate minima iste distinctio molestias, at animi excepturi qui?'
        }
      },
      walshy: {
        name: {
          value: 'Walshy'
        },
        title: {
          value: 'Handy Stomper'
        },
        about: {
          value:
            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id sit est necessitatibus exercitationem fugit dolore, quas ullam veritatis quis minus dicta? Cupiditate minima iste distinctio molestias, at animi excepturi qui?'
        }
      }
    },
    'stomping-reps': {
      'abby-jane': {
        name: {
          value: 'Abby-Jane'
        },
        title: {
          value: 'Chief Stomper'
        },
        about: {
          value:
            'Meet Abby - multi-tasker extraordinaire. The Original Stomper, Abby calls her work space in her garage at home, "Stomping HQ". Abby is a mother to two beautiful, vibrant mini-stompers and working from home means she can be involved in her daughters\' activities. She is very passionate about Stomping Elephants and loves providing fun, affordable stomping style for all the amazing stompers.'
        }
      }
    }
  }
}
