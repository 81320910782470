<template>
  <div>
    <b-form>
      <b-form-group
        id="ig-firstname"
        label-for="gc__recipient_name"
      >
        <slot name="label">
          <div class="mb-1">
            <span class="se-pink">
              *
            </span>
            Recipient name:
          </div>
        </slot>
        <b-form-input
          id="gc__recipient_name"
          v-model="name"
          v-validate="{ required: true, min: 3 }"
          :state="validateState('gc__recipient_name')"
          name="gc__recipient_name"
          aria-describedby="gc__recipient_name-fb"
          required
          placeholder="Recipient name"
        />
        <b-form-invalid-feedback id="gc__recipient_name-fb">
          Name required (min. 3 characters)
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="gc__ig-email"
        label-for="input-email"
      >
        <slot name="label">
          <div class="mb-1">
            <span class="se-pink">
              *
            </span>
            Recipient Email address::
          </div>
        </slot>
        <b-form-input
          id="gc__input-email"
          ref="email"
          v-model="email"
          v-validate="'required|email'"
          name="email"
          :state="validateState('email')"
          aria-describedby="gc__contact-email-fb"
          type="email"
          required
          placeholder="Enter recipient email"
        />
        <b-form-invalid-feedback id="gc__contact-email-fb">
          Invalid email
        </b-form-invalid-feedback> 
      </b-form-group>

      <b-form-group
        id="ig-senders-name"
        label-for="gc__senders_name"
      >
        <slot name="label">
          <div class="mb-1">
            <span class="se-pink">
              *
            </span>
            Senders name:
          </div>
        </slot>
        <b-form-input
          id="gc__senders_name"
          v-model="sendersName"
          v-validate="{ required: true, min: 3 }"
          :state="validateState('gc__senders_name')"
          name="gc__senders_name"
          aria-describedby="gc__senders_name-fb"
          required
          placeholder="Senders name"
        />
        <b-form-invalid-feedback id="gc__senders_name-fb">
          Senders name required (min. 3 characters)
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-3"
        label="Add a note:"
        label-for="textarea"
      >
        <b-form-textarea
          id="textarea"
          v-model="note"
          placeholder="Add a message to the recipient..."
          rows="6"
          max-rows="8"
        />
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import validateState from './../mixins/validateState'
export default {
  mixins: [validateState],
  data() {
    return {
      name: '',
      email: '',
      note: '',
      sendersName: ''
    }
  }
}
</script>
