import ApiError from './error'
import constants from './../utils/constants'

export default $axios => ({
  async update(body) {
    try {
      const { data } = await $axios.patch(
        `api/checkout?include=${constants.cartInclude}`,
        body
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async next() {
    try {
      const { data } = await $axios.patch(
        `api/checkout/next?include=${constants.cartInclude}`,
        null
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async advance() {
    try {
      const { data } = await $axios.patch(
        `api/checkout/advance?include=${constants.cartInclude}`,
        null
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async complete() {
    try {
      const { data } = await $axios.patch(
        `api/checkout/complete?include=${constants.cartInclude}`,
        null
      )
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async shippingRates() {
    try {
      const { data } = await $axios.get(`api/checkout/shipping_rates`)
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  },
  async paymentMethods() {
    try {
      const { data } = await $axios.get(`api/checkout/payment_methods`)
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  }
})
