import ApiError from './error'

export default $axios => ({
  async contact(msgType, msgDetails) {
    try {
      const payload = {
        message_type: msgType,
        message_details: msgDetails
      }
      const { data } = await $axios.post(`/contact`, payload)
      return data
    } catch (error) {
      return { error: ApiError(error), data: null }
    }
  }
})
