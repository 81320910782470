import nuxtStorage from 'nuxt-storage'

export const state = () => ({
  siteName: 'Stomping Elephants',
  apiBaseUrl: '',
  frontBaseUrl: '',
  cdnUrl: '',
  ausPostApiKey: '',
  clientBaseUrl: '',
  currentPage: '/',
  currentPageParams: {},
  prevPageParams: {},
  prevPage: '',
  message: '',
  showMessage: false,
  showError: false,
  states: null,
  loading: false,
  showLogin: false,
  showSignUp: false,
  messageType: 'success',
  showModal: null,
  hideModal: null,
  navSmallShown: false,
  navSmallComponent: 'search',
  carouselImages: [],
  contactFormTitle: 'Contact Us!',
  contactFormComponent: 'stockist-form',
  randSeed: null,
  stompingAlertsId: null
})

export const mutations = {
  SET_BASE_URL(state, url) {
    state.apiBaseUrl = url
  },
  SET_CDN_URL(state, url) {
    state.cdnUrl = url
  },
  SET_FRONT_BASE_URL(state, url) {
    state.frontBaseUrl = url
  },
  SET_CLIENT_BASE_URL(state, url) {
    state.clientBaseUrl = url
  },
  SET_CURRENT_PAGE(state, page) {
    state.prevPage = state.currentPage
    state.prevPageParams = state.currentPageParams
    state.currentPage = page.page
    state.currentPageParams = page.params
  },
  SET_MESSAGE(state, msg) {
    state.messageType = 'success'
    state.message = msg
  },
  SET_WARNING(state, msg) {
    state.messageType = 'warning'
    state.message = msg
  },
  SET_MESSAGE_TYPE(state, type) {
    state.messageType = type
  },
  CLEAR_MESSAGE(state) {
    state.message = ''
  },
  SHOW_MESSAGE(state, show) {
    state.showMessage = show
  },
  SHOW_ERROR(state, show) {
    state.showError = show
  },
  SET_AUSPOST_API_KEY(state, key) {
    state.ausPostApiKey = key
  },
  SET_STATES(state, data) {
    const states = []
    data.included.map(inc => {
      states.push({ value: inc.attributes.abbr, text: inc.attributes.name })
    })
    state.states = states.reverse()
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading
  },
  SHOW_LOGIN(state) {
    state.showLogin = true
  },
  HIDE_LOGIN(state) {
    state.hideModal = 'sign-in-modal'
  },
  SHOW_SIGNUP(state) {
    state.showSignUp = true
  },
  HIDE_SIGNUP(state) {
    state.hideModal = 'sign-up-modal'
    state.showSignUp = false
  },
  SHOW_MODAL(state, modalId) {
    state.showModal = modalId
  },
  TOGGLE_NAV_SMALL(state) {
    state.navSmallShown = !state.navSmallShown
  },
  HIDE_NAV_SMALL(state) {
    state.navSmallShown = false
  },
  NAV_SMALL_COMPONENT(state, component) {
    state.navSmallComponent = component
  },
  SET_CAROUSEL_IMAGES(state, images) {
    state.carouselImages = images
  },
  SET_CONTACT_FORM(state, { title, component }) {
    state.contactFormTitle = title
    state.contactFormComponent = component
  },
  SET_RAND_SEED(state, seed) {
    state.randSeed = seed
  },
  SET_ALERTS_ID(state, id) {
    state.stompingAlertsId = id
  }
}

export const getters = {
  showLoading(state) {
    return state.loading
  }
}

export const actions = {
  async nuxtServerInit({ commit, dispatch, state }, { req, $auth }) {
    const page = req ? req.originalUrl : ''
    const host = req ? `https://${req.headers.host}` : window.location.origin
    commit('SET_LOADING', true)
    commit('SET_CLIENT_BASE_URL', host)
    commit('SET_CURRENT_PAGE', { page })
    commit('SET_BASE_URL', process.env.STORE_BASE_URL)
    commit('SET_AUSPOST_API_KEY', process.env.AUSPOST_API_KEY)
    commit('SET_FRONT_BASE_URL', process.env.BASE_URL)
    commit('SET_CDN_URL', process.env.SPACES_CDN)
    dispatch('setStompingAlertsId')
    // await dispatch('cart/setCartToken')
    const cartToken = this.$cookies.get('stomping_cart_token')
    if (cartToken) {
      this.$axios.setHeader('X-Spree-Order-Token', cartToken)
    }
    await dispatch('cart/getCart')
    // if (!state.cart.cart) {
    //   // await dispatch('cart/setCart')
    //   await dispatch('cart/getCart')
    // }
    if ($auth.loggedIn) {
      await dispatch('account/getAccount')
    }
    await dispatch('products/setTaxonomies')
    await dispatch('products/setFixedTaxons')
    await dispatch('products/reviewSettings')
    commit('SET_LOADING', false)
  },
  setPage({ commit }, page) {
    commit('SET_CURRENT_PAGE', page)
  },
  async setStates({ commit }) {
    const res = await this.$api.countries.show('AU')
    commit('SET_STATES', res)
  },
  showLoading({ commit }) {
    commit('SET_LOADING', true)
  },
  hideLoading({ commit }) {
    commit('SET_LOADING', false)
  },
  async setCarouselImages({ commit }) {
    const { data, error } = await this.$api.carousel.index()
    if (error) {
      commit('SET_CAROUSEL_IMAGES', [])
    } else {
      commit('SET_CAROUSEL_IMAGES', data)
    }
  },
  async submitContactForm({ commit, dispatch }, { msgType, formData }) {
    await dispatch('showLoading')
    await this.$api.contact.contact(msgType, formData)
    await dispatch('hideLoading')
    commit('SET_MESSAGE', 'Your message has been submitted. Thank you!')
    commit('SHOW_MESSAGE', true)
  },
  getOrSetRandSeed({ commit }) {
    const localSeed = nuxtStorage.localStorage.getData('seed')
    const hasLocalSeed = localSeed !== null && localSeed !== undefined
    const seed = hasLocalSeed ? localSeed : Math.random()
    commit('SET_RAND_SEED', seed)
    nuxtStorage.localStorage.setData('seed', seed, 1, 'd')
  },
  setRandSeed({ commit }) {
    const seed = Math.random()
    commit('SET_RAND_SEED', seed)
    nuxtStorage.localStorage.setData('seed', seed, 1, 'd')
  },
  async setStompingAlertsId({ commit }) {
    const { data, error } = await this.$api.mailchimp.lists()
    if (error === null || error === undefined) {
      const lists = data.lists.filter(d => d.name === 'Stomping Alerts')
      if (lists.length > 0) {
        commit('SET_ALERTS_ID', lists[0].id)
      }
    }
  }
}
