<template>
  <b-card
    class="productcard__outer "
    :class="{'productcard__minimal': minimal}"
    img-alt="Image"
    img-top
    no-body
  >
    <b-row v-if="!minimal" no-gutters>
      <b-col>
        <div class="productcard__media">
          <b-link :to="`/shop/${slug}`">
            <img :src="imageUrl" class="card-img card-img-top img-fluid productcard__image">
            <!-- <b-img-lazy v-bind="mainProps" :src="imageUrl" /> -->
            <!-- <b-img :src="imageUrl" /> -->
            <div class="productcard__overlay">
              <span class="align-middle">
                <h4>
                  DETAILS
                </h4>
              </span>
            </div>
          </b-link>
        </div>
      </b-col>
    </b-row>

    <b-card-body>
      <div>
        <div class="productcard__name">
          <h5>
            {{ name }}
          </h5>
        </div>
        <p v-if="product && !isGiftCard" class="soft-text small">
          {{ product.attributes.productCode }} 
          &#8226;
          {{ stockMini }}
        </p>
        <div class="productcard__price">
          <div>
            <span class="">{{ price }}</span>
            <!-- cart amount -->
            <div v-if="!minimal && inStock" class="float-right">
              <b-form inline>
                <b-btn
                  size="sm"
                  variant="light"
                  @click="toggleFavorite"
                >
                  <fa v-if="isFavorite" :icon="['fas', 'heart']" size="lg" />
                  <fa v-else :icon="['far', 'heart']" size="lg" />
                </b-btn>
                <b-form-input
                  v-model="cartAmount"
                  :disabled="isGiftCard"
                  for="type-number"
                  class="productcard__cart-amount w-100"
                  size="sm"
                  type="text"
                />
              </b-form>
            </div>
            <!-- cart amount minimal -->
            <div v-if="minimal" class="d-flex mt-3">
              <div>
                <b-form>
                  <b-form-input
                    v-model="cartAmount"
                    :disabled="isGiftCard || !inStock"
                    for="type-number"
                    class="productcard__cart-amount w-100"
                    size="sm"
                    type="text"
                  />
                </b-form>
                <!-- <p v-if="minimal" class="soft-text small mt-1">
                  {{ stock }}
                </p> -->
              </div>
              <!-- rating -->
              <div class="flex-grow-1 text-right productcard__reviews">
                <div>
                  <b-form inline class="float-right">
                    <no-ssr>
                      <star-rating
                        v-model="rating"
                        :star-size="20"
                        :increment="0.1"
                        :show-rating="false"
                        :read-only="true"
                        class="mr-1"
                      />
                    </no-ssr>
                    <b-link class="review_count mr-2 mt-1" @click="showReviews">
                      ({{ reviewsCount }})
                    </b-link>
                    <b-btn
                      size="lg"
                      variant="light"
                      @click="toggleFavorite"
                    >
                      <fa v-if="isFavorite" :icon="['fas', 'heart']" size="lg" />
                      <fa v-else :icon="['far', 'heart']" size="lg" />
                    </b-btn>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isWholesaler">
          <p class="se-blue">
            ({{ wholesalePrice }} wholesale)
          </p>
        </div>
        <b-collapse
          id="collapseReviews"
          ref="collapseReviews"
          v-model="reviewsShown"
          class="dont-collapse-sm"
        >
          <div v-if="reviewsShown" class="dropdown-full">
            <div class="">
              <div :class="containerClass">
                <div class="productcard__dropdown-contents">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      REVIEWS ({{ reviewsCount }})
                    </div>
                    <div class="mr-3">
                      <b-link @click="showReviewModal">
                        Add a review
                      </b-link>
                    </div>
                    <div class="dismiss">
                      <fa icon="times" @click="reviewsShown = false" />
                    </div>
                  </div>
                  <div v-for="review in reviews" :key="'review_' + review.id" class="productcard__reviews-items">
                    <div class="productcard__reviews-item">
                      <div class="reviews__title">
                        <span class="se-pink mr-1">
                          {{ review.attributes.name }}
                        </span>
                        <span class="mr-1 se-grey">
                          | {{ reviewDate(review.attributes.created_at) }}
                        </span>
                      </div>
                      <no-ssr>
                        <star-rating
                          v-model="review.attributes.rating"
                          :star-size="20"
                          :show-rating="false"
                          :read-only="true"
                          class="mr-1 mb-2"
                        />
                      </no-ssr>
                      <p class="reviews__contents se-dark-grey">
                        <span>
                          {{ truncateText(review.attributes.review) }}
                          <b-link :to="product.attributes.slug + '/reviews/' + review.id">
                            read more
                          </b-link>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="mt-2">
                    <b-link class="se-grey" :to="product.attributes.slug + '/reviews/'">
                      see more <fa icon="angle-double-right" size="lg" class="pt-1" />
                    </b-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>        
      </div>

      <!-- <div v-if="hasVariants && minimal" class="mt-4">
        <options-select v-for="(value, key) in options" :key="key" :option-values="value" />
      </div> -->
    </b-card-body>

    <div slot="footer">
      <b-btn
        v-if="inStock"
        class="productcard__add-btn"
        size="sm"
        block
        :disabled="isBagging"
        :class="addingToBag"
        :variant="btnVariant"
        @click="addTocart"
      >
        {{ buttonText }}
        <fa v-if="buttonText == 'BAGGED'" icon="check" />
      </b-btn>
      <div v-else class="se-pink unavailable-stock">
        <strong>
          Currently unavailable
        </strong>
      </div>
    </div>
    <b-modal
      id="gift-card-modal"
      ref="gift-card-modal"
      title="Gift Card Details"
      ok-title="ADD TO BAG"
      @ok="giftCardSubmit"
    >
      <p class="my-4">
        <gift-card-form ref="gift-card-form" />
      </p>
    </b-modal>
    <b-modal
      id="review-modal"
      ref="review-modal"
      title="Add your review"
      ok-title="SUBMIT REVIEW"
      :ok-disabled="!loggedIn"
      @ok="reviewSubmit"
    >
      <p class="my-4">
        <review-form ref="review-form" />
        <no-ssr>
          <recaptcha
            @error="onRecaptchaError"
            @success="onRecaptchaSuccess"
            @expired="onRecaptchaExpired"
          />
        </no-ssr>
      </p>
    </b-modal>    
  </b-card>
</template>

<script>
// import constants from './../utils/constants'
import normalize from 'json-api-normalizer'
import productOptions from '~/utils/productOptions'
import GiftCardForm from '~/components/GiftCardForm'
import ReviewForm from '~/components/ReviewForm'
// import OptionsSelect from '~/components/OptionsSelect'
export default {
  components: {
    'gift-card-form': GiftCardForm,
    'review-form': ReviewForm
    // 'options-select': OptionsSelect
  },
  props: {
    product: {
      type: Object,
      default: null
    },
    productData: {
      type: Object,
      default: null
    },
    reviews: {
      type: Array,
      default: () => []
    },
    minimal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cartAmount: 1,
      buttonText: 'ADD TO BAG',
      buttonDisabled: false,
      addingToBag: '',
      btnVariant: 'secondary',
      reviewsShown: false,
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: '#f8f8f8',
        class: 'card-img card-img-top img-fluid productcard__image'
      }
    }
  },
  computed: {
    isWholesaler() {
      return this.$auth.user
        ? this.$auth.user.data.attributes.wholesaler
        : false
    },
    name() {
      return this.product ? this.product.attributes.name : ''
    },
    defaultVariant() {
      if (this.product) {
        const id = this.product.relationships.defaultVariant.data.id
        return this.$store.state.products.variants[id]
      }
      return this.product
    },
    hasVariants() {
      if (this.productData) {
        const normed = normalize(this.productData)
        return normed.optionValue !== null && normed.optionValue !== undefined
      }
      return false
    },
    options() {
      if (!this.hasVariants) {
        return {}
      }
      return productOptions(this.productData)
    },
    price() {
      return this.product ? this.product.attributes.displayPrice : ''
    },
    wholesalePrice() {
      return this.product ? this.product.attributes.displayWholesalePrice : ''
    },
    afterpayPrice() {
      const price = this.product ? this.product.attributes.price / 4 : 0
      return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })
    },
    slug() {
      return this.product ? this.product.attributes.slug : ''
    },
    images() {
      let img = {}
      if (this.product) {
        img = this.$normalizer.getRelationships(
          'products',
          'images',
          this.product
        )
      }
      return img
    },
    imageUrl() {
      if (Object.keys(this.images).length > 0) {
        const img = this.images[Object.keys(this.images)[0]]
        const url = img.attributes.styles[2].publicUrl
        return url
      }
      return ''
    },
    isGiftCard() {
      if (this.product && this.product.attributes) {
        return this.product.attributes.isGiftCard
      }
      return false
    },
    favorite() {
      return this.$store.state.account.favorites && this.product
        ? this.$store.state.account.favorites[this.product.id]
        : null
    },
    isFavorite() {
      return this.favorite !== null && this.favorite !== undefined
    },
    loggedIn() {
      return (
        this.$auth.loggedIn !== null &&
        this.$auth.loggedIn !== undefined &&
        this.$auth.loggedIn
      )
    },
    rating() {
      return this.product ? parseFloat(this.product.attributes.avgRating) : 0
    },
    reviewsCount() {
      return this.product ? this.product.attributes.reviewsCount : 0
    },
    containerClass() {
      return this.reviewsShown ? ' productcard__dropdown-overlay' : ''
    },
    qtyError() {
      return this.$store.state.error.qtyError
    },
    stockCount() {
      return this.product ? this.product.attributes.stockCount : 0
    },
    stock() {
      return this.stockCount > 0
        ? `We have ${this.stockCount} in stock.`
        : 'Out of stock!'
    },
    stockMini() {
      if (this.isGiftCard) return ''
      return this.stockCount > 0
        ? `In stock: ${this.stockCount}`
        : 'Out of stock!'
    },
    inStock() {
      return this.stockCount > 0 || this.isGiftCard
    },
    isBagging() {
      return this.$store.state.cart.bagging
    }
  },
  methods: {
    bagging() {
      this.buttonText = 'BAGGING....'
      this.buttonDisabled = true
      this.$store.commit('cart/SET_BAGGING', true)
      this.btnVariant = 'warning'
    },
    baggedSuccess() {
      this.btnVariant = 'success'
      this.buttonText = 'BAGGED'
      this.$store.commit('cart/SET_BAGGING', false)
      this.buttonDisabled = false
    },
    baggedError() {
      this.btnVariant = 'warning'
      this.buttonText = 'OUT OF STOCK'
      this.$store.commit('cart/SET_BAGGING', false)
      this.buttonDisabled = false
    },
    bagged() {
      setTimeout(() => {
        this.buttonText = 'ADD TO BAG'
        this.buttonDisabled = false
        this.addingToBag = ''
        this.btnVariant = 'secondary'
      }, 1500)
    },
    showGiftCardModal() {
      this.$refs['gift-card-modal'].show()
    },
    showReviewModal() {
      this.reviewsShown = false
      this.$refs['review-modal'].show()
    },
    async addTocart() {
      if (this.isGiftCard) {
        this.showGiftCardModal()
      } else {
        this.bagging()
        const body = {
          id: this.product.relationships.defaultVariant.data.id,
          qty: this.cartAmount
        }
        // if (this.qtyError) {
        //   await this.$store.commit('error/CLEAR_QTY_ERROR')
        // }
        await this.$store.dispatch('cart/addToCart', body)
        if (this.qtyError) {
          this.baggedError()
          this.$store.commit('error/CLEAR_QTY_ERROR')
        } else {
          this.baggedSuccess()
        }
        this.bagged()
      }
    },
    async giftCardSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      const valid = await this.$refs['gift-card-form'].isValid()
      if (!valid) {
        return
      }
      const giftCard = {
        name: this.$refs['gift-card-form'].name,
        email: this.$refs['gift-card-form'].email,
        note: this.$refs['gift-card-form'].note,
        senders_name: this.$refs['gift-card-form'].sendersName
      }
      const body = {
        id: this.product.relationships.defaultVariant.data.id,
        qty: this.cartAmount,
        giftCard: giftCard
      }
      this.bagging()
      await this.$store.dispatch('cart/addToCart', body)
      this.baggedSuccess()
      this.bagged()
      this.$nextTick(() => {
        this.$refs['gift-card-modal'].hide()
      })
    },
    async reviewSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      const valid = await this.$refs['review-form'].isValid()
      if (!valid) {
        return
      }
      try {
        await this.$recaptcha.getResponse()
        await this.$recaptcha.reset()
        const review = {
          name: this.$refs['review-form'].name,
          title: this.$refs['review-form'].title,
          review: this.$refs['review-form'].review,
          rating: this.$refs['review-form'].rating,
          product_id: this.product.id
        }
        await this.$store.dispatch('products/createReview', review)
        if (this.$store.state.products.reviewSubmitted) {
          this.$nextTick(() => {
            this.$refs['review-modal'].hide()
          })
          const showAll = this.$store.state.products.reviewSettings.data
            .attributes.include_unapproved_reviews
          const newReview = this.$store.state.products.newReview
          if (showAll && newReview !== null) {
            this.reviews.unshift(newReview)
            this.reviews.pop()
          }
        }
      } catch (error) {
        console.log('Error:', error)
      }
    },
    async toggleFavorite() {
      if (!this.loggedIn) {
        this.$store.commit(
          'SET_WARNING',
          'You must be logged in to add to your favourites!',
          { root: true }
        )
        this.$store.commit('SHOW_MESSAGE', true, { root: true })
      } else {
        this.isFavorite
          ? await this.$store.dispatch(
              'account/deleteFavorite',
              this.favorite.id
            )
          : await this.$store.dispatch('account/addFavorite', this.product.id)
        if (this.$route.name === 'account-wishlist') {
          await this.$store.dispatch('products/favoriteProducts')
        }
      }
    },
    showReviews() {
      this.reviewsShown = !this.reviewsShown
    },
    truncateText(text) {
      return text.length > 100 ? text.substring(0, 100) + '...' : text
    },
    reviewDate(date) {
      // const event = new Date(Date.UTC(2012, 11, 20, 3, 0, 0))
      const event = new Date(Date.parse(date))
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
      return event
        .toLocaleDateString('en-AU', options)
        .split('/')
        .join('.')
    },
    onRecaptchaSuccess(token) {
      console.log('Succeeded')
    },
    onRecaptchaExpired() {
      console.log('Expired')
    },
    onRecaptchaError(error) {
      console.log('Error happened:', error)
    }
  }
}
</script>

<style lang="scss">
.unavailable-stock {
  text-align: left;
  background-color: #f8f8f8 !important;
  padding: 0.25rem 0;
}
</style>
