<template>
  <b-sidebar
    id="sidebar-1"
    right
    backdrop
    :no-header-close="true"
    :no-header="true"
    @hidden="main = true"
  >
    <template v-slot:default="{ hide }">
      <div class="b-sidebar-header1 d-flex align-items-center text-center">
        <div v-if="main">
          <b-button-close class="text-light" @click="main ? hide() : back()" />
        </div>
        <div v-else>
          <b-button
            class="text-light back-button"
            variant="link"
            @click.stop="back"
          >
            <fa icon="angle-left" class="float-left mr-2 menu-caret" />
          </b-button>
        </div>
        <div class="flex-grow-1" @click="main ? myaccount(hide) : back()">
          <transition name="fade" mode="out-in">
            <span>
              <div v-show="main" key="account-tile" class="text-right" style="font-size: 1rem;">
                <span class="nav-text">
                  <span v-show="loggedIn">
                    My Account
                  </span>
                  <span v-show="!loggedIn">
                    Log in / Sign up
                  </span>
                  <fa-layers class="mr-2">
                    <no-ssr>
                      <fa :icon="['fas', 'user']" size="1x" />
                      <fa :icon="icons" size="1x" transform="down-3 right-8 shrink-6" class="pink" />
                    </no-ssr>
                  </fa-layers>
                </span>
              </div>
              <strong v-show="!main" key="cat-title">
                {{ sidebarTitle }}
              </strong>
            </span>
          </transition>
        </div>
      </div>
      <div class="elems">
        <transition :name="main ? 'submenu' : 'main'">
          <div v-if="main" key="main" class="elem">
            <b-nav vertical>
              <b-nav-item to="/new-in-store">
                NEW in store
              </b-nav-item>
              <b-nav-item @click="categoryClick('stud')">
                Stud Earrings
                <fa icon="angle-right" class="float-right menu-caret" />
              </b-nav-item>
              <b-nav-item @click="categoryClick('dangle')">
                Dangle Earrings
                <fa icon="angle-right" class="float-right menu-caret" />
              </b-nav-item>
              <!-- <b-nav-item @click="categoryClick('laserCut')">
                Laser Cut
                <fa icon="angle-right" class="float-right menu-caret" />
              </b-nav-item> -->
              <b-nav-item @click="categoryClick('other')">
                Other Products
                <fa icon="angle-right" class="float-right menu-caret" />
              </b-nav-item>
              <b-nav-item @click="categoryClick('about')">
                Tell me more
                <fa icon="angle-right" class="float-right menu-caret" />
              </b-nav-item>
              <b-nav-item @click="categoryClick('help')">
                Help
                <fa icon="angle-right" class="float-right menu-caret" />
              </b-nav-item>
            </b-nav>
          </div>
          <div v-else key="sub" class="elem">
            <b-nav vertical>
              <b-nav-item v-for="(link, index) in subMenuItems" :key="'sidebar__sub-link-' + index" :to="link.route">
                {{ link.label }}
              </b-nav-item>
            </b-nav>
          </div>
        </transition>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { mapGetters } from 'vuex'
import themeNav from './../mixins/themeNav'
export default {
  mixins: [themeNav],
  data() {
    return {
      main: true,
      subMenuItems: [],
      vcoConfig: {
        handler: this.vcoHandler,
        middleware: this.vcoMiddleware,
        events: ['dblclick', 'click'],
        isActive: true
      }
    }
  },
  computed: {
    ...mapGetters({
      about: 'sitenav/aboutLinks',
      help: 'sitenav/helpLinks',
      studThemes: 'products/studThemes',
      studMaterials: 'products/studMaterials',
      dangleThemes: 'products/dangleThemes',
      dangleMaterials: 'products/dangleMaterials'
      // laserCutThemes: 'products/laserCutThemes',
      // laserCutMaterials: 'products/laserCutMaterials'
    }),
    sidebarTitle() {
      return this.$store.state.sitenav.sidebarTitle
    },
    extrasThemes() {
      return this.$store.getters['products/extraCategories']
    },
    sidebarClass() {
      return this.$store.state.sitenav.sidebarShown ? 'sidebar__shown' : ''
    },
    open() {
      return this.$store.state.sitenav.sidebarShown
    },
    studEarrings() {
      const allStuds = [
        {
          label: 'All Stud Earrings',
          route: `/shop/stud-earrings`
        }
      ]
      const themes = this.categoryNavItems('studEarrings', this.studThemes)
      const materials = this.categoryNavItems(
        'studEarrings',
        this.studMaterials
      )
      return [...allStuds, ...themes, ...materials]
    },
    dangleEarrings() {
      const allDangs = [
        {
          label: 'All Dangle Earrings',
          route: `/shop/dangle-earrings`
        }
      ]
      const themes = this.categoryNavItems('dangleEarrings', this.dangleThemes)
      const materials = this.categoryNavItems(
        'dangleEarrings',
        this.dangleMaterials
      )
      return [...allDangs, ...themes, ...materials]
    },
    extras() {
      const allOthers = [
        {
          label: 'All Other Products',
          route: `/shop/other`
        }
      ]
      const themes = this.categoryNavItems('other', this.extrasThemes)
      return [...allOthers, ...themes]
    },
    icons() {
      return this.loggedIn ? ['fas', 'lock-open'] : ['fas', 'lock']
    }
  },
  methods: {
    back() {
      this.main = true
    },
    myaccount(hide) {
      hide()
      if (!this.loggedIn) {
        this.showLogin()
      } else {
        this.$router.push('/account/info')
      }
    },
    resetTitle() {
      this.$store.commit('sitenav/SET_SIDEBAR_TITLE', 'Menu')
    },
    toggleSidebar() {
      this.$store.dispatch('sitenav/toggleSidebar')
    },
    categoryClick(category) {
      switch (category) {
        case 'about':
          this.$store.commit('sitenav/SET_SIDEBAR_TITLE', 'Tell me more')
          this.subMenuItems = [...this.about]
          break
        case 'help':
          this.$store.commit('sitenav/SET_SIDEBAR_TITLE', 'Help')
          this.subMenuItems = [...this.help]
          break
        case 'stud':
          this.$store.commit('sitenav/SET_SIDEBAR_TITLE', 'Stud Earrings')
          this.subMenuItems = [...this.studEarrings]
          break
        case 'dangle':
          this.$store.commit('sitenav/SET_SIDEBAR_TITLE', 'Dangle Earrings')
          this.subMenuItems = [...this.dangleEarrings]
          break
        case 'other':
          this.$store.commit('sitenav/SET_SIDEBAR_TITLE', 'Other Products')
          this.subMenuItems = [...this.extras]
          break
        // case 'laserCut':
        //   this.$store.commit('sitenav/SET_SIDEBAR_TITLE', 'Laser Cut')
        //   this.subMenuItems = [...this.laserCut]
        //   break
        default:
          break
      }
      this.main = false
    },
    vcoHandler(event) {
      this.toggleSidebar()
    },
    vcoMiddleware(event) {
      return event.target.className === 'overlay active'
    },
    buildRoute(category, name) {
      console.log('buildRoute', name)
      const formatName = name
        .toLowerCase()
        .split(' ')
        .join('-')
      return `/shop/${category}/${formatName}`
    }
  }
}
</script>
<style lang="scss">
.menu-caret {
  font-size: 1.4rem;
}
// .b-sidebar-right > .b-sidebar-header .close {
//   margin-right: 0 !important;
//   margin-left: 0 !important;
// }
.b-sidebar-body {
  background-color: #fff;
}
.b-sidebar-header1 {
  cursor: pointer;
  background-color: #1ab6b9;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  color: white;
  min-height: 2.75rem;
  .title-text {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .back-button {
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
  }
}

.elems {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  // overflow: hidden;
  width: 100%;
  height: 90vh;
  background-color: #fff;
}
.elem {
  display: block;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
  text-align: left;
  background-color: #fff;
  ul {
    min-height: 700px;
    height: 100%;
    background-color: #fff;
  }
}
.main-enter-active,
.main-leave-active {
  transition: all 0.5s;
}
.main-enter {
  transform: translateX(100%);
}
.submenu-enter-active,
.submenu-leave-active,
.submenu-leave-to {
  transition: all 0.5s;
}
.submenu-enter {
  transform: translateX(-100%);
}
// .submenu-leave-to {
//   transform: translateX(-100%);
// }
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
